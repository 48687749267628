import { useState, useEffect } from "react";
import { Tooltip } from "@mui/material";
import * as _ from "lodash";
import { ExportToFile } from "./ExportToFile";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';


export const ExportDataGridToExcel = (props: {
  headers: Array<any>,
  data: any,
  filename: string,
  setMessage: any,
}) => {

  const {
    headers,
    data,
    filename,
    setMessage,
  } = props;

  const [rows, setRows] = useState([]);

  const handleDownloadFile = () => {
      let date = new Date().toISOString().split('.')[0];

      ExportToFile(rows, filename + "_" + date);
      if ((setMessage !== null) && (setMessage !== undefined)) {
          setMessage("Export de " + (rows?.length ? rows.length : 0) + " ligne(s)");
      }
  }

  useEffect(() => {
    if (
        (data?.length > 0) && (headers?.length > 0) 
        && typeof data?.[0] === 'object'
        //&& (Object?.keys(data?.[0])?.length === headers?.length)
    ) {
        setRows(data?.map((rowData: any) => {
            //let columns = headers.filter((header: any) => !(header?.hideable === true) && (header?.field in rowData)).map((header: any) => header.field);
            let columns = headers.filter((header: any) => (header?.field in rowData)).map((header: any) => header.field);
            return _.pick(rowData, columns);
        }));
    } else {
        if ((data?.length === 0) && (setMessage !== null) && (setMessage !== undefined)) {
            setMessage("Aucune données à exporter ou format invalide des entêtes/données");
        } else {
            console.log("No data or headers defined!")
        }
    }
  }, [data, headers]);

  return (
      <>
        <Tooltip title="Télécharger">
            <DownloadForOfflineIcon 
                onClick={ handleDownloadFile }
                sx={{ color: '#EC5428', fontSize: '35px' }}
            />
        </Tooltip>
      </>
  );
}