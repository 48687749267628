import { useState, useEffect } from 'react';
import Clients from '../../../Clients/Clients';
import { useTranslation } from "react-i18next";

export function SelectClient(props: {
    indexNavBar: any,
    setIndexNavBar: any,
    selectedClient: any,
    setSelectedClient: any,
    open: any,
    setOpen: any,
    handleHideElement: any,
    setTitle: Function
}) {
    const {
        indexNavBar,
        setIndexNavBar,
        selectedClient,
        setSelectedClient,
        handleHideElement,
        setTitle
    } = props

    const { t } = useTranslation();
    const [open, setOpen] = useState();
    // useEffect(() => {
        setTitle(t("selectClient"))
    // }, [])

    return (
        <>
            <Clients
                indexNavBar={indexNavBar}
                setIndexNavBar={setIndexNavBar}
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
                open={open}
                setOpen={setOpen}
                handleHideElement={handleHideElement}
                reduced={true}
            />
        </>
    )
}
