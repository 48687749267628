import { MyContainedButton } from 'MyComponents/Generic/MyButton';
import { MyTextField } from 'MyComponents/TextField/MyTextField';
import MyTitle from '../../../MyComponents/Title/MyTitle';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ChangeEventHandler } from 'react';

function LoadingComponent(props: {
    myOptionSelected: any,
    handleChangeDelivery: ChangeEventHandler<Element>
}) {

    const { myOptionSelected, handleChangeDelivery } = props;
    const { t } = useTranslation();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <MyTitle>
                {t("startShippingTitle")}
            </MyTitle>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center', width: '90%', border: '3px solid #f5c065', borderRadius: '24px', padding: '3%' }}>
                <MyModalSubTitle>
                    <LocationOnIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                    {t("Start")}
                </MyModalSubTitle>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <CircularProgress />
                    </Grid>
                </Grid>
                <MyModalSubTitle>
                    <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                    {t("sender")}
                </MyModalSubTitle>
                <Grid container spacing={1} columns={{ xs: 4, md: 12 }}>
                    <Grid item xs={6}>
                        <MyTextField type='text' disabled={true} name='name' label={t("name")} />
                    </Grid>
                    <Grid item xs={6}>
                        <MyTextField type='tel' disabled={true} name="phone" pattern="[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}" label={t("phone")} placeholder={"xx xx xx xx xx"} onChange={handleChangeDelivery} />
                    </Grid>
                </Grid>
            </div>
            <div>
                <MyContainedButton disabled={(myOptionSelected != null ? false : true)} >{t("next>")}</MyContainedButton>
            </div>
        </div>
    )
}
export default LoadingComponent;