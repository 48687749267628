import { styled } from '@mui/material/styles';


export const MyOutlinedButton = styled('button')(({ theme }) => ({
  ':hover': {
    opacity: 0.85,
  },
  ':focus': {
    opacity: 0.7,
  },
  ':disabled': {
    cursor: 'not-allowed',
    opacity: 0.35,
  },
  backgroundColor: 'transparent',
  cursor: 'pointer',
  color: "#F7A007",
  fontSize: "calc(10px + 1.5vmin)",
  fontFamily: 'Sansation, PinkBlue, Helvetica, sans-serif, -apple-system',
  borderRadius: 50,
  border: '3px solid #F7A007',
  width: '20vh',
  height: '4vh',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
  margin: '20px 5px'
}));

export const MyContainedButton = styled('button')(({ theme }) => ({
  ':hover': {
    opacity: 0.85,
  },
  ':focus': {
    opacity: 0.7,
  },
  ':disabled': {
    cursor: 'not-allowed',
    opacity: 0.35,
  },
  backgroundColor: '#F7A007',
  cursor: 'pointer',
  color: "#fff",
  fontSize: "calc(10px + 1.5vmin)",
  fontFamily: 'Sansation, PinkBlue, Helvetica, sans-serif, -apple-system',
  borderRadius: 50,
  border: 'none',
  width: '20vh',
  height: '4vh',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
  margin: '20px 5px'
}));

export const MyTextButton = styled('button')(({ theme }) => ({
  ':hover': {
    opacity: 0.85,
  },
  ':focus': {
    opacity: 0.7,
  },
  ':disabled': {
    cursor: 'not-allowed',
    opacity: 0.35,
  },
  backgroundColor: 'transparent',
  cursor: 'pointer',
  color: "#009CFF",
  fontSize: "calc(10px + 1.5vmin)",
  fontFamily: 'Sansation, PinkBlue, Helvetica, sans-serif, -apple-system',
  borderRadius: 50,
  border: 'none',
  width: '20vh',
  height: '4vh',
  margin: '20px 5px'
}));

export const MyEditButton = styled('button')(({ theme }) => ({
  ':hover': {
    opacity: 0.85,
  },
  ':focus': {
    opacity: 0.7,
  },
  ':disabled': {
    cursor: 'not-allowed',
    opacity: 0.35,
  },
  backgroundColor: '#009CFF',
  cursor: 'pointer',
  color: "#fff",
  fontSize: "calc(10px + 1.5vmin)",
  fontFamily: 'Sansation, PinkBlue, Helvetica, sans-serif, -apple-system',
  borderRadius: 50,
  border: 'none',
  width: '20vh',
  height:'4vh',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
  margin: '20px 5px'
}));

export const MyDeleteButton = styled('button')(({ theme }) => ({
  ':hover': {
    opacity: 0.85,
  },
  ':focus': {
    opacity: 0.7,
  },
  ':disabled': {
    cursor: 'not-allowed',
    opacity: 0.35,
  },
  backgroundColor: '#EC5428',
  cursor: 'pointer',
  color: "#fff",
  fontSize: "calc(10px + 1.5vmin)",
  fontFamily: 'Sansation, PinkBlue, Helvetica, sans-serif, -apple-system',
  borderRadius: 50,
  border: 'none',
  width: '90%',
  minHeight: '100%',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
  margin: '2vh 1vh'
}));

export const MyAddButton = styled('button')(({ theme }) => ({
  ':hover': {
    opacity: 0.85,
  },
  ':focus': {
    opacity: 0.7,
  },
  ':disabled': {
    cursor: 'not-allowed',
    opacity: 0.35,
  },
  backgroundColor: '#EC5428',
  cursor: 'pointer',
  color: "#fff",
  fontSize: "calc(10px + 1.5vmin)",
  fontFamily: 'Sansation, PinkBlue, Helvetica, sans-serif, -apple-system',
  borderRadius: 50,
  border: 'none',
  width: '20vh',
  height: '4vh',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
  margin: '20px 5px'
}));

export const MyExportButton = styled('button')(({ theme }) => ({
  ':hover': {
    opacity: 0.85,
  },
  ':focus': {
    opacity: 0.7,
  },
  ':disabled': {
    cursor: 'not-allowed',
    opacity: 0.35,
  },
  border: '3px solid #EC5428',
  cursor: 'pointer',
  color: "#EC5428",
  fontSize: "calc(10px + 1.5vmin)",
  fontFamily: 'Sansation, PinkBlue, Helvetica, sans-serif, -apple-system',
  borderRadius: 50,
  width: '20vh',
  height: '25%',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
  margin: '20px 5px'
}));