import { ChangeEventHandler, MouseEventHandler } from 'react';
import MyTitle from '../../MyComponents/Title/MyTitle';
import SearchIcon from "@mui/icons-material/Search";
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { MyAddButton } from 'MyComponents/Generic/MyButton';
import { useTranslation } from "react-i18next";

export default function SearchAddress(props: {
    input: any,
    resetInput: MouseEventHandler<HTMLButtonElement>,
    handleChange: ChangeEventHandler<HTMLInputElement>,
    handleAddOpen: MouseEventHandler<HTMLButtonElement>
}) {

    const { t } = useTranslation();
    const {
        handleChange,
        input,
        resetInput,
        handleAddOpen,
    } = props;

    return (
        <>
            <MyTitle>
                {t("myAddress")}
            </MyTitle>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: { sx: '100%', md: '80%' } }}>
                    <SearchIcon sx={{ color: '#F5C065', fontSize: '40px', marginRight: '1vh' }} />
                    <input
                        onChange={handleChange}
                        className="searchInput"
                        value={input}
                        id="reasearch"
                        name="research"
                        placeholder={t("research") + t("3dot")}
                        style={{
                            border: "3.5px solid #F5C065",
                            borderRadius: "8px",
                            height: '30px',
                            width: '80%',
                            fontSize: '16px',
                        }}
                    />
                    <Tooltip title={t("erase")} placement='top'>
                        <IconButton onClick={resetInput}>
                            <FilterAltOffIcon sx={{ color: '#F5C065', fontSize: '35px', }} />
                        </IconButton>
                    </Tooltip>
                </Box>
                <MyAddButton onClick={handleAddOpen}>{t("add")}</MyAddButton>
            </Box>
        </>
    )
}