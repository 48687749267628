import { MyContainedButton } from 'MyComponents/Generic/MyButton';
import MyTitle from '../../../MyComponents/Title/MyTitle';
import Grid from '@mui/material/Grid';
import { useTranslation } from "react-i18next";
import { IDeliveryData } from 'pages/Delivery/type/Delivery/DeliveryData';
import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import PDFButton from 'MyComponents/Pdf/PDFButton';
import { IMessage } from 'pages/Clients/type/Message';
import { QRCodeCanvas } from 'qrcode.react';
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/material";
import { MouseEventHandler } from 'react';

function ShippingEnd(props: {
    createdDelivery: any,
    openAlert: boolean,
    errors: IMessage,
    setErrors: Function,
    setOpenAlert: Function,
    setInputs: Function,
    setActiveStep: Function,
    handleReset: MouseEventHandler<HTMLButtonElement>
}) {

    const {
        createdDelivery,
        openAlert,
        errors,
        setErrors,
        setOpenAlert,
        handleReset
    } = props;
    const { t } = useTranslation();
    const APP_DOMAIN = process.env.REACT_APP_DOMAIN;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <MyTitle>
                {t("recapShippingTitle")}
            </MyTitle>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center', width: '90%', border: '3px solid #f5c065', borderRadius: '24px', padding: '3%' }}>
                <MyModalSubTitle>
                    <PhoneIphoneIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                    QRcode
                </MyModalSubTitle>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {createdDelivery &&
                            <QRCodeCanvas id="qrcode" value={APP_DOMAIN + "/Delivery/start/" + (createdDelivery?.deliveryId === null || createdDelivery?.deliveryId === undefined ? createdDelivery?.id : createdDelivery?.deliveryId)} />
                        }
                    </Grid>
                </Grid>
            </div>
            <Collapse in={openAlert}>
                {(errors?.type) &&
                    <Alert severity={"info"}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpenAlert(false);
                                    setErrors({ type: 'info', content: 'Traitement en cours' });
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {errors?.content || ''}
                    </Alert>
                }
            </Collapse>
            {createdDelivery &&
                <div>
                    <PDFButton
                        setErrors={setErrors}
                        setOpenAlert={setOpenAlert}
                        delivery={createdDelivery}
                    />
                    <MyContainedButton onClick={handleReset}>{t("next>")}</MyContainedButton>
                </div>
            }
        </div>
    )
}
export default ShippingEnd;