import { Typography } from '@mui/material';
import { MyAccordion } from 'MyComponents/Generic/MyAccordion';
import MyStatus from 'MyComponents/Status/MyStatus';
import { MyEditButton } from 'MyComponents/Generic/MyButton';
import { useTranslation } from "react-i18next";

export default function DeliveryReduced(props: {
    data: any,
    roles: Array<String>,
    handleAccept: Function,
    expanded: string | false,
    setExpanded: Function
}) {
    const {
        data,
        roles,
        handleAccept,
        expanded,
        setExpanded
    } = props;

    const ROLE_ADMIN = process.env.REACT_APP_ROLE_ADMIN;
    const ROLE_CARRIER = process.env.REACT_APP_ROLE_CARRIER;
    const { t } = useTranslation();

    return (
        <MyAccordion
            id={`${data.row?.id}`}
            summary={
                <MyStatus label={data?.row?.statuses[0]?.status?.name} />
            }

            description={
                (roles.includes(ROLE_ADMIN!)) ?
                    (`${data.row?.client?.user?.lastname} ${data.row?.client?.user?.firstname} [${data.row?.client?.phone}]`)
                    : (`${data.row?.addressTo?.label}`)
            }
            content={
                <>
                    {roles.includes(ROLE_ADMIN!) && <Typography sx={{ fontSize: 13 }}>{t("carrier")}: {`${data.row?.carrier?.user?.firstname} ${data.row?.carrier?.user?.lastname}`}</Typography>}
                    {roles.includes(ROLE_CARRIER!) && data?.row?.statuses[0]?.status?.name === 'pending' &&
                        <MyEditButton
                            onClick={() => handleAccept(data?.row?.id, data?.row?.statuses[0]?.status?.name)}
                        >{t("acceptDelivery")}
                        </MyEditButton>
                    }
                    <Typography sx={{ fontSize: 13 }}>{t("remark")}: {data.row?.remark}</Typography>
                    {(roles.includes(ROLE_ADMIN!) || roles.includes(ROLE_CARRIER!)) &&
                        <>
                            <Typography sx={{ fontSize: 13, fontWeight: 700 }}>{t("Start")}</Typography>
                            <Typography sx={{ fontSize: 13 }}>{`${data.row?.addressFrom?.fullname || ''}`}</Typography>
                            <Typography sx={{ fontSize: 13 }}>{`Tél.: ${data.row?.addressFrom?.phone}`}</Typography>
                            <Typography sx={{ fontSize: 13 }}>{`${data.row?.addressFrom?.address?.complement || ''}`}</Typography>
                            <Typography sx={{ fontSize: 13 }}>{`${data.row?.addressFrom?.address?.street}`}</Typography>
                            <Typography sx={{ fontSize: 13 }}>{`${data.row?.addressFrom?.address?.zipcode} ${data.row?.addressFrom?.address?.city}`}</Typography>
                            <Typography sx={{ fontSize: 13 }}>{`${data.row?.addressFrom?.address?.country}`}</Typography>
                        </>
                    }
                    <Typography sx={{ fontSize: 13, fontWeight: 700 }}>{t("End")}</Typography>
                    <Typography sx={{ fontSize: 13 }}>{`${data.row?.addressTo?.fullname || ''}`}</Typography>
                    <Typography sx={{ fontSize: 13 }}>{`Tél.: ${data.row?.addressTo?.phone}`}</Typography>
                    <Typography sx={{ fontSize: 13 }}>{`${data.row?.addressTo?.address?.complement || ''}`}</Typography>
                    <Typography sx={{ fontSize: 13 }}>{`${data.row?.addressTo?.address?.street}`}</Typography>
                    <Typography sx={{ fontSize: 13 }}>{`${data.row?.addressTo?.address?.zipcode} ${data.row?.addressTo?.address?.city}`}</Typography>
                    <Typography sx={{ fontSize: 13 }}>{`${data.row?.addressTo?.address?.country}`}</Typography>
                </>
            }
            expanded={expanded}
            setExpanded={setExpanded}
        />
    )
}