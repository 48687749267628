export enum StatusName {
    created = "created",
    pending = "pending",
    picking_up = "picking up",
    delivering = "delivering",
    shipped = "shipped",
}

export default class Status {

    private constructor(public name: StatusName, public index: number, public background: string, public color: string) { }

    public static CREATED = new Status(StatusName.created, 0, '#5c5a5a83', "#5c5a5a");

    static readonly PENDING = new Status(StatusName.pending, 1, '#e6c72b76', "#e6c72b");

    static readonly PICKING_UP = new Status(StatusName.picking_up, 2, '#ec952a7a', "#ec952a");

    static readonly DELIVERING = new Status(StatusName.delivering, 3, '#2a8eec6d', "#2A8FEC");

    static readonly SHIPPED = new Status(StatusName.shipped, 4, '#1fb43f78', "#1FB43F");

    static readonly statusTab = [Status.CREATED, Status.PENDING, Status.PICKING_UP, Status.DELIVERING, Status.SHIPPED];
}