import ReactPDF, { Text, View } from "@react-pdf/renderer";


export interface ICustomHeader {
    name: string;
    percentageWidth: string;
}

function PDFHeader(props: {
    styles: ReactPDF.Styles,
    headers: Array<ICustomHeader>
}) {
    const {
        styles,
        headers
    } = props;


    return (
        <View style={styles.tableHeader}>
            {headers.map((header) => {
                return (
                    <View key={header.name} style={[styles.tableCol, { width: header.percentageWidth + '%' }]}>
                        <Text style={[styles.tableCell, { margin: 'auto' }]}>{header.name}</Text>
                    </View>
                )
            })
            }
        </View>
    )
}

export default PDFHeader;