import Logo from '../../../assets/postexLogo.png';
import {
    Page,
    Text,
    View,
    Image,
    Document,
    StyleSheet,
    Font,
} from '@react-pdf/renderer';
import Roboto from '../../../assets/fonts/Roboto/Roboto-Regular.ttf';
import { DateYmdToDmY } from 'shared/utils/localeISODateTime';
import { useTranslation } from "react-i18next";
import { IInvoice } from '../type/invoice';
import { IDeliveryStep } from 'pages/Delivery/type/Delivery/DeliveryData';

import { ICustomHeader } from 'MyComponents/Pdf/PDFHeader';
import PDFHeader from 'MyComponents/Pdf/PDFHeader';
import PDFRow, { ICustomRow } from 'MyComponents/Pdf/PDFRow';


const SmallTemplate = (props: {
    invoice: any,
    styles: any;
}) => {
    const { invoice, styles } = props;
    const { t } = useTranslation();

    const headers: Array<ICustomHeader> = [
        { name: "", percentageWidth: "15" },
        { name: t("address") || "", percentageWidth: "35" },
        { name: t("informations") || "", percentageWidth: "35" },
        { name: t("amount") + " (€)" || "", percentageWidth: "15" },
    ]

    const startRows: Array<ICustomRow> = [
        {
            data: <Text style={[styles.tableCell, { margin: 'auto' }]}>{t("Start")}</Text>,
            percentageWidth: "15"
        },
        {
            data:
                <>
                    <Text style={styles.tableCell}>{invoice?.items[0]?.delivery?.addressFrom?.address?.street}</Text>
                    <Text style={styles.tableCell}>{invoice?.items[0]?.delivery?.addressFrom?.address?.zipcode} {invoice?.delivery?.addressFrom?.address?.city}</Text>
                </>,
            percentageWidth: "35"
        },
        {
            data:
                <>
                    <Text style={styles.tableCell}>{invoice?.items[0]?.delivery?.addressFrom?.fullname}</Text>
                    <Text style={styles.tableCell}>{invoice?.items[0]?.delivery?.remark}</Text>
                </>,
            percentageWidth: "35"
        },
        {
            data: <Text style={[styles.tableCell, { margin: 'auto' }]}></Text>,
            percentageWidth: "15"
        },
    ];

    const endRows: Array<ICustomRow> = [
        {
            data: <Text style={[styles.tableCell, { margin: 'auto' }]}>{t("End")}</Text>,
            percentageWidth: "15"
        },
        {
            data:
                <>
                    <Text style={styles.tableCell}>{invoice?.items[0]?.delivery?.addressTo?.address?.street}</Text>
                    <Text style={styles.tableCell}>{invoice?.items[0]?.delivery?.addressTo?.address?.zipcode} {invoice?.items[0]?.delivery?.addressTo?.address?.city}</Text>
                </>,
            percentageWidth: "35"
        },
        {
            data:
                <>
                    <Text style={styles.tableCell}>{invoice?.items[0]?.delivery?.addressTo?.fullname}</Text>
                    <Text style={styles.tableCell}>{invoice?.items[0]?.delivery?.remark}</Text>
                </>,
            percentageWidth: "35"
        },
        {
            data: <Text style={[styles.tableCell, { margin: 'auto' }]}></Text>,
            percentageWidth: "15"
        },
    ];

    const totalHaders: Array<ICustomHeader> = [
        { name: "", percentageWidth: "15" },
        { name: "", percentageWidth: "35" },
        { name: "", percentageWidth: "35" },
        { name: "Total", percentageWidth: "15" },
    ]

    const totalRows: Array<ICustomRow> = [
        {
            data: <></>,
            percentageWidth: '15'
        },
        {
            data: <></>,
            percentageWidth: '35'
        },
        {

            data: <>{DateYmdToDmY(invoice?.items[0]?.delivery?.datetime)}</>,
            percentageWidth: '35'
        },
        {
            data: <Text style={[styles.tableCell, { margin: 'auto' }]}>{invoice?.amount}</Text>,
            percentageWidth: '15'
        },
    ]



    return (
        <>
            <View style={styles.header}>
                <View style={styles.header['& .society']}>
                    <Image src={Logo} style={styles.header['& logo']} />
                    <Text>48 rue du Brill</Text>
                    <Text>L-4041 Esch-sur-Alzette</Text>
                    <Text>{t("phone")} : +352 691 500 006</Text>
                    <Text>{t("email")} : info@postex.lu</Text>
                </View>

                <View style={[styles.header['& .client'], { justifyContent: 'flex-end' }]}>
                    <View style={[styles.header['& .title'], { alignSelf: 'flex-end' }]}>
                        <Text>{t('invoice')}</Text>
                    </View>
                    <View>
                        <Text>{invoice?.fullname}</Text>
                        <Text>{invoice?.items[0]?.delivery?.addressFrom?.address?.street}</Text>
                        <Text>{invoice?.items[0]?.delivery?.addressFrom?.address?.zipcode} {invoice?.items[0]?.delivery?.addressFrom?.address?.city}</Text>
                        <Text>{t("phone")} : {invoice?.items[0]?.delivery?.client?.phone}</Text>
                        <Text>{t("email")} : {invoice?.items[0]?.delivery?.client?.emailFacturation}</Text>
                    </View>
                </View>
            </View>

            <View style={styles.body}>
                <View style={styles.table}>
                    <PDFHeader
                        styles={styles}
                        headers={headers}
                    />
                    <View style={styles.table}>
                        <PDFRow styles={styles} rows={startRows} />
                        {invoice.items[0]?.delivery?.deliverySteps != null &&
                            invoice.items[0]?.delivery?.deliverySteps.length > 0 &&
                            <>
                                {invoice.items[0]?.delivery?.deliverySteps.map((deliveryStep: IDeliveryStep) => {
                                    return (
                                        <View key={"deliveryStep" + deliveryStep.step} style={styles.tableRow}>
                                            <View style={[styles.tableCol, { width: '15%' }]}>
                                                <Text style={[styles.tableCell, { margin: 'auto' }]}>{(deliveryStep?.step != null ? deliveryStep?.step + 1 : "")}</Text>
                                            </View>
                                            <View style={[styles.tableCol, { width: '35%' }]}>
                                                <Text style={styles.tableCell}>{deliveryStep.address?.address?.street}</Text>
                                                <Text style={styles.tableCell}>{deliveryStep.address?.address?.zipcode} {deliveryStep.address?.address?.city}</Text>
                                            </View>
                                            <View style={[styles.tableCol, { width: '35%' }]}>
                                                <Text style={styles.tableCell}>{deliveryStep.address?.fullname}</Text>
                                                <Text style={styles.tableCell}>{deliveryStep.remark}</Text>
                                            </View>
                                            <View style={[styles.tableCol, { width: '15%' }]}>
                                                <Text style={[styles.tableCell, { margin: 'auto' }]}>{deliveryStep?.amount}</Text>
                                            </View>
                                        </View>
                                    )
                                })
                                }
                            </>
                        }
                        <PDFRow styles={styles} rows={endRows} />
                        <PDFHeader styles={styles} headers={totalHaders} />
                        <PDFRow styles={styles} rows={totalRows} />
                    </View>
                </View>

                <View style={styles.footer}>
                    <Text>{t("autorisation")} 10082190/0</Text>
                    <Text>V.A.T : LU 29767256</Text>
                </View>
            </View>
        </>
    );
}

export default SmallTemplate;
