import instance from "shared/auth/service/AxiosInstance";
import { IPassword } from "../type/Password";

const API_URL: string = (process.env.REACT_APP_API_URL + "auth/user" );

const SettingService = {

    changePassword: (id: string, user: IPassword) =>
        instance.put(API_URL + "/pwd/" + id, user)
}

export default SettingService;