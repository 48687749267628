import { 
  Page, 
  Text, 
  View, 
  Image, 
  Document, 
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import Roboto from '../../../assets/fonts/Roboto/Roboto-Regular.ttf';
import { DateYmdToDmY, getISODate, localeISODateTime } from 'shared/utils/localeISODateTime';
import { useTranslation } from "react-i18next";

Font.register({ 
  family: 'Roboto',  
  format: 'truetype',
  src: Roboto
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    fontFamily: "Roboto",
    fontSize: 14,
  },
  body: {
    marginVertical: 100,
    marginHorizontal: 20,
    flexGrow: 2,
  },
  table: {
    width: 'auto',
    borderStyle: "solid", 
    borderWidth: 1, 
  },
  tableHeader:{
    margin: "auto", 
    flexDirection: "row",
    backgroundColor: '#f7A007',
  },
  tableRow:{
    margin: "auto", 
    flexDirection: "row"
  },
  tableCol: { 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCell: {
    margin: 2,
    fontSize: 12
  }
});

// Create Document Component
const RemovaleTemplate = (props: {
  delivery: any,
  qrcode: any,
}) => {
  const {delivery, qrcode} = props;
  const { t } = useTranslation();

  return (
      <Document>
        <Page size="A5" style={styles.page}>
          <View style={styles.body}>
            <View style={styles.table}>
              <View style={[styles.tableRow, {height: '100%'}]}>
                <View style={[styles.tableCol,{width: '50%', display: 'flex', alignContent: 'space-between'}]}>
                  <View style={[styles.table, {flex: 1, border: 0}]}>
                    <View style={[styles.tableRow, {flexGrow: 1}]}>
                      <View style={[styles.tableCol, {width: '100%'}]}>
                        <Text style={[styles.tableCell, {backgroundColor: '#f0f0f0', textAlign: 'center'}]}>{t("pfdSender")}</Text>
                        <Text style={styles.tableCell}>{delivery?.addressFrom?.fullname}</Text>
                        <Text style={styles.tableCell}>{delivery?.addressFrom?.address?.street}</Text>
                        <Text style={styles.tableCell}>{delivery?.addressFrom?.address?.zipcode} {delivery?.addressFrom?.address?.city}</Text>
                        <Text style={styles.tableCell}>{delivery?.addressFrom?.address?.country}</Text>
                        <Text style={styles.tableCell}>{t("shortPhone")}: {delivery?.addressFrom?.phone}</Text>
                      </View>
                    </View>
                    <View style={[styles.tableRow, {flexGrow: 1}]}>
                      <View style={[styles.tableCol, {width: '100%'}]}>
                        <Text style={[styles.tableCell, {backgroundColor: '#f0f0f0', textAlign: 'center'}]}>{t("pfdReceiver")}</Text>
                        <Text style={styles.tableCell}>{delivery?.addressTo?.fullname}</Text>
                        <Text style={styles.tableCell}>{delivery?.addressTo?.address?.street}</Text>
                        <Text style={styles.tableCell}>{delivery?.addressTo?.address?.zipcode} {delivery?.addressTo?.address?.city}</Text>
                        <Text style={styles.tableCell}>{delivery?.addressTo?.address?.country}</Text>
                        <Text style={styles.tableCell}>{t("shortPhone")}: {delivery?.addressTo?.phone}</Text>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={[styles.tableCol, {width: '50%', display: 'flex', alignContent: 'space-between', borderLeft: 1}]}>
                  <View style={styles.tableRow}>
                    <View style={[styles.tableCol, {width: '100%'}]}>
                      <Text style={[styles.tableCell, {backgroundColor: '#f0f0f0', textAlign: 'center'}]}>PostEx</Text>
                      <Text style={styles.tableCell}>202, Route de Belval</Text>
                      <Text style={styles.tableCell}>L-4024 Esch-sur-Alzette</Text>
                      <Text style={styles.tableCell}>LUXEMBOURG</Text>
                      <Text style={styles.tableCell}>{t("shortPhone")} : +352 691 500 006</Text>
                      <Text style={styles.tableCell}>{t("email")} : info@postex.lu</Text>
                    </View>
                  </View>
                  <View style={[styles.tableRow, {flex: 1}]}>
                    <View style={[styles.tableCol, {alignItems: 'center', justifyContent: 'center'}]}>
                      <Text style={styles.tableCell}>QR Code</Text>
                      <Image src={qrcode} style={[styles.tableCell, {width: 128, height: 128}]} />
                    </View>
                  </View>
                  <View style={[styles.tableRow]}>
                    <View style={[styles.tableCol, {width: '100%'}]}>
                      <Text style={[styles.tableCell, {backgroundColor: '#f0f0f0', textAlign: 'center'}]}>{t("package")}</Text>
                      <Text style={styles.tableCell}>Type: {delivery?.type?.name}</Text>
                      <Text style={styles.tableCell}>Date: {DateYmdToDmY(getISODate(localeISODateTime(delivery?.datetime)))}</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>

          </View>
        </Page>
      </Document>
  );  
} 


export default RemovaleTemplate;