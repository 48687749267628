import { MyContainedButton, MyOutlinedButton } from "MyComponents/Generic/MyButton";
import { IMessage } from "pages/Clients/type/Message";
import { CustomModals } from "./customModals";
import { useTranslation } from "react-i18next";

export function DeleteModals(props: {
    open: boolean,
    setOpen: any,
    openAlert: boolean,
    setOpenAlert: any,
    message: IMessage,
    setMessage: any,
    refresh: any,
    title: string,
    handleSubmit: any,
    handleClose: any
}) {

    const {
        open,
        setOpen,
        openAlert,
        setOpenAlert,
        message,
        setMessage,
        title,
        handleSubmit,
        handleClose
    } = props

    const { t } = useTranslation();

    const content =
        <div style={{ textAlign: 'center' }}>
            {t("areYouSureToRemove")}
        </div>

    const actions =
        <>
            <MyOutlinedButton onClick={handleClose}> {t("cancel")} </MyOutlinedButton>
            <MyContainedButton onClick={handleSubmit}> {t("validate")} </MyContainedButton>
        </>
    return (
        <>
            <CustomModals
                open={open}
                handleClose={handleClose}
                title={t("remove") + title}
                content={content}
                actions={actions}
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
                message={message}
                setMessage={setMessage}
            />
        </>
    )
}