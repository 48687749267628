import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Delivery from '../../../Delivery/Delivery';

export function SelectDelivery(props: {
    indexNavBar: any,
    setIndexNavBar: any,
    roles: Array<string>,
    selectedDelivery: any,
    selectedClientId: any,
    handleHideElement: any
    setSelectedDelivery: any,
    setTitle: Function,
    setSelectedDeliveries?: any,
}) {
    const {
        roles,
        indexNavBar,
        setIndexNavBar,
        selectedDelivery,
        selectedClientId,
        handleHideElement,
        setSelectedDelivery,
        setTitle,
        setSelectedDeliveries,
    } = props;

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    setTitle(t("selectDelivery"))

    return (
        <>
            <Delivery
                open={open}
                roles={roles}
                addOpen={open}
                reduced={true}
                setOpen={setOpen}
                setAddOpen={setOpen}
                indexNavBar={indexNavBar}
                setIndexNavBar={setIndexNavBar}
                selectedDelivery={selectedDelivery}
                handleHideElement={handleHideElement}
                selfId={selectedClientId?.user?.userId}
                setSelectedDelivery={setSelectedDelivery}
                setSelectedDeliveries={setSelectedDeliveries}
            />
        </>
    )
}
