import instance from "shared/auth/service/AxiosInstance";

const API_URL = process.env.REACT_APP_API_URL + "auth/role";

const roleService = {

    getAllRole: (pageNo: number, pageSize: number) =>
        instance.get(API_URL + "/?pageSize=" + pageSize + "&pageNo=" + pageNo)

}

export default roleService;