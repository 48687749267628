import React, { useEffect, useState } from 'react';
import MyTitle from '../../MyComponents/Title/MyTitle';
import { styled } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box } from '@mui/material';
import Slide from '@mui/material/Slide';

export function CustomDrawer(props: { title: string, content: any, hide: boolean }) {

  const { title, content, hide } = props;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [open, setOpen] = useState(window.innerWidth < 1024 ? false : true);
  const drawerWidth = 320;

  const handleDrawerToggle = () => {
    setOpen((prevState) => !prevState);
  };

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 1024);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  if (hide) {
    return (<></>)
  } else if (isMobile) {
    return (
      <>
        <Box sx={{
          display: 'relative',
          position: { xs: 'fixed', md: 'static' },
          zIndex: 1,
          top: 80,
          right: 0,
        }}>
          <Onglet onClick={handleDrawerToggle} style={{ display: isMobile ? 'block' : 'none' }}>
            {(open === false) ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
          </Onglet>
          <Slide direction="left" in={open} mountOnEnter unmountOnExit>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              backgroundColor: '#F7A007',
              width: drawerWidth,
              minHeight: 'calc(100vh - 64.5px)',
            }}>
              <MyTitle>{title}</MyTitle>
              {content}
            </Box>
          </Slide>
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box sx={{
          display: 'relative',
          position: { xs: 'fixed', md: 'static' },
          zIndex: 1,
          top: 80,
          right: 0
        }}>
          <Slide direction="left" in={true} mountOnEnter unmountOnExit>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              backgroundColor: '#F7A007',
              width: drawerWidth,
              minHeight: 'calc(100vh - 64.5px)',
              // minHeight:'111vh'
            }}>
              <MyTitle>{title}</MyTitle>
              {content}
            </Box>
          </Slide>
        </Box>
      </>
    )
  }
}

const Onglet = styled('button')({
  position: 'absolute',
  width: 30,
  height: 60,
  marginLeft: -30,
  textAlign: 'center',
  backgroundColor: '#EC5428',
  color: '#fff',
  border: 0,
  borderRadius: '15px 0 0 15px'
});