import { MyTextField } from 'MyComponents/TextField/MyTextField';
import PersonIcon from '@mui/icons-material/Person';
import { MyContainedButton, MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { useDispatch } from "react-redux";
import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
import carrierService from '../service/CarrierService';
import { logout } from 'shared/redux/features/auth/authSlice';
import { CustomModals } from "shared/modals/customModals";
import { ICarrierCreator, ICarrierUserCreator } from '../type/CarrierCreator';
import { useTranslation } from "react-i18next";

export function EditCarrier(props: { open: any, refresh: any, setOpen: any, carrier: any }) {

    const { open, refresh, setOpen, carrier } = props;

    const [userInputs, setUserInputs] = useState<ICarrierUserCreator>({});
    const [inputs, setInputs] = useState<ICarrierCreator>({});

    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState({ type: 'error', content: "" });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleChangeCarrier = (event: any) => {
        setInputs({
            ...inputs,
            [event.target.name]: event.target.value
        });
    }

    const resetInput = () => {
        setInputs({})
        setUserInputs({})
    }

    const handleClose = () => {
        setMessage({ type: 'error', content: "" });
        setOpenAlert(false);
        resetInput();
        setOpen(false);
    }

    const handleSubmit = (id: string) => {
        setMessage({ type: 'info', content: t("Processing") });
        setOpenAlert(true);
        if (inputs != null || userInputs != null) {
            inputs.user = userInputs;
            carrierService.updateCarrier(id, inputs)
                .then(r => {
                    refresh()
                    setMessage({
                        type: "success",
                        content: t("carrier") + t("updatedWithSuccess")
                    });
                    setTimeout(() => {
                        handleClose()
                    }, 1500)
                })
                .catch((err) => {
                    if (
                        err?.statusCode === 403 ||
                        err?.statusCode === 401 ||
                        err?.response?.status === 403 ||
                        err?.response?.status === 401
                    ) {
                        dispatch(logout());
                    } else {
                        setMessage({
                            type: 'error',
                            content: err?.response?.data?.message
                        })
                    }
                }
                );
        }
    }

    const content =
        <>
            <MyModalSubTitle>
                <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                {t("personnalInfo")}
            </MyModalSubTitle>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <MyTextField type='tel' name="phone" pattern="[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}" label={t("phone")} placeholder={"xx xx xx xx xx"} defaultValue={carrier?.phone} onChange={handleChangeCarrier} />
                </Grid>
            </Grid>
        </>

    const actions =
        <>
            <MyOutlinedButton onClick={handleClose}>{t("cancel")}</MyOutlinedButton>
            <MyContainedButton onClick={() => handleSubmit(carrier.id)}>{t("validate")}</MyContainedButton>
        </>

    if (carrier != null && carrier != undefined) {
        return (
            <CustomModals
                open={open}
                handleClose={handleClose}
                title={t("updateCarrier")}
                content={content}
                actions={actions}
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
                message={message}
                setMessage={setMessage}
            />
        )
    } else {
        return (
            <></>
        )
    }
}
