import { GridOverlay } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

function MyCustomNoRowsOverlay() {
  const { t } = useTranslation();
    
    return (
        <GridOverlay>
            <div>{t("nodata")}</div>
        </GridOverlay>
    )
}
export default MyCustomNoRowsOverlay;