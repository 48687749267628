import { MyContainedButton } from 'MyComponents/Generic/MyButton';
import { MyTextField } from 'MyComponents/TextField/MyTextField';
import { ChangeEventHandler, MouseEventHandler, useState } from 'react';
import MyTitle from '../../../MyComponents/Title/MyTitle';
import PersonIcon from '@mui/icons-material/Person';
import Grid from '@mui/material/Grid';
import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
import { IFavoriteAddress } from 'pages/Addresses/type/FavoriteAddress';
import { IPayload } from 'types/payload';
import { IDeliveryCreator } from 'pages/Delivery/type/Delivery/DeliveryCreator';
import { Checkbox, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { MyAutocomplete } from 'MyComponents/TextField/MyAutocomplete';
import { AddAddress } from 'pages/Addresses/modals/AddAddress';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import { useTranslation } from "react-i18next";
import { IOptions } from '../types/Options';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';


function Shippingfrom(props: {
    inputs: IDeliveryCreator,
    setInputs: Function,
    myAdd: IFavoriteAddress | null,
    setMyAdd: Function,
    inputMyAdd: unknown,
    setInputMyAdd: Function,
    myOptions: Array<IOptions>,
    myOptionSelected: any,
    setMyOptionSelected: Function,
    handleAddOpen: MouseEventHandler<HTMLButtonElement>,
    addOpen: boolean,
    setAddOpen: Function,
    fetchMyAdd: Function,
    payload: IPayload,
    setActiveStep: Function,
    loading: boolean,
    handleChangeDelivery: ChangeEventHandler<Element>
}) {
    const {
        inputs,
        setInputs,
        myAdd,
        setMyAdd,
        inputMyAdd,
        setInputMyAdd,
        myOptions,
        myOptionSelected,
        setMyOptionSelected,
        handleAddOpen,
        addOpen,
        setAddOpen,
        fetchMyAdd,
        payload,
        setActiveStep,
        loading,
        handleChangeDelivery
    } = props;

    const { t } = useTranslation();

    const [isComingBack, setIsComingBack] = useState(false);

    const handleCheckBox = () => {
        setIsComingBack(!isComingBack);
        inputs.isComingBack = !isComingBack;
        setInputs(inputs);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <MyTitle>
                {t("startShippingTitle")}
            </MyTitle>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center', width: '90%', border: '3px solid #f5c065', borderRadius: '24px', padding: '3%' }}>
                <MyModalSubTitle>
                    <EmojiFlagsIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                    {t("Start")}
                </MyModalSubTitle>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={10}>
                                <MyAutocomplete
                                    label={t("researchAM") + t("addresse") + t("3dot")}
                                    value={myAdd}
                                    inputValue={inputMyAdd}
                                    options={myOptions}
                                    onChange={(event: any, newValue: any) => {
                                        setMyOptionSelected(myOptions?.find(({ name }) => name === newValue.name))
                                        setMyAdd(newValue);
                                        setInputs({
                                            ...inputs,
                                            ["favAddressFromId"]: newValue.value
                                        })
                                    }}
                                    onInputChange={(event: any, newInputValue: any) => setInputMyAdd(newInputValue)}
                                    getOptionLabel={(value: any) => (value?.name)}
                                    loading={loading}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Tooltip title={t('addAnAddress')} placement='top'>
                                    <IconButton onClick={handleAddOpen}>
                                        <AddLocationIcon sx={{ color: '#F5C065', fontSize: '35px', }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <AddAddress
                            ishome={true}
                            type={t("anAddress")}
                            open={addOpen}
                            refresh={fetchMyAdd}
                            setOpen={setAddOpen}
                            payload={payload}
                        />
                    </Grid>
                </Grid>
                <MyModalSubTitle>
                    <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                    {t("sender")}
                </MyModalSubTitle>
                <Grid container spacing={1} columns={{ xs: 4, md: 12 }}>
                    <Grid item xs={6}>
                        <MyTextField type='text' disabled={true} name='name' label={t("lastName")} value={myOptionSelected?.fullname != null ? myOptionSelected?.fullname : ""} />
                    </Grid>
                    <Grid item xs={6}>
                        <MyTextField type='tel' disabled={true} name="phone" pattern="[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}" label={t("phone")} value={myOptionSelected?.phone != null ? myOptionSelected?.phone : ""} placeholder={"xx xx xx xx xx"} onChange={handleChangeDelivery} />
                    </Grid>
                </Grid>
                <div>Should the Shipping come back to this address ?</div>
                <Grid container spacing={1} columns={{ xs: 3, md: 12 }} >
                    <Grid item xs={6}>
                        {t("yes")}
                        <Checkbox checked={inputs.isComingBack == null ? isComingBack : inputs.isComingBack} onChange={handleCheckBox} />
                    </Grid>
                    <Grid item xs={6}>
                        {t("no")}
                        <Checkbox checked={inputs.isComingBack == null ? !isComingBack : !(inputs.isComingBack)} onChange={handleCheckBox} />
                    </Grid>
                </Grid>

            </div>
            <div>
                <MyContainedButton disabled={(myOptionSelected != null ? false : true)} onClick={() => setActiveStep(1)}>{t("next>")}</MyContainedButton>
            </div>
        </div>
    );

}

export default Shippingfrom;