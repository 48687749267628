import instance from "shared/auth/service/AxiosInstance";
import { IInvoiceMultipleUpdate } from "../type/InvoiceMultipleUpdate";
import { IInvoice } from "../type/invoice";
import { IInvoiceCreator } from "../type/invoiceCreator";


const API_URL: string = (process.env.REACT_APP_API_URL + "invoices");

const invoiceService = {

    getAllInvoice: (pageNo: number, pageSize: number,
        research: string, startDate: string,
        endDate: string, status: string) =>
        instance.get(API_URL + "/?pageSize=" + pageSize + "&pageNo=" + pageNo + "&research=" + research + "&startDate=" + startDate + "&endDate=" + endDate + "&isValidate=" + status),

    getMyInvoice: (pageNo: number, pageSize: number,
        research: string, id: string,
        startDate: string, endDate: string,
        status: string) =>
        instance.get(API_URL + "/me/" + id + "?pageSize=" + pageSize + "&pageNo=" + pageNo + "&research=" + research + "&startDate=" + startDate + "&endDate=" + endDate + "&isValidate=" + status),

    createInvoice: (invoice: IInvoiceCreator) =>
        instance.post(API_URL + "/", invoice),

    updateInvoice: (id: string, invoice: IInvoice) =>
        instance.put(API_URL + "/" + id, invoice),

    validateInvoice: (id: string, data: IInvoiceMultipleUpdate) =>
        instance.put(API_URL + "/validate/" + id, data),

    updateAmount: (id: string, data: IInvoiceMultipleUpdate) =>
        instance.put(API_URL + "/amount/" + id, data),

    sendInvoicesTo: (email: string, invoiceIds: Array<String>) =>
        instance.post(API_URL + "/send/multiple/" + email, invoiceIds),

    exportInvoice: (invoiceId: string) =>
        instance.get(API_URL + "/export/" + invoiceId),

}

export default invoiceService;