import { MyContainedButton, MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import { IClientData } from 'pages/Clients/type/Client/ClientData';
import { IDeliveryData } from 'pages/Delivery/type/Delivery/DeliveryData';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { CustomModals } from "shared/modals/customModals";
import { IPayload } from 'types/payload';
import invoiceService from '../service/InvoiceService';
import { IInvoiceCreator } from '../type/invoiceCreator';
import { SelectAmount } from './AddInvoiceSteps/SelectAmount';
import { SelectClient } from './AddInvoiceSteps/SelectClient';
import { SelectDelivery } from './AddInvoiceSteps/SelectDelivery';


interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
}

interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}

export function AddInvoice(props: {
    open: any,
    refresh: any,
    setOpen: any,
    indexNavBar: any,
    setIndexNavBar: any,
    handleHideElement: any,
    payload: IPayload,
    roles: any
}) {

    const {
        open,
        roles,
        payload,
        refresh,
        setOpen,
        indexNavBar,
        setIndexNavBar,
        handleHideElement,
    } = props;

    const { t } = useTranslation();

    const [inputs, setInputs] = useState<IInvoiceCreator>({
        amount: 0,
        email: "",
        fullname: "",
        items: null
    });

    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState({ type: 'error', content: "" });
    const [activeStep, setActiveStep] = useState(0);
    const [calculate, setCalculate] = useState(false);
    const [isDueDate, setIsDueDate] = useState(false);
    const [title, setTitle] = useState(t("createInvoice" || ""));

    //Client
    const [selectedClient, setSelectedClient] = useState<IClientData>();

    //Delivery
    const [selectedDelivery, setSelectedDelivery] = useState(null);
    const [selectedDeliveries, setSelectedDeliveries] = useState<Array<IDeliveryData>>();

    const handleClose = () => {
        setMessage({ type: '', content: "" });
        setOpenAlert(false);
        setOpen(false);
        setInputs({});
        setSelectedClient(undefined);
        setSelectedDeliveries(undefined);
        setSelectedDelivery(null);
        setActiveStep(0);
        setCalculate(false);
    }

    const handleCalculate = () => {
        setMessage({
            type: "success",
            content: t("Facture") + t("calculatedWithSuccess")
        });
        setOpenAlert(true);
        setCalculate(true);
        inputs.email = selectedClient?.emailFacturation;
        inputs.fullname = selectedClient?.fullname;
        let totalAmount = 0;
        if (inputs?.items != null) {
            inputs?.items.forEach(item => {
                totalAmount += (item?.total != null ? item.total : 0);
            });
        }
        inputs.amount = totalAmount;
        setInputs(inputs)

        setTimeout(() => {
            setOpenAlert(false);
        }, 1500)
    }

    const handleSubmit = () => {
        setMessage({ type: 'info', content: t("Processing") });
        setOpenAlert(true);
        if (inputs !== null) {
            invoiceService.createInvoice(inputs)
                .then(r => {
                    refresh();
                    setMessage({
                        type: "success",
                        content: t("Facture") + t("createdWithSuccess")
                    });
                    setTimeout(() => {
                        handleClose()
                    }, 1500)
                })
                .catch((err) => {
                    setMessage({
                        type: 'error',
                        content: err?.response?.data?.message
                    })
                }
                );
        }
    }


    const handleStepBack = () => {
        setActiveStep(activeStep - 1)
    }

    const handleStepUp = () => {
        setActiveStep(activeStep + 1)
    }

    const content =
        <>
            <div style={{ width: '100vw' }} />
            {activeStep == 0 &&
                <SelectClient
                    indexNavBar={indexNavBar}
                    setIndexNavBar={setIndexNavBar}
                    selectedClient={selectedClient}
                    setSelectedClient={setSelectedClient}
                    open={open}
                    setOpen={setOpen}
                    setTitle={setTitle}
                    handleHideElement={handleHideElement}
                />
            }
            {activeStep == 1 && selectedClient != null &&
                <SelectDelivery
                    indexNavBar={indexNavBar}
                    setIndexNavBar={setIndexNavBar}
                    selectedDelivery={selectedDelivery}
                    setSelectedDelivery={setSelectedDelivery}
                    handleHideElement={handleHideElement}
                    roles={roles}
                    selectedClientId={selectedClient}
                    setTitle={setTitle}
                    setSelectedDeliveries={setSelectedDeliveries}
                />
            }
            {activeStep == 2 && selectedDeliveries != null &&
                <SelectAmount
                    deliveries={selectedDeliveries}
                    inputs={inputs}
                    setInputs={setInputs}
                    setTitle={setTitle}
                    setCalculate={setCalculate}
                    setIsDueDate={setIsDueDate}
                />
            }
        </>

    const actions =
        <>
            {activeStep != 0 &&
                <MyOutlinedButton onClick={handleStepBack}>{t("<back")}</MyOutlinedButton>
            }
            {activeStep == 0 &&
                <MyContainedButton disabled={selectedClient == null ? true : false} onClick={handleStepUp}>{t("next>")}</MyContainedButton>
            }

            {activeStep == 1 &&
                <MyContainedButton disabled={selectedDeliveries == null ? true : false} onClick={handleStepUp}>{t("next>")}</MyContainedButton>
            }

            {activeStep == 2 && !calculate &&
                <MyContainedButton onClick={handleCalculate}>{t("calculate")}</MyContainedButton>
            }

            {activeStep == 2 && calculate &&
                <MyContainedButton disabled={!isDueDate} onClick={handleSubmit}>{t("validate")}</MyContainedButton>
            }
        </>

    return (
        <CustomModals
            open={open}
            handleClose={handleClose}
            // title={t("createInvoice")}
            title={title || ""}
            content={content}
            actions={actions}
            openAlert={openAlert}
            setOpenAlert={setOpenAlert}
            message={message}
            setMessage={setMessage}
        />
    )
}
