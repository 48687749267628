import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import Grid from '@mui/material/Grid';
import { MyContainedButton, MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import { MyTextField, MyTextFieldSelect } from 'MyComponents/TextField/MyTextField';
import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";

import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
import carrierService from 'pages/Carrier/service/CarrierService';
import { useTranslation } from "react-i18next";
import { CustomModals } from "shared/modals/customModals";
import { logout } from 'shared/redux/features/auth/authSlice';
import { isValidEmail } from 'shared/utils/EmailValidator';
import clientService from '../service/ClientService';
import { IClientAddressCreator, IClientCreator, IClientUserCreator, IFavoriteAddressCreator } from "../type/Client/ClientCreator";
import { IMessage } from '../type/Message';
import { IRoleData } from '../type/RoleData';

export function AddClient(props: { open: any, refresh: any, setOpen: any, roles: Array<IRoleData> }) {

    const { open, refresh, setOpen, roles } = props;
    const { t } = useTranslation();

    const [favAddressInputs, setFavAddressInputs] = useState<IFavoriteAddressCreator>({})
    const [addressInputs, setAddressInputs] = useState<IClientAddressCreator>({});
    const [userInputs, setUserInputs] = useState<IClientUserCreator>({});
    const [inputs, setInputs] = useState<IClientCreator>({});

    const [isDisabled, setIsDisabled] = useState(true);

    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState<IMessage>({ type: 'error', content: '' });

    const dispatch = useDispatch();

    const handleChangeClient = (event: any) => {
        setInputs({
            ...inputs,
            [event.target.name]: event.target.value
        });
    }

    const handleChangeFavAddress = (event: any) => {
        setFavAddressInputs({
            ...favAddressInputs,
            [event.target.name]: event.target.value
        })
    }

    const handleChangeAddress = (event: any) => {
        setAddressInputs({
            ...addressInputs,
            [event.target.name]: event.target.value
        });
    }

    const handleChangeUser = (event: any) => {
        let value = event.target.value;
        let name = event.target.name;
        if (name === 'roles') {
            value = [value]
        }
        setUserInputs({
            ...userInputs,
            [name]: value
        });
    }

    const resetInput = () => {
        setFavAddressInputs({});
        setAddressInputs({});
        setUserInputs({});
        setInputs({});
    }

    const handleClose = () => {
        setMessage({ type: 'error', content: '' });
        setOpenAlert(false);
        resetInput();
        setOpen(false);
    }

    useEffect(() => {
        if (userInputs.firstname != null &&
            userInputs.email != null &&
            userInputs.roles != null &&
            userInputs.passwordHash &&
            inputs.email != null &&
            inputs.emailFacturation != null &&
            // isValidEmail(userInputs?.email) &&
            // isValidEmail(inputs?.email) &&
            isValidEmail(inputs?.emailFacturation)) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [inputs, userInputs])

    const handleSubmit = () => {
        setMessage({ type: 'info', content: t("Processing") });
        setOpenAlert(true);
        if (inputs != null || addressInputs != null || userInputs != null) {
            favAddressInputs.address = addressInputs;
            inputs.address = favAddressInputs;
            inputs.user = userInputs;

            if (userInputs?.roles?.includes("transporteur")) {
                carrierService.createCarrier(inputs)
                    .then(r => {
                        refresh()
                        setMessage({
                            type: "success",
                            content: t("Client") + t("createdWithSuccess")
                        });
                        setTimeout(() => {
                            handleClose()
                        }, 1500)
                    })
                    .catch((err) => {
                        if (
                            err?.statusCode === 403 ||
                            err?.statusCode === 401 ||
                            err?.response?.status === 403 ||
                            err?.response?.status === 401
                        ) {
                            dispatch(logout());
                        } else {
                            setMessage({
                                type: 'error',
                                content: err?.response?.data?.message
                            })
                        }
                    });
            }
            clientService.createClient(inputs)
                .then(r => {
                    refresh()
                    setMessage({
                        type: "success",
                        content: t("Client") + t("createdWithSuccess")
                    });
                    setTimeout(() => {
                        handleClose()
                    }, 1500)
                })
                .catch((err) => {
                    if (
                        err?.statusCode === 403 ||
                        err?.statusCode === 401 ||
                        err?.response?.status === 403 ||
                        err?.response?.status === 401
                    ) {
                        dispatch(logout());
                    } else {
                        setMessage({
                            type: 'error',
                            content: err?.response?.data?.message
                        })
                    }
                }
                );
        }
    }

    const content =
        <>
            <MyModalSubTitle>
                <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                {t("connectInfo")}
            </MyModalSubTitle>

            <Grid container spacing={1} columns={{ xs: 3, md: 12 }} >
                <Grid item xs={3}>
                    <MyTextField type='text' name="email" label={t("login")} placeholder={"email@email.lu"} onChange={handleChangeUser} />
                </Grid>
                <Grid item xs={6}>
                    <MyTextField type='password' name="passwordHash" label={t("tempoPwd")} onChange={handleChangeUser} />
                </Grid>
                <Grid item xs={3}>
                    <MyTextFieldSelect name="roles" label={t("role")} onChange={handleChangeUser} options={roles} defaultValue={null} />
                </Grid>
            </Grid>
            <MyModalSubTitle>
                <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                {t("personnalInfo")}
            </MyModalSubTitle>

            <Grid container spacing={1} columns={{ xs: 4, md: 12 }}>
                <Grid item xs={4}>
                    <MyTextField type='text' name="firstname" label={t("firstName")} onChange={handleChangeUser} />
                </Grid>
                <Grid item xs={4}>
                    <MyTextField type='text' name="lastname" label={t("lastName")} onChange={handleChangeUser} />
                </Grid>
                <Grid item xs={4}>
                    <MyTextField type='text' name="company" label={t("company")} onChange={handleChangeClient} />
                </Grid>
                <Grid item xs={6}>
                    <MyTextField type='tel' name="phone" pattern="[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}" label={t("phone")} placeholder={"xx xx xx xx xx"} onChange={handleChangeClient} />
                </Grid>
                <Grid item xs={6}>
                    <MyTextField type='text' name="vatNumber" label={t("tva")} size={{ width: '27.3vh' }} onChange={handleChangeClient} />
                </Grid>
                <Grid item xs={6}>
                    <MyTextField type='text' name="email" label='Email' placeholder={"email@email.lu"} onChange={handleChangeClient} />
                </Grid>
                <Grid item xs={6}>
                    <MyTextField type='text' name="emailFacturation" label={t("emailFacturation")} placeholder={"email@email.lu"} onChange={handleChangeClient} />
                </Grid>
            </Grid>
            <MyModalSubTitle>
                <LocationOnIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                {t("localisation")}
            </MyModalSubTitle>

            <Grid container spacing={1} columns={{ xs: 3, md: 12 }} >
                <Grid item xs={6}>
                    <MyTextField type='text' name="street" label={t("street")} size={{ width: '46vh' }} onChange={handleChangeAddress} />
                </Grid>
                <Grid item xs={3}>
                    <MyTextField type='text' name="city" label={t("city")} size={{ width: '46vh' }} onChange={handleChangeAddress} />
                </Grid>
                <Grid item xs={3}>
                    <MyTextField type='text' name="zipcode" label={t("zipcode")} size={{ width: '46vh' }} onChange={handleChangeAddress} />
                </Grid>
                <Grid item xs={8}>
                    <MyTextField type='text' name="complement" label={t("complement")} size={{ width: '46vh' }} onChange={handleChangeAddress} />
                </Grid>
                <Grid item xs={4}>
                    <MyTextField type='text' name="country" label={t("country")} size={{ width: '46vh' }} onChange={handleChangeAddress} />
                </Grid>
                <Grid item xs={12}>
                    <MyTextField type='text' name="label" label={t("label")} size={{ width: '46vh' }} onChange={handleChangeFavAddress} />
                </Grid>
            </Grid>
        </>

    const actions =
        <>
            <MyOutlinedButton onClick={handleClose}>{t("cancel")}</MyOutlinedButton>
            <MyContainedButton disabled={isDisabled} onClick={() => handleSubmit()}>{t("validate")}</MyContainedButton>
        </>

    return (
        <CustomModals
            open={open}
            handleClose={handleClose}
            title={t("addAClient")}
            content={content}
            actions={actions}
            openAlert={openAlert}
            setOpenAlert={setOpenAlert}
            message={message}
            setMessage={setMessage}
        />
    )
}
