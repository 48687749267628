import instance from "shared/auth/service/AxiosInstance";
import { IClientCreator } from "../type/Client/ClientCreator";

const API_URL = process.env.REACT_APP_API_URL + "addresses/client";

const clientService = {

    getAllClient: (pageNo: number, pageSize: number, research: string) =>
        instance.get(API_URL + "/?pageSize=" + pageSize + "&pageNo=" + pageNo + "&research=" + research),

    createClient: (client: IClientCreator) =>
        instance.post(API_URL + "/", client),

    updateClient: (id: string, client: IClientCreator) =>
        instance.put(API_URL + "/" + id, client),

    deleteClient: (id: string) =>
        instance.delete(API_URL + '/locked/' + id),
}

export default clientService;   