// import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
// import FlagIcon from '@mui/icons-material/Flag';
// import LocalShippingIcon from '@mui/icons-material/LocalShipping';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import PanoramaVerticalSelectIcon from '@mui/icons-material/PanoramaVerticalSelect';
// import { Grid, Tooltip } from '@mui/material';
// import { MyTextField } from 'MyComponents/TextField/MyTextField';
// import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
// import { IDeliveryStep } from 'pages/Delivery/type/Delivery/DeliveryData';
import { MyContainedButton, MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import { IDeliveryData } from 'pages/Delivery/type/Delivery/DeliveryData';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CustomModals } from "shared/modals/customModals";
import { logout } from 'shared/redux/features/auth/authSlice';
import invoiceService from '../service/InvoiceService';
import { IInvoiceMultipleUpdate } from '../type/InvoiceMultipleUpdate';
import { IInvoice, IItems } from '../type/invoice';
import { IInvoiceCreator } from '../type/invoiceCreator';
import { SelectAmount } from './AddInvoiceSteps/SelectAmount';


export function EditInvoice(props: {
    inputs: IInvoiceMultipleUpdate,
    setInputs: Function,
    open: any,
    refresh: Function,
    setOpen: Function,
    invoice: IInvoice,
    setInvoiceToShow: Function,
    deliverySteps: any,
    setDeliverySteps: Function,
    invoiceItems: any,
}) {

    const {
        //  inputs, setInputs, 
        open, refresh, setOpen, invoice, setInvoiceToShow, deliverySteps, setDeliverySteps, invoiceItems } = props;
    const { t } = useTranslation();

    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState({ type: 'error', content: "" });
    const [calculate, setCalculate] = useState(false);
    const [isDueDate, setIsDueDate] = useState(false);
    const [title, setTitle] = useState("Update Invoice");
    const [deliveries, setDeliveries] = useState<Array<IDeliveryData>>();
    const [inputs, setInputs] = useState<IInvoiceCreator>({
        amount: 0,
        email: "",
        fullname: "",
        items: null
    })

    useEffect(() => {
        let tempoDeliveries: Array<IDeliveryData> = [];

        invoiceItems?.map((item: IItems) => {
                if (item?.delivery != null) {
                    tempoDeliveries.push(item.delivery);
                }
            })
        setDeliveries(tempoDeliveries);
        
    }, [])

    const dispatch = useDispatch();

    const handleCalculate = () => {
        setMessage({
            type: "success",
            content: t("Facture") + t("calculatedWithSuccess")
        });
        setOpenAlert(true);
        setCalculate(true);
        // inputs.email = selectedClient?.emailFacturation;
        // inputs.fullname = selectedClient?.fullname;
        let totalAmount = 0;
        if (inputs?.items != null) {
            inputs?.items.forEach(item => {
                totalAmount += (item?.total != null ? item.total : 0);
            });
        }
        inputs.amount = totalAmount;
        setInputs(inputs)

        setTimeout(() => {
            setOpenAlert(false);
        }, 1500)
    }

    // const handleChangeDeliverySteps = (event: any) => {
    //     if (inputs?.deliveryStepUpdates != null) {

    //         var newElement: IDeliveryStepUpdate = {};
    //         var newIndex = -1;
    //         inputs?.deliveryStepUpdates?.filter(
    //             (item: IDeliveryStepUpdate, index: number) => {
    //                 if (item.deliveryStepId === event.target.name) {
    //                     newElement = {
    //                         deliveryStepId: item.deliveryStepId,
    //                         amount: +event.target.value
    //                     };
    //                     newIndex = index;
    //                 }
    //             })


    //         inputs?.deliveryStepUpdates?.splice(newIndex, 1);
    //         inputs?.deliveryStepUpdates.push(newElement)
    //         setInputs(inputs)
    //     }
    // }

    const handleChangeAmount = (event: any) => {
        setInputs({
            ...inputs,
            [event.target.name]: +event.target.value
        });
    }

    const handleClose = () => {
        setMessage({ type: '', content: "" });
        setOpenAlert(false);
        setOpen(false);
    }

    const handleSubmit = () => {
        setMessage({ type: 'info', content: t("Processing") });
        setOpenAlert(true);
        if (inputs != null && invoice?.id != null) {
            invoiceService.validateInvoice(invoice.id, inputs)
                .then(r => {
                    refresh();
                    const { invoiceId, ...rest } = r.data;
                    setInvoiceToShow({ id: invoiceId, ...rest });
                    setMessage({
                        type: "success",
                        content: "Facture modifiée avec succès!"
                    });
                    setTimeout(() => {
                        handleClose()
                    }, 1500)
                })
                .catch((err) => {
                    if (
                        err?.statusCode === 403 ||
                        err?.statusCode === 401 ||
                        err?.response?.status === 403 ||
                        err?.response?.status === 401
                    ) {
                        dispatch(logout());
                    } else {
                        setMessage({
                            type: 'error',
                            content: err?.response?.data?.message
                        })
                    }
                }
            );
        }
    }

    const content =
        <>
            {deliveries != null &&
                <SelectAmount
                    deliveries={deliveries}
                    inputs={inputs}
                    setInputs={setInputs}
                    setTitle={setTitle}
                    setCalculate={setCalculate}
                    setIsDueDate={setIsDueDate}
                />
            }
            
            {/* {invoiceItems?.map((item: IItems) => {
                return (
                    <>
                        <MyModalSubTitle>
                            <LocalShippingIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                            {t("Livraison") + " " + item?.delivery?.trackingNumber}
                        </MyModalSubTitle>
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                                <MyTextField disabled type='text' name="fullname" label={t("fullname")} defaultValue={invoice?.fullname} />
                            </Grid>
                            <Grid item xs={4}>
                                <MyTextField disabled type='text' name="datetime" label='Date' defaultValue={(item?.delivery?.datetime?.slice(0, 10))} />
                            </Grid>
                            <Grid item xs={8}>
                                <MyTextField disabled type='text' name="emailFacturation" label={t("emailFacturation")} defaultValue={invoice?.email} />
                            </Grid>
                            <Grid item xs={4}>
                                <MyTextField disabled type='text' name="type" label='Type' defaultValue={item?.delivery?.type?.name} />
                            </Grid>
                        </Grid>
                        <MyModalSubTitle>
                            <LocationOnIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                            {t("journey")}
                        </MyModalSubTitle>

                        <Grid container spacing={1} columns={{ xs: 4, md: 12 }}>
                            <Grid item xs={1}>
                                <EmojiFlagsIcon sx={{ color: '#009CFF', fontSize: '40px', marginTop: '10px' }} />
                            </Grid>
                            <Grid item xs={11}>
                                <MyTextField disabled={true} type='text' name="favAddressFromId" label={t("addressFrom")} defaultValue={
                                    item?.delivery?.addressFrom?.address?.street + " " +
                                    item?.delivery?.addressFrom?.address?.city + " " +
                                    item?.delivery?.addressFrom?.address?.complement
                                }
                                />
                            </Grid>
                            {item?.delivery?.deliverySteps != null && item?.delivery?.deliverySteps?.length > 0 &&
                                <Grid item xs={12}>
                                    {item?.delivery?.deliverySteps.map((deliveryStep: IDeliveryStep) => {
                                        return (
                                            <Grid container spacing={1} columns={{ xs: 7, md: 12 }}>
                                                <Grid item xs={1}>
                                                    <Tooltip title={(item?.delivery?.deliverySteps?.find(({ deliveryStepId }) => deliveryStepId === deliveryStep?.deliveryStepId))?.step || ""} placement='top'>
                                                        <PanoramaVerticalSelectIcon sx={{ color: '#009CFF', fontSize: '25px', marginTop: '30px' }} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <MyTextField
                                                        disabled={true}
                                                        type='text'
                                                        name="deliveryStepId"
                                                        value={
                                                            deliveryStep?.address?.address?.street + " " +
                                                            deliveryStep?.address?.address?.city + " " +
                                                            deliveryStep?.address?.address?.country
                                                        }
                                                        label={t("stepAddresses") + (deliveryStep?.step || "")} />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <MyTextField
                                                        type='number'
                                                        name={deliveryStep.deliveryStepId != null ? deliveryStep.deliveryStepId : "name"}
                                                        defaultValue={deliveryStep?.amount}
                                                        label={
                                                            t("amountStep") +
                                                            (item?.delivery?.deliverySteps?.find(({ deliveryStepId }) => deliveryStepId === deliveryStep?.deliveryStepId))?.step || ""
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                    }
                                </Grid>
                            }
                            <Grid item xs={1}>
                                <FlagIcon sx={{ color: '#009CFF', fontSize: '40px', marginTop: '10px' }} />
                            </Grid>
                            <Grid item xs={11}>
                                <MyTextField disabled={true} type='text' name="favAddressToId" label={t("addressTo")} defaultValue={
                                    item?.delivery?.addressTo?.address?.street + " " +
                                    item?.delivery?.addressTo?.address?.city + " " +
                                    item?.delivery?.addressTo?.address?.complement}
                                />
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={3}>
                                <MyTextField
                                    type='number'
                                    name="tva"
                                    // value={inputs?.tva}
                                    label={t("TVA")}
                                    defaultValue={item.tva}
                                    onChange={handleChangeAmount}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <MyTextField
                                    type='number'
                                    name="amountb"
                                    value={inputs?.amount}
                                    defaultValue={item.amount}
                                    label={t("amount")}
                                    onChange={handleChangeAmount}
                                />
                            </Grid>

                        </Grid>
                    </>
                )
            })
            } */}
            {/* <Grid item xs={9}></Grid>
            <Grid item xs={3}>
                <MyTextField
                    type='number'
                    name="amount"
                    value={inputs?.amount}
                    label={t("total")}
                    onChange={handleChangeAmount}
                />
            </Grid> */}
        </>

    const actions =
        <>
            <MyOutlinedButton onClick={handleClose}>{t("cancel")}</MyOutlinedButton>
            {!calculate &&
                <MyContainedButton onClick={handleCalculate}>{t("calculate")}</MyContainedButton>
            }

            {calculate &&
                <MyContainedButton disabled={!isDueDate} onClick={handleSubmit}>{t("validate")}</MyContainedButton>
            }
            {/* <MyContainedButton onClick={handleSubmit}>{t("update")}</MyContainedButton> */}
        </>

    if (invoice != null && invoice !== undefined) {
        return (
            <CustomModals
                open={open}
                handleClose={handleClose}
                title={t("updateInvoice")}
                content={content}
                actions={actions}
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
                message={message}
                setMessage={setMessage}
            />
        )
    } else {
        return (
            <></>
        )
    }
}
