import { DataGrid, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';

function MyLoadingDataGrid(props: {
    columns: GridColDef[],
    columnVisibilityModel?: GridColumnVisibilityModel,
    setColumnVisibilityModel?: Function
    rowCountState: number
}) {

    const {
        columns,
        columnVisibilityModel,
        setColumnVisibilityModel,
        rowCountState
    } = props;

    return (
        <DataGrid
            getRowHeight={() => 'auto'}
            sx={{
                borderRadius: '10px',
                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                '.MuiDataGrid-columnSeparator': {
                    display: 'none',
                },
                '&.MuiDataGrid-root': {
                    border: 'none',
                },
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => {
                if (setColumnVisibilityModel != null) {
                    setColumnVisibilityModel(newModel)
                }
            }
            }
            columns={columns}
            rows={[]}
            rowCount={rowCountState}
            pagination
            pageSize={10}
            autoHeight
            loading={true}
        />
    )
}
export default MyLoadingDataGrid;