import { styled } from '@mui/material/styles';
import logo from "../../../assets/newLogo.jpg";

export const StatusPage = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  '& .header': {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '80px',
    minHeight: '80px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    backgroundColor: '#EC5428',
    zIndex: 1,
    '::before': {
      // content: logo,
      position: 'absolute',
      backgroundColor: '#fff',
      color: '#fff',
      zIndex: 2,
      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100px',
      padding: '0 20px',
    },
  },
  '& .content': {
    width: '100%',
    marginTop: '80px',
    paddingTop: '-80px',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F7A007',
    '& .info': {
      minHeight: 'calc(100vh - 80px)',
      backgroundColor: '#fff',
    }
  }
}));