import { styled } from '@mui/material/styles';

const MyModalSubTitle = styled('div')(() => ({
    marginTop:'3%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Sansation, PinkBlue, Helvetica, sans-serif, -apple-system',
    fontSize: '25px',
    color: '#333333',
}));

export default MyModalSubTitle;