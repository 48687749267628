import PersonIcon from '@mui/icons-material/Person';
import Grid from '@mui/material/Grid';
import { MyContainedButton, MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import { MyTextField } from 'MyComponents/TextField/MyTextField';
import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
import { IMessage } from 'pages/Clients/type/Message';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CustomModals } from "shared/modals/customModals";
import { logout } from 'shared/redux/features/auth/authSlice';
import { isValidEmail } from 'shared/utils/EmailValidator';
import carrierService from '../service/CarrierService';
import { ICarrierCreator, ICarrierUserCreator } from '../type/CarrierCreator';

export function AddCarrier(props: { open: any, refresh: any, setOpen: any }) {

    const { open, refresh, setOpen } = props;
    const { t } = useTranslation();

    const [userInputs, setUserInputs] = useState<ICarrierUserCreator>({});
    const [inputs, setInputs] = useState<ICarrierCreator>({});

    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState<IMessage>({ type: 'error', content: '' });

    const [isDisabled, setIsDisabled] = useState(true);
    const dispatch = useDispatch();

    const handleChangeCarrier = (event: any) => {
        setInputs({
            ...inputs,
            [event.target.name]: event.target.value
        });
    }

    const handleChangeUser = (event: any) => {
        let value = event.target.value;
        if (event.target.name === 'roles') {
            value = [value]
        }
        setUserInputs({
            ...userInputs,
            [event.target.name]: value
        });
        
        // if (userInputs.firstname != null && userInputs.email != null && userInputs.passwordHash != null && isValidEmail(userInputs?.email)) {
        //     setIsDisabled(false)
        // } else {
        //     setIsDisabled(true)
        // }
    }

    useEffect(() => {
        if (userInputs.firstname != null &&
            userInputs.email != null &&
            userInputs.passwordHash != null &&
            isValidEmail(userInputs?.email)) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [inputs, userInputs])

    const resetInput = () => {
        setInputs({})
        setUserInputs({})
    }

    const handleClose = () => {
        setMessage({ type: 'error', content: '' });
        setOpenAlert(false);
        resetInput();
        setOpen(false);
    }

    const handleSubmit = () => {
        setMessage({ type: 'info', content: t("Processing") });
        setOpenAlert(true);
        if (inputs != null || userInputs != null) {
            inputs.user = userInputs;
            carrierService.createCarrier(inputs)
                .then(r => {
                    refresh()
                    setMessage({
                        type: "success",
                        content: t("carrier") + t("createdWithSuccess")
                    });
                    setTimeout(() => {
                        handleClose()
                    }, 1500)
                })
                .catch((err) => {
                    if (
                        err?.statusCode === 403 ||
                        err?.statusCode === 401 ||
                        err?.response?.status === 403 ||
                        err?.response?.status === 401
                    ) {
                        dispatch(logout());
                    } else {
                        setMessage({
                            type: 'error',
                            content: err?.response?.data?.message
                        })
                    }
                });
        }
    }

    const content =
        <>
            <MyModalSubTitle>
                <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                {t("connectInfo")}
            </MyModalSubTitle>

            <Grid container spacing={1} columns={{ xs: 4, md: 12 }} >
                <Grid item xs={4}>
                    <MyTextField type='text' name="email" label={t("login")} placeholder={"email@email.lu"} onChange={handleChangeUser} />
                </Grid>
                <Grid item xs={8}>
                    <MyTextField type='password' name="passwordHash" label={t("tempoPwd")} onChange={handleChangeUser} />
                </Grid>
            </Grid>
            <MyModalSubTitle>
                <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                {t("personnalInfo")}
            </MyModalSubTitle>

            <Grid container spacing={1} columns={{ xs: 4, md: 12 }} >
                <Grid item xs={4}>
                    <MyTextField type='text' name="firstname" label={t("firstName")} onChange={handleChangeUser} />
                </Grid>
                <Grid item xs={4}>
                    <MyTextField type='text' name="lastname" label={t("lastName")} onChange={handleChangeUser} />
                </Grid>
                <Grid item xs={4}>
                    <MyTextField type='tel' name="phone" pattern="[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}" label={t("phone")} placeholder={"xx xx xx xx xx"} onChange={handleChangeCarrier} />
                </Grid>
            </Grid>
        </>

    const actions =
        <>
            <MyOutlinedButton onClick={handleClose}>{t("cancel")}</MyOutlinedButton>
            <MyContainedButton disabled={isDisabled} onClick={() => handleSubmit()}>{t("validate")}</MyContainedButton>
        </>

    return (
        <CustomModals
            open={open}
            handleClose={handleClose}
            title={t("addACarrier")}
            content={content}
            actions={actions}
            openAlert={openAlert}
            setOpenAlert={setOpenAlert}
            message={message}
            setMessage={setMessage}
        />
    )
}
