import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MyTitle from 'MyComponents/Title/MyTitle';
import { MyTextField } from 'MyComponents/TextField/MyTextField';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { MyContainedButton, MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import Select from 'react-select';
import { useEffect, useState } from 'react';
import { logout } from 'shared/redux/features/auth/authSlice';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ICarrierData } from 'pages/Carrier/type/CarrierData';
import { useDispatch } from "react-redux";
import carrierService from 'pages/Carrier/service/CarrierService';
import { CustomModals } from 'shared/modals/customModals';
import { IMessage } from 'pages/Clients/type/Message';
import Tooltip from '@mui/material/Tooltip';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import SearchIcon from "@mui/icons-material/Search";
import Delivery from '../Delivery';
import deliveryService from '../service/DeliveryService';
import { IDeliveryData } from '../type/Delivery/DeliveryData';
import { IDeliveryCreator } from '../type/Delivery/DeliveryCreator';
import { useTranslation } from "react-i18next";

export function AttributeDelivery(props: { delivery: IDeliveryData, open: any, refresh: any, setOpen: any, type: string }) {

    const { t } = useTranslation();

    const { open, refresh, setOpen, type, delivery } = props;
    const [indexStatus, setIndexStatus] = useState(4);

    const [research, setResearch] = useState("");
    const [input, setInput] = useState<ICarrierData | null>();

    const [openAlert, setOpenAlert] = useState(false);
    const [errors, setErrors] = useState<IMessage>({ type: 'error', content: '' });
    const dispatch = useDispatch();

    const [carriers, setCarriers] = useState<Array<ICarrierData>>();
    const [rowCountState, setRowCountState] = useState(0);
    const [selectionModel, setSelectionModel] = useState([]);
    const [defaultPageNo, setDefaultPageNo] = useState(0);
    const [defaultPageSize, setDefaultPageSize] = useState(40);
    const [rowsState, setRowsState] = useState({
        page: 0,
        pageSize: 40,
    });


    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "Id",
            hideable: true,
            flex: 1,
            headerClassName: "researchColumn",
            headerAlign: "center",
            align: "center",
        },
        {
            field: 'user',
            headerName: t("lastName") || "",
            flex: 1,
            headerAlign: "center",
            headerClassName: "researchColumn",
            align: "center",
            renderCell: (data) => ((data?.row?.user?.firstname || "") + " " + data?.row?.user?.lastname || ""),
        },
        {
            field: 'useremail',
            headerName: 'email',
            flex: 1,
            headerAlign: "center",
            headerClassName: "researchColumn",
            align: "center",
            renderCell: (data) => data?.row?.user?.email,
        },
        {
            field: 'phone',
            headerName: t('phone') || "",
            headerAlign: "center",
            type: 'number',
            headerClassName: "researchColumn",
            align: "center",
            flex: 1,
        },
    ];

    const refreshCarrier = (
        { pageNo, pageSize } = {
            pageNo: rowsState.page,
            pageSize: rowsState.pageSize,
        }
    ) => {
        return carrierService.getAllCarrier(pageNo, pageSize, research).then(
            (response: any) => {
                const data = response.data.content;
                setCarriers(
                    data.map((row: ICarrierData) => {
                        const { carrierId, ...rest } = row;
                        return { id: carrierId, ...rest };
                    })
                );
                setRowCountState(response.data.totalElements);
            }
        ).catch((err) => {
            if (
                err?.statusCode === 403 ||
                err?.statusCode === 401 ||
                err?.statusCode === 400 ||
                err?.response?.status === 403 ||
                err?.response?.status === 401 ||
                err?.response?.status === 400
            ) {
                dispatch(
                    logout()
                );
            } else {
                setErrors(err?.response?.data?.error)
            }
        });
    };

    useEffect(() => {
        const timeoutId = setTimeout(
            () => refreshCarrier({
                pageNo: defaultPageNo,
                pageSize: defaultPageSize
            }),
            300
        );
        return () => clearTimeout(timeoutId);
    }, [research])

    const resetResearch = () => {
        setResearch("");
        setInput(null);
    }

    const handleClose = () => {
        setErrors({ type: 'error', content: '' });
        setOpenAlert(false);
        resetResearch();
        setOpen(false);
    }

    const handleChange = (event: any) => {
        setResearch(event.target.value);
    }

    const handleSubmit = () => {
        if (delivery != null) {
            setErrors({ type: 'info', content: t("Processing") });
            setOpenAlert(true);
            let finalDelivery = {} as IDeliveryCreator;
            finalDelivery.carrierId = input?.id
            deliveryService.addingDeliveryCarrier(delivery?.id, finalDelivery)
                .then(r => {
                    refresh()
                    setErrors({
                        type: "success",
                        content: t("deliveryAttribute")
                    });
                    setTimeout(() => {
                        handleClose()
                    }, 1500)
                })
                .catch((err) => {
                    if (
                        err?.statusCode === 403 ||
                        err?.statusCode === 401 ||
                        err?.response?.status === 403 ||
                        err?.response?.status === 401
                    ) {
                        dispatch(logout());
                    } else {
                        setErrors({
                            type: 'error',
                            content: err?.response?.data?.message
                        })
                    }
                });

        }
    }

    const handlePageChange = (page: any) => {
        setDefaultPageNo(page);
        setRowsState((prev) => ({ ...prev, page }));
        refresh({ pageNo: page, pageSize: defaultPageSize });
    }

    const handlePageSizeChange = (pageSize: any) => {
        setDefaultPageSize(pageSize);
        setRowsState((prev) => ({ ...prev, pageSize }));
        refresh({ pageNo: defaultPageNo, pageSize: pageSize });
    }

    const handleSelectionModelChange = (ids: any) => {
        const selectedIDs = new Set(ids);
        carriers?.filter((carrier: any) => {
            if (selectedIDs.has(carrier.id)) {
                setInput(carrier)
            }
        }
        );
    }

    const content = <>
        <div style={{ display: 'flex', flexDirection: 'column', height: '50vh', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <input
                            onChange={handleChange}
                            className="searchInput"
                            value={research}
                            id="reasearch"
                            name="research"
                            placeholder={t("researchAM") + t("carrier") + t("3dot")}
                            style={{
                                border: "3.5px solid #F5C065",
                                borderRadius: "8px",
                                width: '60vh',
                                height: '3vh',
                                padding: '1vh 2vh',
                                fontSize: '16px',
                            }}
                        />
                    </div>
                </div>
            </div>
            <Box sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                textAlign: 'center',
                marginTop: '2vh',
                '& .researchColumn': {
                    backgroundColor: '#F5C065',
                    fontFamily: 'Sansation, PinkBlue, Helvetica, sans-serif, -apple-system',
                    fontSize: '20px',
                    color: '#333333',
                },
            }}>
                {(carriers != null) ? (

                    <div style={{ width: '94%', height: '96%' }}>
                        <DataGrid
                            sx={{
                                borderRadius: '10px',
                                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                                '.MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                },
                                '&.MuiDataGrid-root': {
                                    border: 'none',
                                },
                            }}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    },
                                },
                            }}
                            columns={columns}
                            rows={carriers}
                            rowCount={rowCountState}
                            pagination
                            {...rowsState}
                            showCellRightBorder
                            paginationMode="server"
                            selectionModel={selectionModel}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                            onSelectionModelChange={handleSelectionModelChange}
                        />
                    </div>)
                    :
                    (
                        (errors == null) ?
                            (
                                <>Loading data</>

                            ) : (
                                <>{t("somethingWentWrong")}{errors}</>
                            )
                    )
                }
            </Box>
        </div>
    </>

    const actions =
        <>
            <MyOutlinedButton onClick={handleClose}>{t("cancel")}</MyOutlinedButton>
            <MyContainedButton disabled={input != null ? false : true} onClick={() => handleSubmit()}>{t("validate")}</MyContainedButton>
        </>

    if (carriers != null) {
        return (
            <CustomModals
                open={open}
                handleClose={handleClose}
                title={t("addACarrier")}
                content={content}
                actions={actions}
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
                message={errors}
                setMessage={setErrors}
            />
        )
    } else {
        return (
            <></>
        )
    }
}