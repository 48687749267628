import { useEffect, useState } from 'react';
import { MyTextFieldSelect } from "../TextField/MyTextField";

export interface IOptions {
    label?: string;
    name?: string;
}

export default function MyStatusSelect(props: {
    input: any,
    setInput: Function
}
) {

    const { input, setInput } = props;

    const options = [
        { label: "Created", name: 'created', statusIndex: 0 },
        { label: "Pending", name: 'pending', statusIndex: 1 },
        { label: "Picking up", name: 'picking up', statusIndex: 2 },
        { label: "Delivering", name: 'delivering', statusIndex: 3 },
        { label: "Shipped", name: 'shipped', statusIndex: 4 }
    ]

    const [indexStatus, setIndexStatus] = useState(5);

    const setStatusStyle = (status: string) => {
        if (status != null) {
            let tmp = options.find(options => options.name === status)
            if (tmp != null) {
                setIndexStatus(tmp.statusIndex);
            }
        }
    };

    const handleChange = (event: any) => {
        const name = event.target.name;
        const value = event.target.value;
        setStatusStyle(value);
        setInput((values: any) => ({ ...values, [name]: value }));
    }

    const styleStatusChip = [
        {
            backgroundColor: input.status !== "" ? 'rgba(151, 148, 131, 0.3)' : 'white',
            border: input.status !== "" ? '3.5px solid #8b8b8b' : "3.5px solid #F5C065",
        }, {
            backgroundColor: input.status !== "" ? '#eccd2a4c' : 'white',
            border: input.status !== "" ? '3.5px solid #e6c72b' : "3.5px solid #F5C065",
        },
        {
            backgroundColor: input.status !== "" ? 'rgba(236, 145, 42, 0.3)' : 'white',
            border: input.status !== "" ? '3.5px solid #ec952a' : "3.5px solid #F5C065",
        },
        {
            backgroundColor: input.status !== "" ? 'rgba(42, 143, 236, 0.3)' : 'white',
            border: input.status !== "" ? '3.5px solid #2A8FEC' : "3.5px solid #F5C065",
        },
        {
            backgroundColor: input.status !== "" ? 'rgba(31, 180, 63, 0.3)' : 'white',
            border: input.status !== "" ? '3.5px solid #1FB43F' : "3.5px solid #F5C065",
        },
        {
            backgroundColor: input.status !== "" ? 'rgba(221, 221, 221, 0.3)' : 'white',
            border: input.status !== "" ? '3.5px solid rgb(221, 221, 221)' : "3.5px solid #F5C065",
        }];

    useEffect(() => {
        setStatusStyle(input.status);
    }, [])

    return (
        <>
            <MyTextFieldSelect options={options} value={input.status} name="status" label="Statut" onChange={handleChange}
                customStyle={{
                    textAlign: 'center',
                    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                    fontSize: '16px',
                    borderRadius: "8px",
                    height: '30px',
                    ...styleStatusChip[indexStatus]
                }} />
        </>
    )
};