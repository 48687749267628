import PersonIcon from '@mui/icons-material/Person';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { MyEditButton } from '../../MyComponents/Generic/MyButton';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { MyDrawerParagraph, MyDrawerRow } from '../../MyComponents/Generic/MyDrawer';
import Zoom from '@mui/material/Zoom';
import { useTranslation } from "react-i18next";

function DrawerClients(props: { client: any, open: any, handleOpen: any }) {

  const { client, handleOpen } = props;
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
      <PersonIcon sx={{ fontSize: '120px', color: '#009CFF' }} />
      <p style={{ fontSize: '20px', marginTop: '0px' }}>
        {client != null && client != undefined &&
          client?.user?.firstname?.charAt(0)?.toUpperCase() + client?.user?.firstname?.slice(1) + " " + client?.user?.lastname
        }
      </p>
      <Zoom in={true} style={{ transitionDelay: '500ms' }}>
        <div style={{
          borderRadius: '12px', backgroundColor: '#F2F2F2', display: 'flex', flexDirection: "column", width: '75%', padding: '4vh 3vh', justifyContent: 'space-between', textAlign: 'center', alignItems: 'center', boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)'
        }}>
          <div>
            {client != null && client != undefined &&
              <>
                <MyDrawerRow>
                  <LocationOnIcon sx={{ marginRight: '2vh', color: '#009CFF', fontSize: '40px' }} />
                  <MyDrawerParagraph>
                    {
                      client?.address[0]?.address?.street + ", " +
                      client?.address[0]?.address?.city + " (" +
                      client?.address[0]?.label + ")"
                    }
                  </MyDrawerParagraph>
                </MyDrawerRow>
                <MyDrawerRow>
                  <LocalPhoneIcon sx={{ marginRight: '2vh', color: '#009CFF', fontSize: '40px' }} />
                  <MyDrawerParagraph>
                    {client?.phone}
                  </MyDrawerParagraph>
                </MyDrawerRow>
                <MyDrawerRow>
                  <EmailOutlinedIcon sx={{ marginRight: '2vh', color: '#009CFF', fontSize: '40px' }} />
                  <MyDrawerParagraph>
                    {client?.email}
                  </MyDrawerParagraph>
                </MyDrawerRow>
              </>
            }
          </div>
          {client != null && client != undefined ?
            (
              <>
                <MyEditButton onClick={handleOpen} disabled={client === null || client === undefined ? true : false}>
                  {t("seeMore")}
                </MyEditButton>
              </>
            )
            :
            (
              t("noClientSelected")
            )

          }
        </div>
      </Zoom>
    </div>
  );
}

export default DrawerClients;
