import { useEffect, useRef, useState } from 'react';

import { IMessage } from 'pages/Clients/type/Message';
import deliveryService from '../service/DeliveryService';
import { useParams } from 'react-router-dom';
import { logout } from 'shared/redux/features/auth/authSlice';
import { IDeliveryData, IDeliveryStep } from '../type/Delivery/DeliveryData';
import { useDispatch } from 'react-redux';

import StartDelivery from './StartDelivery';
import EndDelivery from './EndDelivery';
import DeliveryStep from './DeliveryStep';
import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
import { Box } from '@mui/material';
import { StatusPage } from './StatusPage';
import { useTranslation } from "react-i18next";

function DeliveryStatus(props: { setOpen: any, open: boolean, handleHideElement: any }) {
    const { id } = useParams();
    const {
        open,
        setOpen,
        handleHideElement
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [delivery, setDelivery] = useState<IDeliveryData | null>();
    const [multiStep, setMultiStep] = useState(false);
    const [errors, setErrors] = useState<IMessage>({ type: 'error', content: '' });
    const [openAlert, setOpenAlert] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    const handleClose = () => {
        setOpen(false);
    }

    const refreshDelivery = (delivery: IDeliveryData) => {
        setDelivery(delivery);
        checkMultiStep(delivery);
    }

    const checkMultiStep = ( delivery: IDeliveryData) => {
        let isStep = false;
        delivery?.deliverySteps?.forEach(step => {
            if (step.signatureTo == null) isStep = true;
        });
        setMultiStep(isStep);
    }

    useEffect(() => {
        setActiveStep(0);
        handleHideElement(true);
        setErrors({ type: "info", content: t("Processing") });
        setOpenAlert(true);

        if (!id) return;

        const fetchData = async () => {
            try {
                const response = await deliveryService.getDelivery(id);
                const data = response.data;
                if (data.statuses[0].status.name !== "created") {
                    if (data.statuses[0].status.name === "delivering") setActiveStep(4);
                    if (data.statuses[0].status.name === "shipped") setActiveStep(6);
                    setDelivery(data);
                    checkMultiStep(data);
                    setTimeout(handleClose, 1500);
                }
            } catch (err: any) {
                if (
                    err?.statusCode === 403 ||
                    err?.statusCode === 401 ||
                    err?.statusCode === 400 ||
                    err?.response?.status === 403 ||
                    err?.response?.status === 401 ||
                    err?.response?.status === 400
                ) {
                    dispatch(logout());
                } else {
                    setErrors(err?.response?.data?.error);
                }
            } finally {
                setOpenAlert(false);
            }
        };
        fetchData();
    }, []);


    if (delivery != null) {
        if (activeStep < 4) {
            return (
                <StartDelivery
                    setOpen={setOpen}
                    open={open}
                    handleHideElement={handleHideElement}
                    delivery={delivery}
                    errors={errors} activeStep={activeStep}
                    openAlert={openAlert}
                    setOpenAlert={setOpenAlert}
                    setActiveStep={setActiveStep}
                    setErrors={setErrors}
                    handleClose={handleClose}
                />
            )
        } else {
            return (
                <>
                    {
                        multiStep ? (
                            <DeliveryStep
                                setOpen={setOpen}
                                open={open}
                                handleHideElement={handleHideElement}
                                delivery={delivery}
                                refreshDelivery={refreshDelivery}
                                errors={errors} activeStep={activeStep}
                                openAlert={openAlert}
                                setOpenAlert={setOpenAlert}
                                setActiveStep={setActiveStep}
                                setErrors={setErrors}
                                handleClose={handleClose}
                            />
                        ) : (
                            <EndDelivery
                                setOpen={setOpen}
                                open={open}
                                handleHideElement={handleHideElement}
                                delivery={delivery}
                                errors={errors} activeStep={activeStep}
                                openAlert={openAlert}
                                setOpenAlert={setOpenAlert}
                                setActiveStep={setActiveStep}
                                setErrors={setErrors}
                                handleClose={handleClose}
                />
                        )
                    }
                </>
            )
        }
    } else {
        return (
            <>
                <StatusPage>
                    <Box className="header" />
                    <Box className="content">
                        <Box className='info' sx={{ width: { xs: '100%', md: '70%' } }}>
                            <MyModalSubTitle>{t("deliveryNotFound")}</MyModalSubTitle>
                        </Box>
                    </Box>
                </StatusPage>
            </>
        )
    }
};

export default DeliveryStatus;

