import axios from "axios";

export const getLocalAccessToken = () => {
    return JSON.parse(localStorage.getItem('user') ||  "")?.token || null;
}

export const getLocalRefreshToken = () => {
    return JSON.parse(localStorage.getItem('user') || "")?.refreshToken || null;
}


const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json", },
});


instance.interceptors.request.use(
  (config : any) => {
    const token = getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;
    } else {
      window.location.replace("/login");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await refreshToken();
          const { data } = rs;
          window.localStorage.setItem("user", JSON.stringify(data));
          instance.defaults.headers.common["Authorization"] = 'Bearer ' + data?.token;
          return instance(originalConfig);
        } catch (_error :any) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }
          return Promise.reject(_error);
        }
      }
      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }
    return Promise.reject(err);
  }
);

export const refreshToken = () => {
    return instance.post("/auth/refreshtoken", { refreshToken: getLocalRefreshToken(), });
}

export default instance;
