import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { GridColDef } from '@mui/x-data-grid';
import carrierService from './service/CarrierService';
import { logout } from 'shared/redux/features/auth/authSlice';
import { ICarrierData } from './type/CarrierData';
import { EditCarrier } from './modals/EditCarrier';
import { AddCarrier } from './modals/AddCarrier';
import { IMessage } from 'pages/Clients/type/Message';
import { DeleteModals } from 'shared/modals/DeleteModals';
import { useTranslation } from "react-i18next";
import SearchCarrier from './SearchCarrier';
import MyDataGrid from 'MyComponents/DataGrid/MyDataGrid';

function Carrier(props: {
  indexNavBar: any,
  setIndexNavBar: any,
  selectedCarrier: any,
  setSelectedCarrier: any,
  open: any,
  setOpen: any,
  handleHideElement: any,
  setDeleteOpen: any,
  deleteOpen: boolean
}) {

  const {
    setIndexNavBar,
    selectedCarrier,
    setSelectedCarrier,
    open,
    setOpen,
    handleHideElement,
    setDeleteOpen,
    deleteOpen
  } = props;

  const dispatch = useDispatch();

  const [input, setInput] = useState("");
  const [addOpen, setAddOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState<IMessage>({ type: 'error', content: '' });
  const { t } = useTranslation();

  const [carriers, setCarriers] = useState<Array<ICarrierData>>();
  const [rowCountState, setRowCountState] = useState(0);
  const [selectionModel, setSelectionModel] = useState([]);
  const [defaultPageNo, setDefaultPageNo] = useState(0);
  const [defaultPageSize, setDefaultPageSize] = useState(40);
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 40,
  });

  const [errors, setErrors] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const isMobile = (window.innerWidth < 897) || false;

  const [expanded, setExpanded] = useState<string | false>(false);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      hideable: true,
      flex: 1,
      headerClassName: "researchColumn",
      headerAlign: "center",
      align: "center",
    },
    {
      field: 'user',
      headerName: t("lastName") || "",
      flex: 1,
      headerAlign: "center",
      headerClassName: "researchColumn",
      align: "center",
      renderCell: (data) => ((data?.row?.user?.firstname || "") + " " + data?.row?.user?.lastname || ""),
    },
    {
      field: 'useremail',
      headerName: 'Email',
      flex: 1,
      headerAlign: "center",
      headerClassName: "researchColumn",
      align: "center",
      renderCell: (data) => data?.row?.user?.email,
    },
    {
      field: 'phone',
      headerName: t('phone') || "",
      headerAlign: "center",
      type: 'number',
      headerClassName: "researchColumn",
      align: "center",
      flex: 1,
    },
  ];

  const handleChange = (event: any) => {
    setInput(event.target.value);
  }

  const resetInput = () => {
    setInput("");
  }

  const handleError = (err: any) => {
    setIsLoading(false)
    if (
      err?.statusCode === 403 ||
      err?.statusCode === 401 ||
      err?.statusCode === 400 ||
      err?.response?.status === 403 ||
      err?.response?.status === 401 ||
      err?.response?.status === 400
    ) {
      dispatch(
        logout()
      );
    } else {
      setErrors(err?.response?.data?.error)
    }
  }

  const handlePageChange = (page: any) => {
    setDefaultPageNo(page);
    setRowsState((prev) => ({ ...prev, page }));
    refresh({ pageNo: page, pageSize: defaultPageSize }).catch(err => handleError(err));
  }

  const handlePageSizeChange = (pageSize: any) => {
    setDefaultPageSize(pageSize);
    setRowsState((prev) => ({ ...prev, pageSize }));
    refresh({ pageNo: defaultPageNo, pageSize: pageSize }).catch(err => handleError(err));
  }

  const handleSelectionModelChange = (ids: any) => {
    let selectedIds = ids[ids.length - 1]
    setSelectionModel(selectedIds);
    carriers?.filter((carrier: any) => {
      if (selectedIds === carrier.id) {
        setSelectedCarrier(carrier)
      }
    });
  }

  const handleClose = () => {
    setMessage({ type: 'error', content: '' });
    setOpenAlert(false);
    setDeleteOpen(false);
  }

  const handleSubmit = () => {
    setMessage({ type: 'info', content: t("Processing") });
    setOpenAlert(true);
    if (selectedCarrier != null) {
      carrierService.deleteCarrier(selectedCarrier?.id)
        .then(r => {
          refresh().catch(err => handleError(err))
          setMessage({
            type: "success",
            content: t("carrier") + t("deleteWithSucess")
          });
          setTimeout(() => {
            handleClose()
          }, 1500)
        })
        .catch((err) => {
          if (
            err?.statusCode === 403 ||
            err?.statusCode === 401 ||
            err?.response?.status === 403 ||
            err?.response?.status === 401
          ) {
            dispatch(logout());
          } else {
            setMessage({
              type: 'error',
              content: err?.response?.data?.message
            })
          }
        }
        );
    }
  }

  const handleAddOpen = () => {
    setAddOpen(true);
  }

  const refresh = (
    { pageNo, pageSize } = {
      pageNo: rowsState.page,
      pageSize: rowsState.pageSize,
    }
  ) => {
    setIsLoading(true)
    return carrierService.getAllCarrier(pageNo, pageSize, input).then(
      (response: any) => {
        const data = response.data.content;
        setCarriers(
          data.map((row: ICarrierData) => {
            const { carrierId, ...rest } = row;
            return { id: carrierId, ...rest };
          })
        );
        setIsLoading(false)
        setRowCountState(response.data.totalElements);
      }
    ).catch((err) => handleError(err));
  };

  useEffect(() => {
    handleHideElement(false);
    setIndexNavBar(3); //set color of current page button on navbar
    setSelectedCarrier(null); //reset selected carrier
    refresh({
      pageNo: defaultPageNo,
      pageSize: defaultPageSize
    }).catch(err => handleError(err));
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(
      () => refresh({
        pageNo: defaultPageNo,
        pageSize: defaultPageSize
      }).catch(err => handleError(err)),
      300
    );
    return () => clearTimeout(timeoutId);
  }, [input])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 86.5px)', width: '100%' }}>
      <SearchCarrier
        input={input}
        expanded={expanded}
        isMobile={isMobile}
        resetInput={resetInput}
        setExpanded={setExpanded}
        handleChange={handleChange}
        handleAddOpen={handleAddOpen}
      />
      <AddCarrier
        open={addOpen}
        refresh={refresh}
        setOpen={setAddOpen}
      />
      <EditCarrier
        open={open}
        refresh={refresh}
        setOpen={setOpen}
        carrier={selectedCarrier}
      />
      <DeleteModals
        open={deleteOpen}
        setOpen={setDeleteOpen}
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
        message={message}
        setMessage={setMessage}
        refresh={refresh}
        title={t("ofCarrier")}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
      />
      <MyDataGrid
        errors={errors}
        columns={columns}
        rows={carriers}
        rowsState={rowsState}
        isLoading={isLoading}
        rowCountState={rowCountState}
        selectionModel={selectionModel}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        handleSelectionModelChange={handleSelectionModelChange}
      />
    </div>
  );
}

export default Carrier;