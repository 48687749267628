import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { SvgIconProps } from '@mui/material';
import Status, { StatusName } from 'MyComponents/type/StatusEnum';
import MyDefault from './MyDefault';

export default function MyChip(props: { label: string, index: number }) {

    const { label, index } = props;

    function defaultChip(option: StatusName, currentStatus: Status) {
        if (currentStatus == null) {
            return <></>
        }
        
        let chipRendering = {
            'created': <CustomChip
                status={Status.CREATED}
                label={label}
                Icon={AssignmentIcon}
                currentStatus={currentStatus}
            />,
            'pending': <CustomChip
                status={Status.PENDING}
                label={label}
                Icon={WorkHistoryIcon}
                currentStatus={currentStatus}
            />,
            'picking up': <CustomChip
                status={Status.PICKING_UP}
                label={label}
                Icon={HourglassTopIcon}
                currentStatus={currentStatus}
            />,
            'delivering': <CustomChip
                status={Status.DELIVERING}
                label={label}
                Icon={WorkHistoryIcon}
                currentStatus={currentStatus}
            />,
            'shipped': <CustomChip
                status={Status.SHIPPED}
                label={label}
                Icon={AssignmentTurnedInIcon}
                currentStatus={currentStatus}
            />,
            'default': <MyDefault />
        }

        return (chipRendering[option] || chipRendering['default'])
    }

    return (
        <>
            {(Object.keys(StatusName) as (keyof typeof StatusName)[]).map((key) => {
                return defaultChip(StatusName[key], Status.statusTab[index])
            })}
        </>
    )

}

export function CustomChip({ status, label, currentStatus, Icon }: {
    status: Status;
    label: string;
    currentStatus: Status;
    Icon: React.ComponentType<SvgIconProps>;
}) {
   
    return <Icon sx={{
        color: status.name === label ? status.color : '#e6e6e6',
        backgroundColor: status.index <= currentStatus.index ? currentStatus.background : '',
        fontFamily: 'Sansation, PinkBlue, Helvetica, sans-serif, -apple-system',
    }} />

}
