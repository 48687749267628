import { ChangeEventHandler, MouseEventHandler } from 'react';
import MyTitle from '../../MyComponents/Title/MyTitle';
import SearchIcon from "@mui/icons-material/Search";
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { MyAccordion } from 'MyComponents/Generic/MyAccordion';
import { MyAddButton } from 'MyComponents/Generic/MyButton';
import { MyTextField } from 'MyComponents/TextField/MyTextField';
import { useTranslation } from "react-i18next";

export default function SearchCarrier(props: {
    input: any,
    expanded: string | false,
    isMobile: boolean,
    setExpanded: Function,
    resetInput: MouseEventHandler<HTMLButtonElement>,
    handleChange: ChangeEventHandler<HTMLInputElement>,
    handleAddOpen: MouseEventHandler<HTMLButtonElement>
}) {
    const { t } = useTranslation();

    const {
        input,
        expanded,
        isMobile,
        resetInput,
        setExpanded,
        handleChange,
        handleAddOpen,
    } = props;
    return (
        <>
            <MyTitle>
                {t("navCarrier")}
            </MyTitle>
            {
                isMobile ?
                    (
                        <MyAccordion
                            id={t("expandToSeeMore") + t("3dot")}
                            summary={
                                <MyTextField type='text' value={input} name="research" label="" onChange={handleChange} placeholder={t("research") + t("3dot")}
                                    customStyle={{
                                        border: "3.5px solid #F5C065",
                                        borderRadius: "8px",
                                        height: '30px',
                                        width: '100%',
                                        fontSize: '16px',
                                    }}
                                />
                            }
                            description={t("expandToSeeMore") + t("3dot")}
                            content={
                                <>
                                    <MyAddButton onClick={handleAddOpen}>{t("add")}</MyAddButton>
                                    <Tooltip title={t("erase")} placement='top'>
                                        <IconButton onClick={resetInput}>
                                            <FilterAltOffIcon sx={{ color: '#F5C065', fontSize: '35px', }} />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            }
                            expanded={expanded}
                            setExpanded={setExpanded}
                        />
                    )
                    :
                    (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '100%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '70%' }}>
                                <SearchIcon sx={{ color: '#F5C065', fontSize: '40px' }} />
                                <input type='text' value={input} name="research" onChange={handleChange} placeholder={t("research") + t("3dot")}
                                    style={{
                                        border: "3.5px solid #F5C065",
                                        borderRadius: "8px",
                                        height: '30px',
                                        width: '100%',
                                        fontSize: '16px',
                                    }} />

                                <Tooltip title={t("erase")} placement='top'>
                                    <IconButton onClick={resetInput}>
                                        <FilterAltOffIcon sx={{ color: '#F5C065', fontSize: '35px', }} />
                                    </IconButton>
                                </Tooltip>
                                <MyAddButton onClick={handleAddOpen}>{t("add")}</MyAddButton>
                            </Box>
                        </Box>
                    )
            }
        </>
    )
}