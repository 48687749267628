import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export const MyAccordion = (props: { 
  id: string,
  summary: any, 
  description: string,
  content: any,
  expanded: string | false,
  setExpanded: any,
}) => {
  const {
    id,
    summary,
    description,
    content,
    expanded,
    setExpanded
  } = props;

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  };

  return (
    <Accordion expanded={expanded === id} onChange={handleChange(id)} sx={{width: '100%'}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panelbh-content"
        id={`panelbh-header-${id}`}
        sx={{backgroundColor: (expanded === id) ? '#D6EAF8' : '#fff'}}
      >
        <Typography sx={{ width: '50%', flexShrink: 0, textAlign: 'left', fontSize: 13, fontWeight: 700 }}>{ summary }</Typography>
        <Typography sx={{ color: 'text.secondary', textAlign: 'left', fontSize: 13, fontWeight: 700 }}>{ description }</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ textAlign: 'left'}}>{ content }</AccordionDetails>
    </Accordion>
  );
}