import instance from "shared/auth/service/AxiosInstance";

const API_URL: string = (process.env.REACT_APP_API_URL + "logs/log" );

const logService = {

    getAll: (pageNo: number, pageSize: number, research: any | null | undefined) =>
        instance.get(API_URL + "/?pageSize=" + pageSize + "&pageNo=" + pageNo + "&" + new URLSearchParams(research).toString()),

}

export default logService;