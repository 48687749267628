import Chip from "@material-ui/core/Chip";
import Status, { StatusName } from 'MyComponents/type/StatusEnum';
import MyDefault from 'MyComponents/Generic/MyDefault';


export default function MyStatus(props: { label: StatusName }) {
    const { label } = props;

    function defaultStatus(state: StatusName) {
        let statusRendering = {
            'created': <CustomStatus status={Status.CREATED} />,
            'pending': <CustomStatus status={Status.PENDING} />,
            'picking up': <CustomStatus status={Status.PICKING_UP} />,
            'delivering': <CustomStatus status={Status.DELIVERING} />,
            'shipped': <CustomStatus status={Status.SHIPPED} />,
            'default': <MyDefault />
        }

        return (statusRendering[state] || statusRendering['default'])
    }

    return defaultStatus(label);
}

export function CustomStatus(props: { status: Status }) {
    const { status } = props;
    return (
        <Chip
            key={status.name}
            label={status.name?.charAt(0).toUpperCase() + status.name?.slice(1)}
            variant="outlined"
            size="small"
            style={{
                border: '2px solid ' + status.color,
                backgroundColor: status.background,
                color: status.color,
                fontSize: '15px',
                padding: '5px',
                fontFamily: 'Sansation, PinkBlue, Helvetica, sans-serif, -apple-system',
            }}
        />
    )
}
