import Logo from '../../../assets/postexLogo.png';
import {
    Page,
    Text,
    View,
    Image,
    Document,
    StyleSheet,
    Font,
} from '@react-pdf/renderer';
import Roboto from '../../../assets/fonts/Roboto/Roboto-Regular.ttf';
import { DateYmdToDmY } from 'shared/utils/localeISODateTime';
import { useTranslation } from "react-i18next";
import { IInvoice } from '../type/invoice';
import { IDeliveryStep } from 'pages/Delivery/type/Delivery/DeliveryData';

import { ICustomHeader } from 'MyComponents/Pdf/PDFHeader';
import PDFHeader from 'MyComponents/Pdf/PDFHeader';
import PDFRow, { ICustomRow } from 'MyComponents/Pdf/PDFRow';
import SmallTemplate from './SmallTempalte';

Font.register({
    family: 'Roboto',
    format: 'truetype',
    src: Roboto
});


// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        fontFamily: 'Sansation, PinkBlue, Helvetica, sans-serif, -apple-system',
        fontSize: 14,
    },
    header: {
        margin: 50,
        display: 'flex',
        flexDirection: 'row',
        '& logo': {
            height: 100,
            width: 100
        },
        '& .title': {
            fontSize: '30px',
            color: 'black',
            flexGrow: 1,
            fontWeight: 900,
        },
        '& .society': {
            width: '50%'
        },
        '& .client': {
            flexGrow: 1,
        }
    },
    body: {
        marginVertical: 20,
        marginHorizontal: 50,
        flexGrow: 2,
    },
    footer: {
        marginVertical: 20,
        marginHorizontal: 50,
        height: 50,
        textAlign: 'center',
    },
    table: {
        width: 'auto',
        borderStyle: "solid",
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableHeader: {
        margin: "auto",
        flexDirection: "row",
        backgroundColor: '#f7A007',
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCell: {
        margin: 5,
        fontSize: 12
    }
});

const MultipleInvoiceTemplate = (props: {
    invoices: Array<IInvoice>
    email: String
}) => {

    const { invoices, email } = props;
    const { t } = useTranslation();

    const headers: Array<ICustomHeader> = [
        { name: "", percentageWidth: "15" },
        { name: t("address") || "", percentageWidth: "35" },
        { name: t("informations") || "", percentageWidth: "35" },
        { name: t("amount") + " (€)" || "", percentageWidth: "15" },
    ]

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* <View style={styles.header}>
                    <View style={styles.header['& .society']}>
                        <Image src={Logo} style={styles.header['& logo']} />
                        <Text>48 rue du Brill</Text>
                        <Text>L-4041 Esch-sur-Alzette</Text>
                        <Text>{t("phone")} : +352 691 500 006</Text>
                        <Text>{t("email")} : info@postex.lu</Text>
                    </View>

                    <View style={[styles.header['& .client'], { justifyContent: 'flex-end' }]}>
                        <View style={[styles.header['& .title'], { alignSelf: 'flex-end' }]}>
                            <Text>{t('invoice')}</Text>
                        </View>
                        <View>
                            <Text>{t("email")} : {email}</Text>
                        </View>
                    </View>
                </View> */}
                {invoices.map((invoice, index) => {
                    if (invoice?.items != null && 
                        invoice?.items[0] != null) {
                        return (
                            <View key={invoice?.id}>
                                <View break={index > 0} wrap={false}>
                                    <SmallTemplate
                                        invoice={invoice}
                                        styles={styles}
                                    />
                                </View>
                            </View>
                        )
                    } else {
                        return <></>
                    }
                }
                )}
            </Page>
        </Document>
    )
}

export default MultipleInvoiceTemplate;