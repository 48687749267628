import { useRef, useState } from 'react';
import { IMessage } from 'pages/Clients/type/Message';
import deliveryService from '../service/DeliveryService';
import { logout } from 'shared/redux/features/auth/authSlice';
import { IDeliveryData } from '../type/Delivery/DeliveryData';
import { MyTextField } from 'MyComponents/TextField/MyTextField';
import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
import Grid from '@mui/material/Grid';
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch } from 'react-redux';
import { MyContainedButton, MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import CanvasDraw from "react-canvas-draw";
import { IDeliveryCreator } from '../type/Delivery/DeliveryCreator';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from '@mui/material/Collapse';
import { Alert, Box } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { StatusPage } from './StatusPage';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useTranslation } from "react-i18next";

function StartDelivery(props: {
    setOpen: Function,
    open: boolean,
    handleHideElement: Function,
    delivery: IDeliveryData,
    errors: IMessage,
    activeStep: number,
    openAlert: boolean,
    setOpenAlert: Function,
    setActiveStep: Function,
    setErrors: Function,
    handleClose: Function
}) {

    const {
        open,
        setOpen,
        handleHideElement,
        delivery,
        errors,
        activeStep,
        openAlert,
        setOpenAlert,
        setActiveStep,
        setErrors,
        handleClose
    } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [signatureFrom, setSignatureFrom] = useState("");
    const [signatureFromRemark, setSignatureFromRemark] = useState("");
    const canvasRef = useRef<any>(null)

    const handleRemarkchange = (event: any) => {
        setSignatureFromRemark(event.target.value);
    }
     
    const handleAccept = () => {
        setOpenAlert(true);
        if (!delivery || !delivery.deliveryId) return;

        const deliveryCreator: IDeliveryCreator = { signatureFrom, signatureFromRemark };
        deliveryService
            .startDelivery(delivery.deliveryId, deliveryCreator)
            .then(r => {
                setErrors({ type: 'success', content: t("packagePickup") });
                setOpenAlert(false);
                setTimeout(handleClose, 1500);
                setActiveStep(3);
            })
            .catch(err => {
                if (err.statusCode === 403 || err.statusCode === 401 || err.statusCode === 400) {
                    dispatch(logout());
                } else {
                    setErrors(err?.response?.data?.error);
                }
            });
    };

    const handleChange = () => {
        const data = canvasRef?.current?.getSaveData();
        setSignatureFrom(data);
    }
    
    if (delivery != null) {
        if (activeStep === 0) {
            return (
                <>
                    <StatusPage>
                        <Box className="header" />
                        <Box className="content">
                            <Box className='info' sx={{ width: { xs: '100%', md: '60%' } }}>
                                <MyModalSubTitle>
                                    <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                                    {t("informations")}
                                </MyModalSubTitle>
                                <Grid container spacing={1} padding={3}>
                                    <Grid item xs={12}>
                                        <MyTextField disabled={true} type='text' name="clientId" label="Client" defaultValue={delivery?.client?.user?.firstname + " " + delivery?.client?.user?.lastname} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MyTextField disabled={true} type='text' name="type" label={t("typeOfDelivery")} defaultValue={delivery?.type?.name} />
                                    </Grid>
                                    {/* <Grid item xs={6}>
                                        <MyTextField disabled={true} type='text' name="remark" label={t("remark")} defaultValue={delivery?.setSignatureFromRemark} />
                                    </Grid> */}
                                </Grid>
                                <MyModalSubTitle>
                                    <LocalShippingIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                                    {t("journey")}
                                </MyModalSubTitle>
                                <Grid container spacing={1} padding={3}>
                                    <Grid item xs={12}>
                                        <MyTextField disabled={true} type='text' name="favAddressFromId" label={t("addressFrom")} defaultValue={
                                            delivery?.addressFrom?.address?.street + " " +
                                            delivery?.addressFrom?.address?.city + " " +
                                            delivery?.addressFrom?.address?.complement}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MyTextField disabled={true} type='text' name="favAddressToId" label={t("addressTo")} defaultValue={
                                            delivery?.addressTo?.address?.street + " " +
                                            delivery?.addressTo?.address?.city + " " +
                                            delivery?.addressTo?.address?.complement}
                                        />
                                    </Grid>
                                </Grid>
                                <div>
                                    <MyContainedButton onClick={() => setActiveStep(1)}>{t("next>")}</MyContainedButton>
                                </div>
                            </Box>
                        </Box>
                    </StatusPage>
                </>
            )
        } else if (activeStep === 1) {
            return (<>
                <StatusPage>
                    <Box className="header" />
                    <Box className="content">
                        <Box className='info' sx={{ width: { xs: '100%', md: '60%' } }}>
                            <Grid container spacing={1} padding={3}>
                                <Grid item xs={12}>
                                    <MyModalSubTitle>
                                        <LocalShippingIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                                        {t("command")} {delivery.trackingNumber}
                                    </MyModalSubTitle>
                                </Grid>
                                <Grid item xs={12}>
                                    <MyModalSubTitle>
                                        <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                                        {t("signatureFrom")}
                                    </MyModalSubTitle>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <CanvasDraw
                                            canvasWidth={300}
                                            canvasHeight={250}
                                            ref={canvasRef}
                                            loadTimeOffset={10}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <MyTextField type='text' name='remark' value={signatureFromRemark} label='remark' onChange={handleRemarkchange}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Collapse in={openAlert}>
                                        {(errors?.type) &&
                                            <Alert severity={'info'}
                                                action={
                                                    <IconButton
                                                        aria-label="close"
                                                        color="inherit"
                                                        size="small"
                                                        onClick={() => {
                                                            setOpenAlert(false);
                                                            setErrors({ type: 'error', content: '' });
                                                        }}
                                                    >
                                                        <CloseIcon fontSize="inherit" />
                                                    </IconButton>
                                                }
                                            >
                                                {errors?.content || ''}
                                            </Alert>
                                        }
                                    </Collapse>
                                </Grid>
                            </Grid>
                            <div>
                                <MyOutlinedButton onClick={() => setActiveStep(0)}>{t("<back")}</MyOutlinedButton>
                                <MyContainedButton onClick={handleAccept}>{t("validate")}</MyContainedButton>
                            </div>
                        </Box>
                    </Box>
                </StatusPage>
            </>)
        } else if (activeStep === 3) {
            return (
                <>
                    <StatusPage>
                        <Box className="header" />
                        <Box className="content">
                            <Box className='info' sx={{ width: { xs: '100%', md: '60%' } }}>
                                <MyModalSubTitle>
                                    {t("deliveryPickup")}
                                    <br />
                                </MyModalSubTitle>

                                <Grid container spacing={1} padding={5}>
                                    <Grid item xs={12}>
                                        <CheckCircleOutlineIcon sx={{ color: '#5fe95a', fontSize: '40px' }} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} padding={5}>
                                    <Grid item xs={12}>
                                        <MyTextField disabled={true} type='text' name="favAddressFromId" label={t("addressFrom")} defaultValue={
                                            delivery?.addressFrom?.address?.street + " " +
                                            delivery?.addressFrom?.address?.city + " " +
                                            delivery?.addressFrom?.address?.complement}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MyTextField disabled={true} type='text' name="favAddressToId" label={t("addressTo")} defaultValue={
                                            delivery?.addressTo?.address?.street + " " +
                                            delivery?.addressTo?.address?.city + " " +
                                            delivery?.addressTo?.address?.complement}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </StatusPage>
                </>
            )
        } else {
            return (
                <>
                    <StatusPage>
                        <Box className="header" />
                        <Box className="content">
                            <Box className='info' sx={{ width: { xs: '100%', md: '60%' } }}>
                                <MyModalSubTitle>{t("deliveryNotFound")}</MyModalSubTitle>
                            </Box>
                        </Box>
                    </StatusPage>
                </>
            )
        }
    } else {
        return (
            <>
                <StatusPage>
                    <Box className="header" />
                    <Box className="content">
                        <Box className='info' sx={{ width: { xs: '100%', md: '60%' } }}>
                            <MyModalSubTitle>{t("deliveryNotFound")}</MyModalSubTitle>
                        </Box>
                    </Box>
                </StatusPage>
            </>
        )
    }

};

export default StartDelivery;