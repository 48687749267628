import Autocomplete from '@mui/material/Autocomplete';
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

export const MyAutocomplete = (
  props: {
    // filterOptions: any,
    label: string,
    options: any[],
    value: any,
    inputValue: any,
    onChange: any,
    onInputChange: any,
    getOptionLabel: any,
    loading: boolean,
  }
) => {
  const {
    // filterOptions,
    label,
    options,
    value,
    inputValue,
    onChange,
    onInputChange,
    getOptionLabel,
    loading
  } = props;

  return (
    <Autocomplete
      noOptionsText="Aucun résultat"
      // filterOptions={filterOptions}
      disabled={loading}
      value={value}
      inputValue={inputValue}
      onChange={onChange}
      onInputChange={onInputChange}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          margin="dense"
           InputLabelProps={{
             shrink: inputValue != null ? true : false,
           }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};