import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import { MyContainedButton, MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import { MyTextField } from 'MyComponents/TextField/MyTextField';
import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
import { IFavoriteAddress } from 'pages/Addresses/type/FavoriteAddress';
import { IDeliveryCreator, IDeliveryStepCreator } from 'pages/Delivery/type/Delivery/DeliveryCreator';
import { MouseEventHandler, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import MyTitle from '../../../MyComponents/Title/MyTitle';
import { IOptions } from '../types/Options';

import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import FlagIcon from '@mui/icons-material/Flag';

import MyAutoCompleteDelivery from 'MyComponents/TextField/MyAutoCompleteDelivery';

function ShippingSteps(props: {
    myAdd: IFavoriteAddress | null,
    setMyAdd: Function,
    setMyOptionSelected: Function,
    setInputMyAdd: Function,
    inputMyAdd: unknown,
    inputs: IDeliveryCreator,
    setInputs: Function,
    myOptions: Array<IOptions>,
    destinationOptions: Array<IOptions>,
    handleSubmit: MouseEventHandler<HTMLButtonElement>,
    setActiveStep: Function,
    handleAddOpen: MouseEventHandler<HTMLButtonElement>,
    loading: boolean,
    researchValue: unknown,
    setResearchValue: Function,
    researchInput: unknown,
    setResearchInput: Function,
    deliverySteps: Array<IDeliveryStepCreator>,
    setDeliverySteps: Function,
}) {

    const {
        setMyOptionSelected,
        inputs,
        setInputs,
        myOptions,
        destinationOptions,
        handleSubmit,
        setActiveStep,
        handleAddOpen,
        loading,
        researchValue,
        setResearchValue,
        researchInput,
        setResearchInput,
        deliverySteps,
        setDeliverySteps,
    } = props;

    const { t } = useTranslation();
    const endRef = useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () => {
        if (endRef.current != null) {
            endRef.current.scrollIntoView({ behavior: "smooth" })
        }
    }

    useEffect(() => {
        scrollToBottom();
        setInputs({
            ...inputs,
            ["deliverySteps"]:deliverySteps
        })
    }, [deliverySteps])

    const handleRemove = (stepToRemove: number | null | undefined) => {
        let elementToRemove: IDeliveryStepCreator | null | undefined = deliverySteps.find(({ step }) => step === stepToRemove);

        if (elementToRemove != null) {
            var index: number = deliverySteps.indexOf(elementToRemove);
            var newDelivery: Array<IDeliveryStepCreator> = [];

            newDelivery.push(...deliverySteps);
            newDelivery.splice(index, 1);
            newDelivery.forEach((value, index) => {
                value.step = index
            })

            if (newDelivery != deliverySteps) {
                setDeliverySteps(newDelivery);
            }
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <MyTitle>
                {t("shippingSteps")}
            </MyTitle>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center', width: '90%', border: '3px solid #f5c065', borderRadius: '24px', padding: '2%' }}>
                <MyModalSubTitle>
                    <LocationOnIcon sx={{ color: '#009CFF', fontSize: '40px', marginTp: '30px' }} />
                    {t("journey")}
                </MyModalSubTitle>
                <Grid container spacing={1} columns={{ xs: 4, md: 12 }}>
                    <Grid item xs={1}>
                        <EmojiFlagsIcon sx={{ color: '#009CFF', fontSize: '40px', marginTop: '10px' }} />
                    </Grid>
                    <Grid item xs={8}>
                        <MyTextField disabled={true} type='text' name="favAddressFromId" value={(myOptions?.find(({ value }) => value === inputs.favAddressFromId))?.name} label={t("addressFrom")} />
                    </Grid>
                    <Grid item xs={3}>
                        <MyTextField disabled={true} type='text' name="clientId" value={(myOptions?.find(({ value }) => value === inputs.favAddressFromId))?.fullname} label={t("sender")} />
                    </Grid>
                    <MyAutoCompleteDelivery
                        inputs={inputs}
                        loading={loading}
                        setInputs={setInputs}
                        myOptions={myOptions}
                        researchValue={researchValue}
                        researchInput={researchInput}
                        deliverySteps={deliverySteps}
                        setResearchValue={setResearchValue}
                        setResearchInput={setResearchInput}
                        setDeliverySteps={setDeliverySteps}
                        setMyOptionSelected={setMyOptionSelected}
                    />
                    {/* <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={1}>
                                <PanoramaVerticalSelectIcon sx={{ color: '#009CFF', fontSize: '25px', marginTop: '30px' }} />
                            </Grid>
                            <Grid item xs={11}>
                                <MyAutocomplete
                                    label={t("deliveryStepAdd")}
                                    value={researchValue}
                                    inputValue={researchInput}
                                    options={myOptions}
                                    onChange={(event: any, newValue: any) => {
                                        setMyOptionSelected(myOptions?.find(({ name }) => name === newValue.name))
                                        setResearchValue(newValue);
                                        let newDeliverySteps: IDeliveryStepCreator = {
                                            favAddressId: newValue.value,
                                            remark: "",
                                            step: deliverySteps.length
                                        };
                                        setDeliverySteps([
                                            ...deliverySteps,
                                            newDeliverySteps
                                        ])
                                    }}
                                    onInputChange={(event: any, newInputValue: any) => setResearchInput(newInputValue)}
                                    getOptionLabel={(value: any) => (value?.name)
                                    }
                                    loading={loading}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <List
                                    sx={{
                                        overflow: 'auto',
                                        maxHeight: 150,
                                        '& ul': { padding: 0 },
                                    }}
                                    subheader={<li />}>
                                    {deliverySteps.map((deliveryStep: IDeliveryStepCreator) => {
                                        return (
                                            <li key={deliveryStep.step}>
                                                <ul>
                                                    <ListItemText >
                                                        <Grid container spacing={1} columns={{ xs: 7, md: 12 }}>
                                                            <Grid item xs={1}>
                                                                <Tooltip title={t("Steps") + " " + (deliveryStep?.step != null ? deliveryStep?.step + 1 : "")} placement='top'>
                                                                    <PanoramaVerticalSelectIcon sx={{ color: '#009CFF', fontSize: '25px', marginTop: '30px' }} />
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item xs={7}>
                                                                <MyTextField
                                                                    disabled={true}
                                                                    type='text'
                                                                    name="favAddressId"
                                                                    value={(myOptions?.find(({ value }) => value === deliveryStep.favAddressId))?.name}
                                                                    label={t("stepAddresses") + (deliveryStep?.step != null ? deliveryStep?.step + 1 : "")} />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <MyTextField
                                                                    disabled={true}
                                                                    type='text'
                                                                    name="clientId"
                                                                    value={(myOptions?.find(({ value }) => value === deliveryStep.favAddressId))?.fullname}
                                                                    label={t("receiverSTep") + (deliveryStep?.step != null ? deliveryStep?.step + 1 : "")} />
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                <Tooltip title={t('remove')} placement='top'>
                                                                    <IconButton onClick={() => handleRemove(deliveryStep.step)}>
                                                                        <RemoveCircleOutlineIcon sx={{ color: '#9c1111', fontSize: '30px', marginTop: '20px' }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </ListItemText>
                                                </ul>
                                            </li>
                                        )
                                    })}
                                    <div ref={endRef} />
                                </List>
                            </Grid>
                        </Grid>
                    </Grid> */}
                    <Grid item xs={1}>
                        <FlagIcon sx={{ color: '#009CFF', fontSize: '40px', marginTop: '10px' }} />
                    </Grid>
                    <Grid item xs={8}>
                        <MyTextField disabled={true} type='text' name="favAddressToId" value={(destinationOptions?.find(({ value }) => value === inputs.favAddressToId))?.name} label={t("addressReceiver")} />
                    </Grid>
                    <Grid item xs={3}>
                        <MyTextField disabled={true} type='text' name="clientId" value={(destinationOptions?.find(({ value }) => value === inputs.favAddressToId))?.fullname} label={t("receiver")} />
                    </Grid>
                </Grid>
            </div>
            <div>
                <MyOutlinedButton onClick={() => setActiveStep(1)}>{t("<back")}</MyOutlinedButton>
                <MyContainedButton onClick={() => setActiveStep(3)}>{t("next>")}</MyContainedButton>
            </div>
        </div>
    );

}
export default ShippingSteps;