import axios from "axios";
import { IAuthData } from "types/authdata";

export const AuthService = {

    signin: (authData: IAuthData) => axios.post(process.env.REACT_APP_API_URL + "auth/signin",
        authData
    ),

    logout: () => {
        localStorage.removeItem("user")
    }

}
