import { DataGrid, GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import MyLoadingDataGrid from './MyLoadingDataGrid';
import MyCustomNoRowsOverlay from './MyCustomNoRowsOverlay';

export default function MyDataGrid(props: {
    columns: GridColDef[],
    columnVisibilityModel?: GridColumnVisibilityModel,
    rows: any,
    selectionModel: any,
    handlePageChange: Function,
    handlePageSizeChange: Function,
    handleSelectionModelChange: Function,
    rowCountState: any,
    isLoading: boolean,
    setColumnVisibilityModel?: Function,
    rowsState: any,
    errors: any
}) {
    const {
        columns,
        columnVisibilityModel,
        rows,
        selectionModel,
        handlePageChange,
        handlePageSizeChange,
        handleSelectionModelChange,
        rowCountState,
        setColumnVisibilityModel,
        rowsState,
        isLoading,
        errors
    } = props

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            textAlign: 'center',
            marginTop: '2vh',
            '& .researchColumn': {
                backgroundColor: '#F5C065',
                fontFamily: 'Sansation, PinkBlue, Helvetica, sans-serif, -apple-system',
                fontSize: '20px',
                color: '#333333',
            },
        }}>
            {(rows != null) ? (
                <div style={{ width: '94%', height: '96%' }}>
                    <DataGrid
                        getRowHeight={() => 'auto'}
                        sx={{
                            borderRadius: '10px',
                            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: '#F5C065',
                                color: '#333333',
                            },
                            "& .MuiCheckbox-root svg": {
                                width: 16,
                                height: 16,
                                backgroundColor: "transparent",
                                border: '1px solid #F1731D',
                                borderRadius: 10
                            },
                            "& .MuiCheckbox-root svg path": {
                                display: "none"
                            },
                            "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
                                backgroundColor: "#F1731D",
                            },
                            '.MuiDataGrid-columnSeparator': {
                                display: 'none',
                            },
                            '&.MuiDataGrid-root': {
                                border: 'none',
                            },
                        }}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id: false,
                                },
                            },
                        }}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) => { if (setColumnVisibilityModel != null) setColumnVisibilityModel(newModel) }}
                        checkboxSelection
                        getRowId={(row: any) => row.id}
                        keepNonExistentRowsSelected
                        columns={columns}
                        rows={rows}
                        rowCount={rowCountState}
                        pagination
                        {...rowsState}
                        showCellRightBorder
                        paginationMode="server"
                        selectionModel={selectionModel}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        onSelectionModelChange={handleSelectionModelChange}
                        components={{
                            NoRowsOverlay: MyCustomNoRowsOverlay,
                            NoResultsOverlay: MyCustomNoRowsOverlay
                        }}
                        loading={isLoading}
                    />
                </div>)
                :
                (
                    (errors == null) &&
                    <div style={{ width: '94%', height: '96%' }}>
                        <MyLoadingDataGrid
                            columns={columns}
                            columnVisibilityModel={columnVisibilityModel}
                            setColumnVisibilityModel={setColumnVisibilityModel}
                            rowCountState={rowCountState}
                        />
                    </div>
                )
            }
        </Box>
    )
}