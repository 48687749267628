import LocationOnIcon from '@mui/icons-material/LocationOn';
import { MouseEventHandler } from 'react';
import { MyDrawerParagraph, MyDrawerRow } from '../../MyComponents/Generic/MyDrawer';
import Zoom from '@mui/material/Zoom';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { useTranslation } from "react-i18next";

function DraweraddressAddress(props: {
    address: any,
    open: boolean,
    handleOpen: MouseEventHandler<HTMLButtonElement>
}) {

    const { t } = useTranslation();

    const { address } = props

    return (
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
            <PersonIcon sx={{ fontSize: '120px', color: '#009CFF' }} />
            <p style={{ fontSize: '20px', marginTop: '0px' }}>
                {address != null && address != undefined &&
                    address?.fullname?.charAt(0)?.toUpperCase() + address?.fullname?.slice(1)
                }
            </p>
            <Zoom in={true} style={{ transitionDelay: '500ms' }}>
                <div style={{
                    borderRadius: '12px', backgroundColor: '#F2F2F2', display: 'flex', flexDirection: "column", width: '75%', padding: '4vh 3vh', justifyContent: 'space-between', textAlign: 'center', alignItems: 'center', boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)'
                }}>
                    <div>
                        {address != null && address != undefined &&
                            <>
                                <MyDrawerRow>
                                    <LocationOnIcon sx={{ marginRight: '2vh', color: '#009CFF', fontSize: '40px' }} />
                                    <MyDrawerParagraph>
                                        {
                                            address?.address?.country
                                        }
                                    </MyDrawerParagraph>
                                </MyDrawerRow>
                                {address?.phone != null &&
                                    <MyDrawerRow>
                                        <LocalPhoneIcon sx={{ marginRight: '2vh', color: '#009CFF', fontSize: '40px' }} />
                                        <MyDrawerParagraph>
                                            {address?.phone}
                                        </MyDrawerParagraph>
                                    </MyDrawerRow>
                                }
                            </>
                        }
                    </div>
                    {address != null && address != undefined ?
                        (
                            <>
                            </>
                        )
                        :
                        (
                            t("noClientSelected")
                        )

                    }
                </div>
            </Zoom>
        </div>
    )
}

export default DraweraddressAddress;