import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import { MyContainedButton, MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import { useState } from 'react';
import { useDispatch } from "react-redux";

import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
import { CustomModals } from "shared/modals/customModals";
import { logout } from 'shared/redux/features/auth/authSlice';
import addressService from '../service/AddressService';

import PersonIcon from '@mui/icons-material/Person';
import { MyTextField } from 'MyComponents/TextField/MyTextField';
import { IMessage } from 'pages/Clients/type/Message';
import { useTranslation } from "react-i18next";
import { IPayload } from 'types/payload';
import { IAddressCreator } from '../type/AddressCreator';
import { IFavoriteAddress } from '../type/FavoriteAddress';

export function AddAddress(props: { open: any, refresh: any, setOpen: any, payload: IPayload, type: string, ishome: boolean }) {

    const { t } = useTranslation();
    const { open, refresh, setOpen, payload, type, ishome } = props;
    const [inputs, setInputs] = useState<IFavoriteAddress>({});
    const [addressInputs, setAddressInputs] = useState<IAddressCreator>({});
    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState<IMessage>({ type: 'error', content: '' });

    const [isDisabled, setIsDisabled] = useState(true);
    const dispatch = useDispatch();

    const handleChange = (event: any) => {
        setInputs({
            ...inputs,
            [event.target.name]: event.target.value
        });

        if (inputs.fullname != null && addressInputs.city != null && addressInputs.street != null && addressInputs.country != null) {
            setIsDisabled(false);
        }
    }

    const handleAddressChange = (event: any) => {
        setAddressInputs({
            ...addressInputs,
            [event.target.name]: event.target.value
        })

        if (inputs.fullname != null && addressInputs.city != null && addressInputs.street != null && addressInputs.country != null) {
            setIsDisabled(false);
        }
    }

    const resetInput = () => {
        setInputs({})
        setAddressInputs({})
    }

    const handleClose = () => {
        setMessage({ type: 'error', content: '' });
        setOpenAlert(false);
        resetInput();
        setOpen(false);
    }

    const handleSubmit = () => {
        setMessage({ type: 'info', content: t("Processing") });
        setOpenAlert(true);
        if (inputs != null || addressInputs != null) {
            inputs.address = addressInputs;
            inputs.isHome = ishome;
            addressService.createAddress(payload?.me, inputs)
                .then(r => {
                    refresh()
                    setMessage({
                        type: "success",
                        content: type + t("createdWithSuccess")
                    });
                    setTimeout(() => {
                        handleClose()
                    }, 1500)
                })
                .catch((err) => {
                    if (
                        err?.statusCode === 403 ||
                        err?.statusCode === 401 ||
                        err?.response?.status === 403 ||
                        err?.response?.status === 401
                    ) {
                        dispatch(logout());
                    } else {
                        setMessage({
                            type: 'error',
                            content: err?.response?.data?.message
                        })
                    }
                });
        }
    }

    const content =
        <>
            <MyModalSubTitle>
                <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                {t("personnalInfo")}
            </MyModalSubTitle>
            <Grid container spacing={1} columns={{ xs: 4, md: 12 }} >
                <Grid item xs={4} >
                    <MyTextField type='text' name="fullname" label={t("nameOfPeople")} onChange={handleChange} />
                </Grid>
                < Grid item xs={4} >
                    <MyTextField type='text' name="label" label={t("company")} onChange={handleChange} />
                </Grid>
                <Grid item xs={4} >
                    <MyTextField type='tel' name="phone" pattern="[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}" label={t("phone")} placeholder={"xx xx xx xx xx"} onChange={handleChange} />
                </Grid>
            </Grid>
            <MyModalSubTitle >
                <LocationOnIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                {t("address")}
            </MyModalSubTitle>

            <Grid container spacing={1} columns={{ xs: 3, sm: 6, md: 12 }} >
                <Grid item xs={6} >
                    <MyTextField type='text' name="street" label={t("street")} onChange={handleAddressChange} />
                </Grid>
                < Grid item xs={6} >
                    <MyTextField type='text' name="city" label={t("city")} onChange={handleAddressChange} />
                </Grid>
                < Grid item xs={2} >
                    <MyTextField type='text' name="zipcode" label={t("zipcode")} onChange={handleAddressChange} />
                </Grid>
                < Grid item xs={3} >
                    <MyTextField type='text' name="country" label={t("country")} onChange={handleAddressChange} />
                </Grid>
                < Grid item xs={7} >
                    <MyTextField type='text' name="complement" label={t("complement")} onChange={handleAddressChange} />
                </Grid>
            </Grid>
        </>

    const actions =
        <>
            <MyOutlinedButton onClick={handleClose}> {t("cancel")} </MyOutlinedButton>
            <MyContainedButton disabled={isDisabled} onClick={() => handleSubmit()}> {t("validate")} </MyContainedButton>
        </>

    return (
        <CustomModals
            open={open}
            handleClose={handleClose}
            title={t("add") + type}
            content={content}
            actions={actions}
            openAlert={openAlert}
            setOpenAlert={setOpenAlert}
            message={message}
            setMessage={setMessage}
        />
    )
}