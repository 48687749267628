import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { FormControl, IconButton, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { MyAccordion } from 'MyComponents/Generic/MyAccordion';
import { ChangeEventHandler, MouseEventHandler } from 'react';
import { useTranslation } from "react-i18next";
import { MyOutlinedButton } from "../../MyComponents/Generic/MyButton";
import MyTitle from '../../MyComponents/Title/MyTitle';

export default function SearchInvoice(props: {
    isMobile: boolean,
    input: any,
    handleChange: ChangeEventHandler<HTMLInputElement>,
    // handleExport: MouseEventHandler<HTMLButtonElement>,
    canExport: boolean,
    resetInput: MouseEventHandler<HTMLButtonElement>,
    setExpanded: Function,
    expanded: string | false,
    roles: any,
    handleAddOpen: MouseEventHandler<HTMLButtonElement>
}) {

    const { t } = useTranslation();
    const {
        isMobile,
        input,
        handleChange,
        // handleExport,
        canExport,
        resetInput,
        setExpanded,
        expanded,
        roles,
        handleAddOpen
    } = props;

    const ROLE_FACTURATION = process.env.REACT_APP_ROLE_FACTURATION;

    return (
        <>
            <MyTitle>
                {t("navInvoice")}
            </MyTitle>

            {isMobile ?
                (
                    <Box sx={{ display: 'flex'}}>
                    <MyAccordion
                        id={t("expandToSeeMore") + t("3dot")}
                        summary={
                            <>
                                <FormControl margin='dense' sx={{ width: { xs: '50%', md: '100%' } }}>
                                    <TextField
                                        id="research"
                                        name="research"
                                        label={t("research") + t("3dot")}
                                        type="text"
                                        value={input?.research}
                                        onChange={handleChange}
                                        size='small'
                                        sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: "3.5px solid #F5C065" }, marginX: '5px' }}
                                        InputLabelProps={{ shrink: true, }}
                                    />
                                </FormControl>
                            </>
                        }
                        description={t("expandToSeeMore") + t("3dot")}
                        content={
                            <>
                                <FormControl margin='dense' sx={{ width: { xs: '50%', md: '100%' } }}>
                                    <TextField
                                        id="startDate"
                                        name="startDate"
                                        label={t("issueDate")}
                                        type="date"
                                        value={input?.startDate}
                                        onChange={handleChange}
                                        size='small'
                                        sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: "3.5px solid #F5C065" }, marginX: '5px' }}
                                        InputLabelProps={{ shrink: true, }}
                                    />
                                </FormControl>
                                <FormControl margin='dense' sx={{ width: { xs: '50%', md: '100%' } }}>
                                    <TextField
                                        id="endDate"
                                        name="endDate"
                                        label={t("dueDate")}
                                        type="date"
                                        value={input?.endDate}
                                        onChange={handleChange}
                                        size='small'
                                        sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: "3.5px solid #F5C065" }, marginX: '5px' }}
                                        InputLabelProps={{ shrink: true, }}
                                    />
                                </FormControl>
                                {/* <MyExportButton disabled={canExport} onClick={handleExport}>{t("export")}</MyExportButton> */}
                                <Tooltip title={t("erase")} placement='top'>
                                    <IconButton onClick={resetInput}>
                                        <FilterAltOffIcon sx={{ color: '#F5C065', fontSize: '35px', }} />
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                        expanded={expanded}
                        setExpanded={setExpanded}
                    />
                    {roles.includes(ROLE_FACTURATION!) &&
                        <MyOutlinedButton onClick={handleAddOpen}>{t('add')}</MyOutlinedButton>
                    }
                    </Box>
                )
                :
                (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '95%' }}>
                            <FormControl margin='dense' sx={{ width: { xs: '50%', md: '50%' } }}>
                                <TextField
                                    id="startDate"
                                    name="startDate"
                                    label={t("issueDate")}
                                    type="date"
                                    value={input?.startDate}
                                    onChange={handleChange}
                                    size='small'
                                    sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: "3.5px solid #F5C065" }, marginX: '5px' }}
                                    InputLabelProps={{ shrink: true, }}
                                />
                            </FormControl>
                            <FormControl margin='dense' sx={{ width: { xs: '50%', md: '50%' } }}>
                                <TextField
                                    id="endDate"
                                    name="endDate"
                                    label={t("dueDate")}
                                    type="date"
                                    value={input?.endDate}
                                    onChange={handleChange}
                                    size='small'
                                    sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: "3.5px solid #F5C065" }, marginX: '5px' }}
                                    InputLabelProps={{ shrink: true, }}
                                />
                            </FormControl>
                            <FormControl margin='dense' sx={{ width: { xs: '50%', md: '50%' } }}>
                                <TextField
                                    id="research"
                                    name="research"
                                    label={t("research") + t("3dot")}
                                    type="text"
                                    value={input?.research}
                                    onChange={handleChange}
                                    size='small'
                                    sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: "3.5px solid #F5C065" }, marginX: '5px' }}
                                    InputLabelProps={{ shrink: true, }}
                                />
                            </FormControl>
                            <Tooltip title={t("erase")} placement='top'>
                                <IconButton onClick={resetInput}>
                                    <FilterAltOffIcon sx={{ color: '#F5C065', fontSize: '35px', }} />
                                </IconButton>
                            </Tooltip>
                            {roles.includes(ROLE_FACTURATION!) &&
                                <MyOutlinedButton onClick={handleAddOpen}>{t('add')}</MyOutlinedButton>
                            }
                        </Box>
                    </Box>
                )
            }
        </>
    )
}