import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import CommentIcon from '@mui/icons-material/Comment';
import { useTranslation } from "react-i18next";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const LogValueModal = (props: {oldValue: any, newValue: any}) => { 
  const { t } = useTranslation();
  const { oldValue, newValue } = props;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [oldValues, setOldValues] = useState<any>(null);
  const [newValues, setNewValues] = useState<any>(null);

  function formatValue(value: string | undefined): Record<string, string> {
    if (!value) {
      return {};
    }

    const keyValuePairs = value.match(/(\w+)\s*=\s*([^,)]+)/g);

    if (!keyValuePairs) {
        return {};
    }

    const finalObj: Record<string, string> = {};
    keyValuePairs.forEach(keyValue => {
        const [key, value] = keyValue.split('=');
        finalObj[key.trim()] = value.trim();
    });

    return finalObj;
  }

  function keepChange(oldValue: any, newValue: any) : [Record<string, any>, Record<string, any>] {
    if (!oldValue || Object.keys(oldValue).length === 0) {
      return [{}, newValue];
    } else if (!newValue || Object.keys(newValue).length === 0) {
        return [oldValue, {}];
    } else {
      let finalOldValue: Record<string, any> = {};
      let finalNewValue: Record<string, any> = {};

      for (const key in oldValue) {
        if (oldValue.hasOwnProperty(key) && newValue.hasOwnProperty(key) && oldValue[key] !== newValue[key]) {
          finalOldValue[key] = oldValue[key];
          finalNewValue[key] = newValue[key];
        }
      }

      if ((Object.keys(finalOldValue).length === 0) && (Object.keys(finalNewValue).length === 0)) {
        return [oldValue, newValue];
      }

      return [finalOldValue, finalNewValue];
    }
  }

  useEffect(() => {
    let result = keepChange(formatValue(oldValue), formatValue(newValue));
    setOldValues(result[0]);
    setNewValues(result[1]);
  }, []);

  return (
    <div>
      <Tooltip title={t("seeMoreInformation")}>
        <div className="moreInfo">
          <button onClick={handleOpen}>
            <CommentIcon />
          </button>
        </div>
      </Tooltip>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[style, {width: {xs: '85%', md: '70%'}}]}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h1"
            textAlign={"center"}
            fontWeight={"bold"}
            fontFamily={'Sansation, PinkBlue, Helvetica, sans-serif, -apple-system'}
          >
          {t("values")}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div style={{display: 'flex'}}>
                <div style={{flex: 1}}>
                  <h5>{t("oldValues")}</h5>
                  {oldValues && Object.keys(oldValues).map(function (key, index) {
                    return (
                      <li key={"old-" + index}>
                        {key}: {oldValues[key]}
                      </li>
                    );
                  })}
                </div>
                <div style={{flex: 1}}>
                  <h5>{t("newValues")}</h5>
                  {newValues && Object.keys(newValues).map(function (key, index) {
                    return (
                      <li key={"new-" + index}>
                        {key}: {newValues[key]}
                      </li>
                    );
                  })}
                </div>
              </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

export default LogValueModal;