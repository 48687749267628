import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import FlagIcon from '@mui/icons-material/Flag';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Alert, Grid, Snackbar } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import PDFButton from 'MyComponents/Pdf/PDFButton';
import { IMessage } from 'pages/Clients/type/Message';
import { QRCodeCanvas } from 'qrcode.react';
import { MouseEventHandler, useState } from 'react';
import { useTranslation } from "react-i18next";
import { MyAddButton, MyEditButton } from '../../MyComponents/Generic/MyButton';

function DrawerDelivery(props: {
  delivery: any,
  open: boolean,
  handleOpen: MouseEventHandler<HTMLButtonElement>,
  handleAddOpen: MouseEventHandler<HTMLButtonElement>,
  roles: Array<string>,
  handleEditOpen?:MouseEventHandler<HTMLButtonElement>,
}) {

  const {
    delivery,
    handleOpen,
    handleAddOpen,
    roles,
    handleEditOpen
  } = props;

  const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
  const ROLE_ADMIN = process.env.REACT_APP_ROLE_ADMIN;
  const ROLE_CLIENT = process.env.REACT_APP_ROLE_CLIENT;

  const { t } = useTranslation();
  const [errors, setErrors] = useState<IMessage>({type:'info', content:'traitement en cours'});
  const [openSnack, setOpenSnack] = useState(false);

  const handleClose = () => {
      setOpenSnack(false)
  }

  if (delivery != null && delivery != undefined) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'left', width: '90%', alignItems: 'left', textAlign: 'left', margin: '1vh 2vh 1.7vh 2vh', fontSize: '13px' }}>
          <EmojiFlagsIcon sx={{ fontSize: '30px', color: '#009CFF', margin: '0vh 1vh 0vh 0vh' }} />
          {
            delivery?.addressFrom?.address?.street + " " +
            delivery?.addressFrom?.address?.city + " " +
            delivery?.addressFrom?.address?.complement
          }
        </div>
        <div style={{
          display: 'flex', flexDirection: "row", justifyContent: 'left', width: '90%', alignItems: 'left', textAlign: 'left', margin: '1.7vh 2vh 4vh 2vh', fontSize: '13px'
        }}>
          <FlagIcon sx={{ fontSize: '30px', color: '#009CFF', margin: '0vh 1vh 0vh 0vh' }} />
          {
            delivery?.addressTo?.address?.street + " " +
            delivery?.addressTo?.address?.city + " " +
            delivery?.addressTo?.address?.complement
          }
        </div>

        <div style={ { borderRadius: '12px', backgroundColor: '#F2F2F2', display: 'flex', flexDirection: "column", width: '75%', padding: '4vh 3vh', justifyContent: 'space-between', textAlign: 'center', alignItems: 'center', boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)' }}>

          <div>
            {delivery?.remark}
          </div>
          <div>
            {delivery?.carrier != null
              ?
              (
                delivery?.carrier?.user?.firstname + " " + delivery?.carrier?.user?.lastname
              )
              :
              (t("waiting"))
            }
          </div>

          {(delivery?.statuses[0]?.status?.name !== "shipped") &&
            <QRCodeCanvas
              id="qrcode"
              value={APP_DOMAIN + "/Delivery/start/" + (delivery?.deliveryId === null || delivery?.deliveryId === undefined ? delivery?.id : delivery?.deliveryId)}
              bgColor={'#f2f2f2'}
              fgColor={'#000'}
            />
          }

          {(delivery?.statuses[0]?.status?.name !== "shipped") && (roles.includes(ROLE_CLIENT!)) &&
            <PDFButton
              setErrors={setErrors}
              setOpenAlert={setOpenSnack}
              delivery={delivery}
            />
          }

          <Grid spacing={1}>
            <Grid item xs={12}>
                <MyEditButton onClick={handleOpen} disabled={delivery === null || delivery === undefined ? true : false}>{t("view")}</MyEditButton>
            </Grid>
            <Grid item xs={12}>
              {
                roles.includes(ROLE_ADMIN!) && delivery?.statuses[0]?.status?.name === "created"
                  ?
                  <MyAddButton onClick={handleAddOpen}>
                    {t("attribute")}
                  </MyAddButton> :
                  <></>
              }
            </Grid>
            <Grid item xs={12}>
              {
                roles.includes(ROLE_ADMIN!) && delivery?.statuses[0]?.status?.name !== "shipped"
                  ?
                  <MyEditButton onClick={handleEditOpen}>
                    {t("update")}
                  </MyEditButton> :
                  <></>
              }
            </Grid>
          </Grid>
          <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={errors.type || "info"} sx={{ width: '100%' }}>
              {errors?.content}
            </Alert>
          </Snackbar>
        </div>
      </div>
    )
  }
  else {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
        <LocalShippingIcon sx={{ fontSize: '120px', color: '#009CFF' }} />
        <Zoom in={true} style={{ transitionDelay: '500ms' }}>
          <div style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', display: 'flex', flexDirection: "column", width: '75%', padding: '4vh 3vh', justifyContent: 'space-between', textAlign: 'center', alignItems: 'center', boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)' }}>
            {t("noDeliverySelected")}
          </div>
        </Zoom>
      </div>
    )
  }
}

export default DrawerDelivery;