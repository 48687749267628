import CloseIcon from "@mui/icons-material/Close";
import { Alert, DialogContent, useTheme } from "@mui/material";
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from "@mui/material/IconButton";
import useMediaQuery from '@mui/material/useMediaQuery';
import MyTitle from 'MyComponents/Title/MyTitle';

export function CustomModals(props: {
    open: boolean,
    handleClose: any
    title: string,
    content: any,
    actions: any,
    openAlert: boolean,
    setOpenAlert: any,
    message: any,
    setMessage: any,
    notContent?:boolean
}
) {
    const {
        open,
        handleClose,
        title,
        content,
        actions,
        openAlert,
        setOpenAlert,
        message,
        setMessage,
        notContent
    } = props;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={fullScreen}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            maxWidth="lg"
            sx={{ overflowX: 'hidden' }}
        >
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: "fixed",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle>
                <MyTitle>{title}</MyTitle>
            </DialogTitle>
            {notContent ?<>{content}</> :<DialogContent>{content}</DialogContent>}
            <Collapse in={openAlert}>
                {(message?.type) &&
                    <Alert severity={message?.type}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpenAlert(false);
                                    setMessage({ type: 'error', content: '' });
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {message?.content || ''}
                    </Alert>
                }
            </Collapse>
            <DialogActions>
                {actions}
            </DialogActions>
        </Dialog>
    )
}