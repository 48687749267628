import PanoramaVerticalSelectIcon from '@mui/icons-material/PanoramaVerticalSelect';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Tooltip } from "@mui/material";
import Grid from '@mui/material/Grid';
import IconButton from "@mui/material/IconButton";
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { MyAutocomplete } from 'MyComponents/TextField/MyAutocomplete';
import { MyTextField } from 'MyComponents/TextField/MyTextField';
import { IDeliveryCreator, IDeliveryStepCreator } from 'pages/Delivery/type/Delivery/DeliveryCreator';
import { IOptions } from 'pages/Removale/types/Options';
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

function MyAutoCompleteDelivery(props: {
    setMyOptionSelected: Function,
    inputs: IDeliveryCreator,
    setInputs: Function,
    myOptions: Array<IOptions>,
    loading: boolean,
    researchValue: unknown,
    setResearchValue: Function,
    researchInput: unknown,
    setResearchInput: Function,
    deliverySteps: Array<IDeliveryStepCreator>,
    setDeliverySteps: Function,
}) {

    const {
        setMyOptionSelected,
        inputs,
        setInputs,
        myOptions,
        loading,
        researchValue,
        setResearchValue,
        researchInput,
        setResearchInput,
        deliverySteps,
        setDeliverySteps,
    } = props;

    const { t } = useTranslation();
    const endRef = useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () => {
        if (endRef.current != null) {
            endRef.current.scrollIntoView({ behavior: "smooth" })
        }
    }

    useEffect(() => {
        scrollToBottom();
        setInputs({
            ...inputs,
            ["deliverySteps"]: deliverySteps
        })
    }, [deliverySteps])

    const handleRemove = (stepToRemove: number | null | undefined) => {
        let elementToRemove: IDeliveryStepCreator | null | undefined = deliverySteps.find(({ step }) => step === stepToRemove);

        if (elementToRemove != null) {
            let index: number = deliverySteps.indexOf(elementToRemove);
            let newDelivery: Array<IDeliveryStepCreator> = [];

            newDelivery.push(...deliverySteps);
            newDelivery.splice(index, 1);
            newDelivery.forEach((value, index) => {
                value.step = index
            })

            if (newDelivery != deliverySteps) {
                setDeliverySteps(newDelivery);
            }
        }
    };

    return (
        <Grid item xs={12}>
            <Grid container spacing={1}>
                <Grid item xs={1}>
                    <PanoramaVerticalSelectIcon sx={{ color: '#009CFF', fontSize: '25px', marginTop: '30px' }} />
                </Grid>
                <Grid item xs={11}>
                    <MyAutocomplete
                        label={t("deliveryStepAdd")}
                        value={researchValue}
                        inputValue={researchInput}
                        options={myOptions}
                        onChange={(event: any, newValue: any) => {
                            setMyOptionSelected(myOptions?.find(({ name }) => name === newValue.name))
                            setResearchValue(newValue);
                            let newDeliverySteps: IDeliveryStepCreator = {
                                favAddressId: newValue.value,
                                remark: "",
                                step: deliverySteps.length
                            };
                            setDeliverySteps([
                                ...deliverySteps,
                                newDeliverySteps
                            ])
                        }}
                        onInputChange={(event: any, newInputValue: any) => setResearchInput(newInputValue)}
                        getOptionLabel={(value: any) => (value?.name)
                        }
                        loading={loading}
                    />
                </Grid>
                <Grid item xs={12}>
                    <List
                        sx={{
                            overflow: 'auto',
                            maxHeight: 150,
                            '& ul': { padding: 0 },
                        }}
                        subheader={<li />}>
                        {deliverySteps.map((deliveryStep: IDeliveryStepCreator) => {
                            return (
                                <li key={deliveryStep.step}>
                                    <ul>
                                        <ListItemText >
                                            <Grid container spacing={1} columns={{ xs: 7, md: 12 }}>
                                                <Grid item xs={1}>
                                                    <Tooltip title={t("Steps") + " " + (deliveryStep?.step != null ? deliveryStep?.step + 1 : "")} placement='top'>
                                                        <PanoramaVerticalSelectIcon sx={{ color: '#009CFF', fontSize: '25px', marginTop: '30px' }} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <MyTextField
                                                        disabled={true}
                                                        type='text'
                                                        name="favAddressId"
                                                        value={(myOptions?.find(({ value }) => value === deliveryStep.favAddressId))?.name}
                                                        label={t("stepAddresses") + (deliveryStep?.step != null ? deliveryStep?.step + 1 : "")} />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <MyTextField
                                                        disabled={true}
                                                        type='text'
                                                        name="clientId"
                                                        value={(myOptions?.find(({ value }) => value === deliveryStep.favAddressId))?.fullname}
                                                        label={t("receiverSTep") + (deliveryStep?.step != null ? deliveryStep?.step + 1 : "")} />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Tooltip title={t('remove')} placement='top'>
                                                        <IconButton onClick={() => handleRemove(deliveryStep.step)}>
                                                            <RemoveCircleOutlineIcon sx={{ color: '#9c1111', fontSize: '30px', marginTop: '20px' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </ListItemText>
                                    </ul>
                                </li>
                            )
                        })}
                        <div ref={endRef} />
                    </List>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default MyAutoCompleteDelivery;