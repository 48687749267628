import { styled } from '@mui/material/styles';

export const MyDrawerParagraph = styled('p')(() => ({
    textOverflow: 'ellipsis',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '150px',
}));

export const MyDrawerRow = styled('div')(() => ({
    justifyContent: 'left',
    flexDirection: "row",
    alignItems: 'center',
    textAlign: 'left',
    display: 'flex',
}));

export default MyDrawerParagraph;