import { MyOutlinedButton } from "MyComponents/Generic/MyButton";
import { IMessage } from "pages/Clients/type/Message";
import { useRef, useState } from "react";
import CanvasDraw from "react-canvas-draw";
import { useTranslation } from "react-i18next";
import { CustomModals } from "shared/modals/customModals";
import { IDeliveryStep } from "../type/Delivery/DeliveryData";

function DeliveryStepSignature(props: {
    deliveryStep: IDeliveryStep,
    open: boolean,
    setOpen: Function,
}) {

    const {
        deliveryStep,
        open,
        setOpen
    } = props;

    const { t } = useTranslation();

    const canvasRef = useRef<any>(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState<IMessage>({ type: 'error', content: '' });


    const handleClose = () => {
        setOpen(false);
    }

    if (deliveryStep?.signatureTo != null) {
        canvasRef?.current?.loadSaveData(deliveryStep.signatureTo, true);
    }

    const content = <>
        {deliveryStep?.signatureTo != null ?
            (
                <CanvasDraw
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    canvasWidth={250}
                    canvasHeight={250}
                    disabled
                    ref={canvasRef}
                    immediateLoading={true}
                    saveData={deliveryStep.signatureTo}
                />
            ) :
            (
                <div style={{ textAlign: 'center' }}>
                    No signature yet
                </div>
            )
        }
    </>

    const actions = <MyOutlinedButton onClick={handleClose}>{t("close")}</MyOutlinedButton>

    return (
        <CustomModals
            open={open}
            handleClose={handleClose}
            title={"Step N°" + (deliveryStep?.step != null ? deliveryStep?.step + 1 : "")}
            content={content}
            actions={actions}
            openAlert={openAlert}
            setOpenAlert={setOpenAlert}
            message={message}
            setMessage={setMessage}
        />
    )
}

export default DeliveryStepSignature;
