import AddLocationIcon from '@mui/icons-material/AddLocation';
import FlagIcon from '@mui/icons-material/Flag';
import InfoIcon from '@mui/icons-material/Info';
import PersonIcon from '@mui/icons-material/Person';
import { Tooltip } from "@mui/material";
import Grid from '@mui/material/Grid';
import IconButton from "@mui/material/IconButton";
import { MyContainedButton, MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import { MyAutocomplete } from 'MyComponents/TextField/MyAutocomplete';
import { MyTextField, MyTextFieldSelect } from 'MyComponents/TextField/MyTextField';
import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
import { AddAddress } from 'pages/Addresses/modals/AddAddress';
import { IFavoriteAddress } from 'pages/Addresses/type/FavoriteAddress';
import deliveryTypeService from 'pages/Delivery/service/DeliveryTypeService';
import { IDeliveryCreator } from 'pages/Delivery/type/Delivery/DeliveryCreator';
import { IType } from 'pages/Delivery/type/Type';
import { ChangeEventHandler, MouseEventHandler, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { IPayload } from 'types/payload';
import MyTitle from '../../../MyComponents/Title/MyTitle';
import { IOptions } from '../types/Options';

function ShippingTo(props: {
    payload: IPayload,
    inputs: IDeliveryCreator,
    allAdd: IFavoriteAddress | null,
    destinationOptions: Array<IOptions>,
    destinationOptionSelected: any,
    inputAdd: unknown,
    addOpen: boolean,
    loading: boolean,
    setInputs: Function,
    setAllAdd: Function,
    setAddOpen: Function,
    setInputAdd: Function,
    fetchAllAdd: Function,
    setActiveStep: Function,
    setDestinationOptionSelected: Function,
    handleChangeDelivery: ChangeEventHandler<Element>,
    handleAddOpen: MouseEventHandler<HTMLButtonElement>,
}) {
    const {
        inputs,
        setInputs,
        allAdd,
        inputAdd,
        handleAddOpen,
        addOpen,
        setAddOpen,
        payload,
        setActiveStep,
        loading,
        handleChangeDelivery,
        destinationOptions,
        destinationOptionSelected,
        setDestinationOptionSelected,
        setAllAdd,
        setInputAdd,
        fetchAllAdd
    } = props;

    const [typeOptions, setTypeOptions] = useState<IType>();

    useEffect(() => {
        deliveryTypeService.getDeliveryType(0, 10)
      .then(r => {
        setTypeOptions(r.data.content)
      });
    }, [])
    
    const { t } = useTranslation();

    // const types = [
    //     {
    //         value: 'économique', name: t('eco')
    //     },
    //     {
    //         value: 'normal', name: t('normal')
    //     },
    //     {
    //         value: 'rapide', name: t('fast')
    //     },
    // ]

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <MyTitle>
                {t("endShippingTitle")}
            </MyTitle>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center', width: '90%', border: '3px solid #f5c065', borderRadius: '24px', padding: '3%' }}>
                <MyModalSubTitle>
                    <FlagIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                    {t("End")}
                </MyModalSubTitle>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={10}>
                                <MyAutocomplete
                                    // filterOptions={}
                                    label={t("researchA") + t("addresse") + t("3dot")}
                                    value={allAdd}
                                    inputValue={inputAdd}
                                    options={destinationOptions}
                                    onChange={(event: any, newValue: any) => {
                                        setDestinationOptionSelected(destinationOptions?.find(({ name }) => name === newValue.name))
                                        setAllAdd(newValue);
                                        setInputs({
                                            ...inputs,
                                            ["favAddressToId"]: newValue.value
                                        })
                                    }}
                                    onInputChange={(event: any, newInputValue: any) =>
                                        setInputAdd(newInputValue)
                                    }
                                    getOptionLabel={(value: any) => (value?.name)
                                    }
                                    loading={loading}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Tooltip title={t("addAnAddress")} placement='top'>
                                    <IconButton onClick={handleAddOpen}>
                                        <AddLocationIcon sx={{ color: '#F5C065', fontSize: '35px', }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <AddAddress
                    ishome={false}
                    type={t("anAddress")}
                    open={addOpen}
                    refresh={fetchAllAdd}
                    setOpen={setAddOpen}
                    payload={payload}
                />
                <MyModalSubTitle>
                    <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                    {t("receiver")}
                </MyModalSubTitle>
                <Grid container spacing={1} columns={{ xs: 4, md: 12 }}>
                    <Grid item xs={6}>
                        <MyTextField type='text' disabled={true} name='b' label={t("lastName")} value={destinationOptionSelected?.fullname != null ? destinationOptionSelected?.fullname : ""} defaultValue={""} />
                    </Grid>
                    <Grid item xs={6}>
                        <MyTextField type='tel' disabled={true} name="c" pattern="[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}" label={t("phone")} value={destinationOptionSelected?.phone != null ? destinationOptionSelected?.phone : ""} placeholder={"xx xx xx xx xx"} defaultValue={""} />
                    </Grid>
                </Grid>
                <MyModalSubTitle>
                    <InfoIcon sx={{ color: '#009CFF', fontSize: '30px' }} />
                    {t("deliveryInformation")}
                </MyModalSubTitle>
                <Grid container spacing={1} columns={{ xs: 3, md: 12 }}>
                    <Grid item xs={4}>
                        <MyTextField type='date' name="datetime" label="Date" defaultValue={inputs?.datetime} onChange={handleChangeDelivery} />
                    </Grid>
                    {typeOptions != null &&
                        <Grid item xs={3}>
                            <MyTextFieldSelect name="type" value={inputs.type} options={typeOptions} label="Type" onChange={handleChangeDelivery} />
                        </Grid>
                    }
                    <Grid item xs={5}>
                        <MyTextField type='text' name="remark" label={t("remark")} onChange={handleChangeDelivery} />
                    </Grid>
                </Grid>
            </div>
            <div>
                <MyOutlinedButton onClick={() => setActiveStep(0)}>{t("<back")}</MyOutlinedButton>
                <MyContainedButton disabled={(destinationOptionSelected != null && inputs.type != null && inputs.datetime != null ? false : true)} onClick={() => setActiveStep(2)}>{t("next>")}</MyContainedButton>
            </div>
        </div>
    )
};
export default ShippingTo;
