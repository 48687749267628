import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import Grid from '@mui/material/Grid';
import { MyContainedButton, MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import { MyTextField } from 'MyComponents/TextField/MyTextField';
import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CustomModals } from "shared/modals/customModals";
import { logout } from 'shared/redux/features/auth/authSlice';
import clientService from '../service/ClientService';
import { IClientAddressUpdate, IClientUpdate, IClientUserUpdate, IFavoriteAddressUpdate } from "../type/Client/ClientUpdate";

export function EditClient(props: { open: any, refresh: any, setOpen: any, client: any }) {

    const { open, refresh, setOpen, client } = props;
    const { t } = useTranslation();

    const [favAddressInputs, setFavAddressInputs] = useState<IFavoriteAddressUpdate>({});
    const [addressInputs, setAddressInputs] = useState<IClientAddressUpdate>({});
    const [userInputs, setUserInputs] = useState<IClientUserUpdate>({});
    const [inputs, setInputs] = useState<IClientUpdate>({});

    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState({ type: 'error', content: "" });

    const [isDisabled, setIsDisabled] = useState(false);
    const dispatch = useDispatch();

    const handleChangeClient = (event: any) => {
        setInputs({
            ...inputs,
            [event.target.name]: event.target.value
        });
    }

    const handleChangeFavAddress = (event: any) => {
        setFavAddressInputs({
            ...favAddressInputs,
            [event.target.name]: event.target.value
        })
    }

    const handleChangeAddress = (event: any) => {
        setAddressInputs({
            ...addressInputs,
            [event.target.name]: event.target.value
        });
    }

    const handleChangeUser = (event: any) => {
        setUserInputs({
            ...userInputs,
            [event.target.name]: event.target.value
        });
    }

    const resetInput = () => {
        setFavAddressInputs({});
        setAddressInputs({});
        setUserInputs({});
        setInputs({});
    }

    const handleClose = () => {
        setMessage({ type: 'error', content: "" });
        setOpenAlert(false);
        resetInput();
        setOpen(false);
    }

    const handleSubmit = (id: string) => {
        setMessage({ type: 'info', content: t("Processing") });
        setOpenAlert(true);
        if (inputs != null || addressInputs != null || userInputs != null) {
            favAddressInputs.address = addressInputs;
            favAddressInputs.favoriteAddressId = client?.address[0]?.favoriteAddressId
            inputs.address = favAddressInputs;
            inputs.user = userInputs;
            clientService.updateClient(id, inputs)
                .then(r => {
                    refresh()
                    setMessage({
                        type: "success",
                        content: t("client") + t("updatedWithSuccess")
                    });
                    setTimeout(() => {
                        handleClose()
                    }, 1500)
                })
                .catch((err) => {
                    if (
                        err?.statusCode === 403 ||
                        err?.statusCode === 401 ||
                        err?.response?.status === 403 ||
                        err?.response?.status === 401
                    ) {
                        dispatch(logout());
                    } else {
                        setMessage({
                            type: 'error',
                            content: err?.response?.data?.message
                        })
                    }
                }
                );
        }
    }

    const content =
        <>
            <MyModalSubTitle>
                <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                {t("personnalInfo")}
            </MyModalSubTitle>
            <Grid container spacing={1} columns={{ xs: 5, md: 12 }} >
                <Grid item xs={4}>
                    <MyTextField type='text' name="firstname" label={t("firstName")} defaultValue={client?.user?.firstname} onChange={handleChangeUser} />
                </Grid>
                <Grid item xs={4}>
                    <MyTextField type='text' name="lastname" label={t("lastName")} defaultValue={client?.user?.lastname} onChange={handleChangeUser} />
                </Grid>
                <Grid item xs={4}>
                    <MyTextField type='text' name="company" label={t("company")} defaultValue={client?.company} onChange={handleChangeClient} />
                </Grid>

                <Grid item xs={6}>
                    <MyTextField type='tel' name="phone" pattern="[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}" label={t("phone")} placeholder={"xx xx xx xx xx"} defaultValue={client?.phone} onChange={handleChangeClient} />
                </Grid>
                <Grid item xs={6}>
                    <MyTextField type='text' name="email" label='Email' defaultValue={client?.email} onChange={handleChangeClient} />
                </Grid>
                <Grid item xs={6}>
                    <MyTextField type='text' name="emailFacturation" label={t("emailFacturation")} defaultValue={client?.emailFacturation} onChange={handleChangeClient} />
                </Grid>
                <Grid item xs={6}>
                    <MyTextField type='text' name="vatNumber" label={t("tva")} size={{ width: '27.3vh' }} defaultValue={client?.vatNumber} onChange={handleChangeClient} />
                </Grid>
            </Grid>
            <MyModalSubTitle>
                <LocationOnIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                {t("address")}
            </MyModalSubTitle>

            <Grid container spacing={1} columns={{ xs: 3, md: 12 }} >
                <Grid item xs={6}>
                    <MyTextField type='text' name="street" label={t("street")} size={{ width: '46vh' }} defaultValue={client?.address[0]?.address?.street} onChange={handleChangeAddress} />
                </Grid>
                <Grid item xs={3}>
                    <MyTextField type='text' name="city" label={t("city")} size={{ width: '46vh' }} defaultValue={client?.address[0]?.address?.city} onChange={handleChangeAddress} />
                </Grid>
                <Grid item xs={3}>
                    <MyTextField type='text' name="zipcode" label={t("zipcode")} size={{ width: '46vh' }} defaultValue={client?.address[0]?.address?.zipcode} onChange={handleChangeAddress} />
                </Grid>
                <Grid item xs={8}>
                    <MyTextField type='text' name="complement" label={t("complement")} size={{ width: '46vh' }} defaultValue={client?.address[0]?.address?.complement} onChange={handleChangeAddress} />
                </Grid>
                <Grid item xs={4}>
                    <MyTextField type='text' name="country" label={t("country")} size={{ width: '46vh' }} defaultValue={client?.address[0]?.address?.country} onChange={handleChangeAddress} />
                </Grid>
                <Grid item xs={12}>
                    <MyTextField type='text' name="label" label={t("label")} size={{ width: '46vh' }} defaultValue={client?.address[0]?.label} onChange={handleChangeFavAddress} />
                </Grid>
            </Grid>
        </>

    const actions =
        <>
            <MyOutlinedButton onClick={handleClose}>{t("cancel")}</MyOutlinedButton>
            <MyContainedButton disabled={isDisabled} onClick={() => handleSubmit(client.id)}>{t("validate")}</MyContainedButton>
        </>

    if (client != null && client != undefined) {
        return (
            <CustomModals
                open={open}
                handleClose={handleClose}
                title={t("updateClient")}
                content={content}
                actions={actions}
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
                message={message}
                setMessage={setMessage}
            />
        )
    } else {
        return (
            <></>
        )
    }
}
