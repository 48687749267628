import instance from "shared/auth/service/AxiosInstance";
import { ICarrierCreator } from "../type/CarrierCreator";

const API_URL = process.env.REACT_APP_API_URL + "activities/carrier";

const carrierService = {

    getAllCarrier: (pageNo: number, pageSize: number, research: string) =>
        instance.get(API_URL + "/research/?pageSize=" + pageSize + "&pageNo=" + pageNo + "&research=" + research),

    createCarrier: (carrier: ICarrierCreator) =>
        instance.post(API_URL + "/", carrier),

    updateCarrier: (id: string, carrier: ICarrierCreator) =>
        instance.put(API_URL + "/" + id, carrier),

    deleteCarrier: (id: string) =>
        instance.delete(API_URL + "/" + id),
}

export default carrierService;