import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { MyEditButton, MyDeleteButton } from '../../MyComponents/Generic/MyButton';
import { MyDrawerParagraph, MyDrawerRow } from '../../MyComponents/Generic/MyDrawer';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import Zoom from '@mui/material/Zoom';
import { useTranslation } from "react-i18next";

function DrawerCarriers(props: { carrier: any, open: any, handleOpen: any, handleDeleteOpen: any }) {

  const { t } = useTranslation();
  const { carrier, handleOpen, handleDeleteOpen } = props;

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
        <TransferWithinAStationIcon sx={{ fontSize: '120px', color: '#009CFF' }} />
        <p style={{ fontSize: '20px', marginTop: '0px' }}>
          {carrier != null && carrier != undefined &&
            carrier?.user?.firstname?.charAt(0)?.toUpperCase() + carrier?.user?.firstname?.slice(1) + " " + carrier?.user?.lastname
          }
        </p>
        <Zoom in={true} style={{ transitionDelay: '500ms' }}>
          <div style={{
            borderRadius: '12px', backgroundColor: '#F2F2F2', display: 'flex', flexDirection: "column", width: '75%', padding: '4vh 3vh', justifyContent: 'space-between', textAlign: 'center', alignItems: 'center', boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)'
          }}>
            <div>
              {carrier != null && carrier != undefined &&
                <>
                  <MyDrawerRow>
                    <LocalPhoneIcon sx={{ marginRight: '2vh', color: '#009CFF', fontSize: '40px' }} />
                    <MyDrawerParagraph>
                      {carrier?.phone}
                    </MyDrawerParagraph>
                  </MyDrawerRow>
                  <MyDrawerRow>
                    <EmailOutlinedIcon sx={{ marginRight: '2vh', color: '#009CFF', fontSize: '40px' }} />
                    <MyDrawerParagraph>
                      {carrier?.user?.email}
                    </MyDrawerParagraph>
                  </MyDrawerRow>
                </>
              }
            </div>
            {carrier != null && carrier != undefined ?
              (
                <>
                  <MyEditButton onClick={handleOpen} disabled={carrier === null || carrier === undefined ? true : false}>{t("update")}</MyEditButton>
                  <MyDeleteButton onClick={handleDeleteOpen} disabled={carrier === null || carrier === undefined ? true : false}>{t("delete")}</MyDeleteButton>
                </>
              )
              :
              (
                t("noCarrierSelected")
              )
            }
          </div>
        </Zoom>
      </div>
    </>
  );
}

export default DrawerCarriers;

