import DescriptionIcon from '@mui/icons-material/Description';
import Zoom from '@mui/material/Zoom';
import { useTranslation } from "react-i18next";

function DrawerInvoice(props: { invoice: any, handleOpen: any, roles: Array<String> }) {

  const { invoice, handleOpen, roles } = props;
  const { t } = useTranslation();

  const ROLE_FACTURATION = process.env.REACT_APP_ROLE_FACTURATION;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
      {!invoice &&
        <DescriptionIcon sx={{ fontSize: '120px', color: '#009CFF' }} />
      }
      <Zoom in={true} style={{ transitionDelay: '500ms' }}>

        <div style={{
          borderRadius: '12px', backgroundColor: '#F2F2F2', display: 'flex', flexDirection: "column", width: '75%', padding: '4vh 3vh', justifyContent: 'space-between', textAlign: 'center', alignItems: 'center', boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)'
        }}>
          {(invoice &&
            <>
              <DescriptionIcon sx={{ fontSize: '40px', color: '#009CFF', marginRight: 1 }} />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>Invoice number : {invoice.id}</span>
              </div>
              <div style={{ display: 'flex' }}>
                {invoice.fullname}
              </div>
              <div style={{ display: 'flex' }}>
                {invoice.email}
              </div>
              {/* {roles.includes(ROLE_FACTURATION!) &&
                <MyEditButton
                  disabled={invoice === null || invoice === undefined ? true : false}
                  onClick={handleOpen}
                >
                  {t("update")}
                </MyEditButton>
              } */}
            </>
          ) || (
              t("noInvoiceSelected")
            )}
        </div>
      </Zoom>
    </div>
  );
}

export default DrawerInvoice;