import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { AuthService } from "shared/auth/service/AuthService";
import { IAuthData } from "types/authdata";

const user: JSON = JSON.parse(localStorage.getItem("user") || '{}');

export const login: any = createAsyncThunk(
    "auth/signin",
    async (authData: IAuthData, thunkAPI) => {
        try {
            const response = await AuthService.signin(authData);
            localStorage.setItem("user", JSON.stringify(response.data));
            
            const payload: any = jwtDecode(response.data.token);
            localStorage.setItem("roles", JSON.stringify(payload.roles));
            
            return { user: response };
        } catch (error) {
            return thunkAPI.rejectWithValue({ error });
        }
    }
);

export const logout: any = createAsyncThunk("auth/logout", async () => {
    await AuthService.logout();
});

const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };

export const authSlice: any = createSlice({
    name: 'user',
    initialState,
    reducers: {
    },
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.data;
        },
        [login.rejected]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        },
        [logout.fulfilled]: (state, action) => {
            state.isLoggedIn = false;
            state.user = null;
        },
    },
});

export default authSlice.reducer;