import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import FlagIcon from '@mui/icons-material/Flag';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import Grid from '@mui/material/Grid';
import { MyContainedButton, MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import SelectedStatus from 'MyComponents/Status/SelectedStatus';
import MyAutoCompleteDeliverySteps from 'MyComponents/TextField/MyAutoCompleteDeliverySteps';
import { MyTextField } from 'MyComponents/TextField/MyTextField';
import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
import addressService from 'pages/Addresses/service/AddressService';
import { IFavoriteAddress } from 'pages/Addresses/type/FavoriteAddress';
import { IMessage } from 'pages/Clients/type/Message';
import { IOptions } from 'pages/Removale/types/Options';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { CustomModals } from 'shared/modals/customModals';
import { ErrorHandler } from 'shared/utils/ErrorHandler';
import deliveryService from '../service/DeliveryService';
import { IDeliveryCreator, IDeliveryStepCreator } from '../type/Delivery/DeliveryCreator';
import { IDeliveryStep } from '../type/Delivery/DeliveryData';

export function EditDelivery(props: { open: any, refresh: any, setOpen: any, delivery: any }) {

  const { open, refresh, setOpen, delivery } = props;
  const { t } = useTranslation();

  const [inputs, setInputs] = useState<IDeliveryCreator>({});
  // const [inputStatus, setInputStatus] = useState<IDeliveryStatus>({});
  const [deliverySteps, setDeliverySteps] = useState<Array<IDeliveryStepCreator>>([]);

  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState<IMessage>({ type: 'error', content: '' });

  const [openStepSignature, setOpenStepSignature] = useState(false);


  const [loading, setLoading] = useState(false);
  const [researchValue, setResearchValue] = useState(null);
  const [researchInput, setResearchInput] = useState(null);

  const [myOptions, setMyOptions] = useState<Array<IOptions>>();
  const [myOptionSelected, setMyOptionSelected] = useState<IOptions>();


  const canvasRefFrom = useRef<any>(null);
  const canvasRefTo = useRef<any>(null);

  const resetInput = () => {
    setResearchValue(null);
    setResearchInput(null);
    setDeliverySteps([]);
    setInputs({});
  }

  const handleClose = () => {
    setMessage({ type: 'error', content: '' });
    setOpenAlert(false);
    resetInput();
    setOpen(false);
  }

  const [input, setInput] = useState();

  useEffect(() => {

    if (delivery?.deliverySteps != null) {

      let reducedDeliverySteps: Array<IDeliveryStepCreator> = [];

      delivery.deliverySteps.forEach(function (deliveryStep: IDeliveryStep) {
        let newDeliverySteps: IDeliveryStepCreator = {
          favAddressId: deliveryStep?.address?.favoriteAddressId,
          signatureTo: deliveryStep.signatureTo,
          remark: deliveryStep.remark,
          step: deliveryStep.step
        };
        reducedDeliverySteps.push(newDeliverySteps);
      });

      let sortedSteps: Array<IDeliveryStepCreator> = reducedDeliverySteps.sort((n1, n2) => {
        if (n1.step != null && n2.step != null) {
          if (n1.step > n2.step) return 1;
          if (n1.step < n2.step) return -1;
        }
        return 0;
      });
      setDeliverySteps(sortedSteps)

    }
    if (delivery != null && delivery != undefined) {
      setInput(delivery?.statuses[0]?.status?.name);
    }
  }, [delivery]);

  const handleChangeDelivery = (event: any) => {
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value

    })
  }

  const handleSubmit = (id: string) => {
    setMessage({ type: 'info', content: t("Processing") });
    setOpenAlert(true);
    if (inputs != null) {
      deliveryService.updateDelivery(id, inputs)
        .then(r => {
          refresh()
          setMessage({
            type: "success",
            content: t("Carrier") + t("updatedWithSuccess")
          });
          setTimeout(() => {
            handleClose()
          }, 1500)
        })
        .catch(err => ErrorHandler(err, setMessage));
    }
  }

  useEffect(() => {
    if (delivery?.signatureFrom != null) {
      canvasRefFrom?.current?.loadSaveData(delivery?.signatureFrom, true)
    }

    if (delivery?.signatureTo != null) {
      canvasRefTo?.current?.loadSaveData(delivery?.signatureTo, true)
    }
    if (delivery != null) {
      addressService.getAllAddress(delivery?.client?.user?.userId, 0, 40, "").then(
        (response: any) => {
          const data = response.data.content;
          setMyOptions(
            data.map((ad: IFavoriteAddress) => {
              return {
                value: ad.favoriteAddressId,
                name:
                  (ad.label != null ? ad.label + " - " : '') +
                  (ad.address?.street != null ? ad.address?.street + " " : '') +
                  (ad.address?.country != null ? ad.address?.country + " " : '') +
                  (ad.address?.complement != null ? ad.address?.complement : ''),
                fullname: ad.fullname,
                phone: ad.phone,
                label: ad.label
              }
            }))
        })
        .catch(err => console.log(err));
    }

  }, [delivery])

  const actions =
    <>
      <MyOutlinedButton onClick={handleClose}>{t("cancel")}</MyOutlinedButton>
      <MyContainedButton onClick={() => handleSubmit(delivery.id)}>{t("validate")}</MyContainedButton>
    </>

  const content =
    <>
      {delivery != null && delivery != undefined ?
        (<>
          <MyModalSubTitle>
            <LocationOnIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
            Status
          </MyModalSubTitle>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SelectedStatus delivery={delivery} />
            </Grid>
          </Grid>
          <MyModalSubTitle>
            <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
            {t("informations")}
          </MyModalSubTitle>
          <Grid container spacing={1} columns={{ xs: 6, md: 12 }} >
            <Grid item xs={6}>
              <MyTextField type='text' disabled name="remark" label={t("remarkSignatureTo")} defaultValue={delivery?.signatureToRemark} onChange={handleChangeDelivery} />
            </Grid>
            <Grid item xs={6}>
              <MyTextField type='text' disabled name="remark" label={t("remarkSignatureFrom")} defaultValue={delivery?.signatureFromRemark} onChange={handleChangeDelivery} />
            </Grid>
            <Grid item xs={4}>
              <MyTextField disabled={true} type='text' name="type" label={t("typeOfDelivery")} defaultValue={delivery?.type?.name} onChange={handleChangeDelivery} />
            </Grid>
            <Grid item xs={4}>
              <MyTextField disabled={true} type='text' name="carrierId" label={t("carrier")} defaultValue={
                delivery?.carrier != null ?
                  (delivery?.carrier?.user?.firstname + " " + delivery?.carrier?.user?.lastname) :
                  ("")}
                onChange={handleChangeDelivery}
              />
            </Grid>
            <Grid item xs={4}>
              <MyTextField disabled={true} type='text' name="clientId" label="Client" defaultValue={delivery?.client?.user?.firstname + " " + delivery?.client?.user?.lastname} onChange={handleChangeDelivery} />
            </Grid>
          </Grid>
          <MyModalSubTitle>
            <LocationOnIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
            {t("journey")}
          </MyModalSubTitle>
          <Grid container spacing={1} columns={{ xs: 4, md: 12 }}>
            <Grid item xs={1}>
              <EmojiFlagsIcon sx={{ color: '#009CFF', fontSize: '40px', marginTop: '10px' }} />
            </Grid>
            <Grid item xs={11}>
              <MyTextField disabled={true} type='text' name="favAddressFromId" label={t("addressFrom")} defaultValue={
                delivery?.addressFrom?.address?.street + " " +
                delivery?.addressFrom?.address?.city + " " +
                delivery?.addressFrom?.address?.complement}
              />
            </Grid>
            {deliverySteps != null && myOptions != null &&
              <MyAutoCompleteDeliverySteps
                inputs={inputs}
                loading={loading}
                setInputs={setInputs}
                myOptions={myOptions}
                researchValue={researchValue}
                researchInput={researchInput}
                deliverySteps={deliverySteps}
                setResearchValue={setResearchValue}
                setResearchInput={setResearchInput}
                setDeliverySteps={setDeliverySteps}
                setMyOptionSelected={setMyOptionSelected}
              />
            }
            <Grid item xs={1}>
              <FlagIcon sx={{ color: '#009CFF', fontSize: '40px', marginTop: '10px' }} />
            </Grid>
            <Grid item xs={11}>
              <MyTextField disabled={true} type='text' name="favAddressToId" label={t("addressTo")} defaultValue={
                delivery?.addressTo?.address?.street + " " +
                delivery?.addressTo?.address?.city + " " +
                delivery?.addressTo?.address?.complement}
              />
            </Grid>
          </Grid>
        </>
        ) : (<></>)
      }
    </>

  return (
    <CustomModals
      open={open}
      handleClose={handleClose}
      title={t("view") + " " + t("delivery")}
      content={content}
      actions={actions}
      openAlert={openAlert}
      setOpenAlert={setOpenAlert}
      message={message}
      setMessage={setMessage}
      notContent={true}
    />
  )
}
