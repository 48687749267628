import instance from "shared/auth/service/AxiosInstance";

const API_URL: string = (process.env.REACT_APP_API_URL + "deliveries/type" );

const deliveryTypeService = {

    getDeliveryType: (pageNo: number, pageSize: number) =>
        instance.get(API_URL + "/?pageSize=" + pageSize + "&pageNo=" + pageNo),

}

export default deliveryTypeService;