import PersonIcon from '@mui/icons-material/Person';
import { logout } from 'shared/redux/features/auth/authSlice';
import { CustomModals } from "shared/modals/customModals";
import { useState } from 'react';
import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
import { useDispatch } from "react-redux";
import { MyTextField } from 'MyComponents/TextField/MyTextField';
import { useTranslation } from "react-i18next";
import invoiceService from '../service/InvoiceService';
import Grid from '@mui/material/Grid';
import { MyContainedButton, MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import { PDFViewer } from '@react-pdf/renderer';
import InvoiceTemplate from '../pdf/InvoiceTemplate';
import { IInvoice } from '../type/invoice';
import MultipleInvoiceTemplate from '../pdf/MultipleInvoiceTemplate';

export function SendInvoice(props: {
    open: boolean,
    refresh: Function,
    setOpen: Function,
    ids?: Array<String>
    selectedInvoices: Array<IInvoice>
}) {
    const { open, refresh, setOpen, ids, selectedInvoices } = props;

    const [inputs, setInputs] = useState({ email: null });

    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState({ type: 'error', content: "" });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleChange = (event: any) => {
        setInputs({
            ...inputs,
            [event.target.name]: event.target.value
        });
    }

    const resetInputs = () => {
        setInputs({ email: null });
    }

    const handleClose = () => {
        setMessage({ type: 'error', content: "" });
        setOpenAlert(false);
        resetInputs();
        setOpen(false);
    }


    const handelSend = () => {
        setMessage({ type: 'info', content: t("Processing") });
        setOpenAlert(true);
        if (inputs != null && inputs?.email != null && ids != null) {
            invoiceService.sendInvoicesTo(inputs.email, ids)
                .then(r => {
                    refresh()
                    setMessage({
                        type: "success",
                        content: t("emailSendSucess")
                    });
                    setTimeout(() => {
                        handleClose()
                    }, 1500)
                })
                .catch((err) => {
                    if (
                        err?.statusCode === 403 ||
                        err?.statusCode === 401 ||
                        err?.response?.status === 403 ||
                        err?.response?.status === 401
                    ) {
                        dispatch(logout());
                    } else {
                        setMessage({
                            type: 'error',
                            content: err?.response?.data?.message
                        })
                    }
                }
                );
        }
    }

    const content = <>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <MyTextField type='email' name="email" pattern="/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i" label={t("email")} placeholder={"email@email.lu"} onChange={handleChange} />
            </Grid>
            <Grid item xs={12}>
                <div style={{ width: '100%', height: 500 }}>
                    {selectedInvoices != null && inputs?.email != null &&
                        <PDFViewer showToolbar={false} style={{ width: '100%', height: '100%' }}>
                            <MultipleInvoiceTemplate
                                invoices={selectedInvoices}
                                email={inputs?.email}
                            />
                        </PDFViewer>
                    }
                </div>
            </Grid>
        </Grid>
    </>

    const actions = <>
        <MyOutlinedButton onClick={handleClose}>{t("cancel")}</MyOutlinedButton>
        <MyContainedButton onClick={handelSend}>{t("validate")}</MyContainedButton>
    </>

    if (ids != null && ids != undefined) {
        return (
            <CustomModals
                open={open}
                handleClose={handleClose}
                title={t("sendEmail")}
                content={content}
                actions={actions}
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
                message={message}
                setMessage={setMessage}
            />
        )
    } else {
        return <></>
    }

}

