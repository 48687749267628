import addressService from 'pages/Addresses/service/AddressService';
import { IFavoriteAddress } from 'pages/Addresses/type/FavoriteAddress';
import { IMessage } from 'pages/Clients/type/Message';
import deliveryService from 'pages/Delivery/service/DeliveryService';
import { IDeliveryCreator, IDeliveryStepCreator } from 'pages/Delivery/type/Delivery/DeliveryCreator';
import { IDeliveryData } from 'pages/Delivery/type/Delivery/DeliveryData';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { logout } from 'shared/redux/features/auth/authSlice';
import { IPayload } from 'types/payload';
import LoadingComponent from './pages/LoadingComponent';
import ShippingEnd from './pages/ShippingEnd';
import ShippingFrom from './pages/ShippingFrom';
import ShippingRecapInformation from './pages/ShippingRecapInformation';
import ShippingSteps from './pages/ShippingSteps';
import ShippingTo from './pages/ShippingTo';
import { IOptions } from './types/Options';

const waitForElm = (selector: any) => {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(mutations => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}

function Removale(props: { indexNavBar: any, setIndexNavBar: any, setActiveStep: any, activeStep: any, payload: IPayload, handleHideElement: any }) {

  const {
    indexNavBar,
    setIndexNavBar,
    setActiveStep,
    activeStep,
    payload,
    handleHideElement } = props;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [inputs, setInputs] = useState<IDeliveryCreator>({});

  const [myAddresses, setMyAddresses] = useState<Array<IFavoriteAddress>>();
  const [errors, setErrors] = useState<IMessage>({ type: 'error', content: '' });
  const [myOptions, setMyOptions] = useState<Array<IOptions>>();
  const [destinationOptions, setDestinationOptions] = useState<Array<IOptions>>();
  const [myOptionSelected, setMyOptionSelected] = useState<IOptions>();
  const [destinationOptionSelected, setDestinationOptionSelected] = useState<IOptions>();
  const [openAlert, setOpenAlert] = useState(false);

  const [createdDelivery, setCreatedDelivery] = useState<IDeliveryData>();

  const [qrcode, setQrcode] = useState<any>(null);

  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 40,
  });

  const [inputMyAdd, setInputMyAdd] = useState(null);
  const [inputAdd, setInputAdd] = useState(null);

  const [myAdd, setMyAdd] = useState<IFavoriteAddress | null>(null);
  const [allAdd, setAllAdd] = useState<IFavoriteAddress | null>(null);

  const [loading, setLoading] = useState(false);
  const [addOpen, setAddOpen] = useState(false);

  const [researchValue, setResearchValue] = useState(null);
  const [researchInput, setResearchInput] = useState(null);
  const [deliverySteps, setDeliverySteps] = useState<Array<IDeliveryStepCreator>>([]);


  const handleReset = () => {
    setInputMyAdd(null);
    setInputAdd(null);
    setMyAdd(null);
    setAllAdd(null);
    setResearchInput(null);
    setResearchValue(null);
    setInputs({});
    setActiveStep(0);
  }


  const handleChangeDelivery = (event: any) => {
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value
    })
  };

  const handleAddOpen = () => {
    setAddOpen(true);
  }

  const fetchMyAdd = ({ pageNo, pageSize } = {
    pageNo: rowsState.page,
    pageSize: rowsState.pageSize,
  }) => {
    return addressService.getAllAddressHome(payload?.me, pageNo, pageSize, inputMyAdd || "").then(
      (response: any) => {
        const data = response.data.content;
        setMyOptions(
          data.map((ad: IFavoriteAddress) => {
            return {
              value: ad.favoriteAddressId,
              name:
                (ad.label != null ? ad.label + " - " : '') +
                (ad.address?.street != null ? ad.address?.street + " " : '') +
                (ad.address?.country != null ? ad.address?.country + " " : '') +
                (ad.address?.complement != null ? ad.address?.complement : ''),
              fullname: ad.fullname,
              phone: ad.phone,
              label: ad.label
            }
          }))
        setMyAddresses(data);
      }
    ).catch((err) => {
      if (
        err?.statusCode === 403 ||
        err?.statusCode === 401 ||
        err?.response?.status === 403 ||
        err?.response?.status === 401
      ) {
        dispatch(
          logout()
        );
      } else {
        setErrors(err?.response?.data?.error)
      }
    });
  }

  const fetchAllAdd = ({ pageNo, pageSize } = {
    pageNo: rowsState.page,
    pageSize: rowsState.pageSize,
  }) => {
    addressService.getAllAddressDestination(payload?.me, pageNo, pageSize, inputAdd || "").then(
      (response: any) => {
        const data = response.data.content;
        setDestinationOptions(
          data.map((ad: IFavoriteAddress) => {
            return {
              value: ad.favoriteAddressId,
              name:
                (ad.label != null ? ad.label + " - " : '') +
                (ad.address?.street != null ? ad.address?.street + " " : '') +
                (ad.address?.country != null ? ad.address?.country + " " : '') +
                (ad.address?.complement != null ? ad.address?.complement : ''),
              fullname: ad.fullname,
              phone: ad.phone,
              label: ad.label
            }
          }))
        setMyAddresses(data);
      }
    ).catch((err) => {
      if (
        err?.statusCode === 403 ||
        err?.statusCode === 401 ||
        err?.response?.status === 403 ||
        err?.response?.status === 401
      ) {
        dispatch(
          logout()
        );
      } else {
        setErrors(err?.response?.data?.error)
      }
    });
  }

  useEffect(() => {
    handleHideElement(false);
    setIndexNavBar(indexNavBar); //set color of current page button on navbar
    setActiveStep(0); //set activeStep to start

    fetchMyAdd({
      pageNo: rowsState.page,
      pageSize: rowsState.pageSize
    })

    fetchAllAdd({
      pageNo: rowsState.page,
      pageSize: rowsState.pageSize
    })
  }, []);

  const handleSubmit = () => {
    setActiveStep(4);
    setOpenAlert(true);
    inputs.userId = payload?.me;
    deliveryService.createDelivery(inputs)
      .then(r => {
        setCreatedDelivery(r.data)
        setErrors({
          type: "success",
          content: t("removalCreated")
        })
        setTimeout(() => {
          setOpenAlert(false);
        }, 1500)

        waitForElm('canvas').then(() => {
          const qrCodeCanvas = document.getElementById('qrcode') as HTMLCanvasElement;
          const qrCodeDataUri = qrCodeCanvas.toDataURL('image/jpg', 0.6);
          setQrcode(qrCodeDataUri);
        });
      })
      .catch((err) => {
        if (
          err?.statusCode === 403 ||
          err?.statusCode === 401 ||
          err?.response?.status === 403 ||
          err?.response?.status === 401
        ) {
          dispatch(
            logout()
          );
        } else {
          setErrors(err?.response?.data?.error)
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(
      () => fetchMyAdd({
        pageNo: rowsState.page,
        pageSize: rowsState.pageSize
      }), 500
    );
    setLoading(false);
    return () => clearTimeout(timeoutId);
  }, [inputMyAdd]);

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(
      () => fetchAllAdd({
        pageNo: rowsState.page,
        pageSize: rowsState.pageSize
      }), 500
    );
    setLoading(false);
    return () => clearTimeout(timeoutId);
  }, [inputAdd]);

  if (myOptions != null && myAddresses != null && destinationOptions != null) {
    if (activeStep === 0) {
      return (
        <ShippingFrom
          inputs={inputs}
          setInputs={setInputs}
          myAdd={myAdd}
          setMyAdd={setMyAdd}
          inputMyAdd={inputMyAdd}
          setInputMyAdd={setInputMyAdd}
          myOptions={myOptions}
          myOptionSelected={myOptionSelected}
          setMyOptionSelected={setMyOptionSelected}
          handleAddOpen={handleAddOpen}
          addOpen={addOpen}
          setAddOpen={setAddOpen}
          fetchMyAdd={fetchMyAdd}
          payload={payload}
          setActiveStep={setActiveStep}
          loading={loading}
          handleChangeDelivery={handleChangeDelivery}
        />
      )
    } else if (activeStep === 1) {
      return (
        <ShippingTo
          inputs={inputs}
          setInputs={setInputs}
          allAdd={allAdd}
          inputAdd={inputAdd}
          handleAddOpen={handleAddOpen}
          addOpen={addOpen}
          setAddOpen={setAddOpen}
          payload={payload}
          setActiveStep={setActiveStep}
          loading={loading}
          handleChangeDelivery={handleChangeDelivery}
          destinationOptions={destinationOptions}
          destinationOptionSelected={destinationOptionSelected}
          setDestinationOptionSelected={setDestinationOptionSelected}
          setAllAdd={setAllAdd}
          setInputAdd={setInputAdd}
          fetchAllAdd={fetchAllAdd}
        />
      )
    } else if (activeStep === 2) {
      return (
        <ShippingSteps
          inputs={inputs}
          setInputs={setInputs}
          myOptions={myOptions}
          destinationOptions={destinationOptions}
          handleSubmit={handleSubmit}
          setActiveStep={setActiveStep}
          handleAddOpen={handleAddOpen}
          myAdd={myAdd}
          setMyAdd={setMyAdd}
          setMyOptionSelected={setMyOptionSelected}
          setInputMyAdd={setInputMyAdd}
          inputMyAdd={inputMyAdd}
          loading={loading}
          researchValue={researchValue}
          setResearchValue={setResearchValue}
          researchInput={researchInput}
          setResearchInput={setResearchInput}
          deliverySteps={deliverySteps}
          setDeliverySteps={setDeliverySteps}
        />
      )
    } else if (activeStep === 3) {
      return (
        <ShippingRecapInformation
          inputs={inputs}
          myOptions={myOptions}
          destinationOptions={destinationOptions}
          handleSubmit={handleSubmit}
          setActiveStep={setActiveStep}
          deliverySteps={deliverySteps}
        />
      )
    } else {
      return (
        <ShippingEnd
          createdDelivery={createdDelivery}
          openAlert={openAlert}
          errors={errors}
          setErrors={setErrors}
          setOpenAlert={setOpenAlert}
          setInputs={setInputs}
          setActiveStep={setActiveStep}
          handleReset={handleReset}
        />
      )
    }
  } else {
    return (
      <LoadingComponent
        myOptionSelected={myOptionSelected}
        handleChangeDelivery={handleChangeDelivery}
      />
    )
  }
}

export default Removale;