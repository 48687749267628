import React, { useState } from 'react';
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import InventoryIcon from "@mui/icons-material/Inventory";
import Zoom from '@mui/material/Zoom';
import { useTranslation } from "react-i18next";
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import FlagIcon from '@mui/icons-material/Flag';
import PanoramaVerticalSelectIcon from '@mui/icons-material/PanoramaVerticalSelect';

function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <EmojiFlagsIcon />,
        2: <FlagIcon />,
        3:<PanoramaVerticalSelectIcon />,
        4: <InventoryIcon />
    };

    return (
        <ColorlibStepIconRoot
            ownerState={{ completed, active }}
            className={className}
        >
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 0,
        left: '33%'
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: "#82beeb"
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: "#82beeb"
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 35,
        width: 3,
        border: 0,
        borderRadius: 5,
        backgroundColor: "#eaeaf0"
    }
}));

const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    marginTop: 40,
    borderRadius: "75%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    ...(ownerState.active && {
        backgroundColor: "#009CFF",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
    }),
    ...(ownerState.completed && {
        backgroundColor: "#009CFF"
    })
}));


function DrawerRemovale(props: { activeStep: any }) {
    const { t } = useTranslation();

    const steps = [
        { label: t("receiver") , transitionDelay:'250ms'},
        { label: t("End") , transitionDelay:'500ms'},
        { label: t("Step") , transitionDelay:'750ms'},
        { label: t("send"), transitionDelay:'1000ms' }
    ];
    const { activeStep } = props;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
            <Stepper
                alternativeLabel
                activeStep={activeStep}
                orientation="vertical"
                connector={<ColorlibConnector />}
            >
                {steps.map((step) => (
                    <Zoom in={true} style={{ transitionDelay: step.transitionDelay }}>
                        <Step key={step.label} sx={{ display: 'flex', felxDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{step.label}</StepLabel>
                        </Step>
                    </Zoom>
                ))}
            </Stepper>
        </div>
    );
}

export default DrawerRemovale;