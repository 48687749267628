import * as React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import InventoryIcon from '@mui/icons-material/Inventory';
import { useDispatch } from 'react-redux';
import { logout } from 'shared/redux/features/auth/authSlice';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import logo from  "../../assets/newLogo.jpg";

import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography
} from '@mui/material';
import { useTranslation } from "react-i18next";


const Onglet = styled('button')((props: { focus: boolean }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  fontFamily: 'Sansation, PinkBlue, Helvetica, sans-serif, -apple-system',
  fontSize: '22px',
  color: (props.focus ? '#F7A007' : '#fff'),
  backgroundColor: 'transparent',
  border: 'none',
  margin: '10px',
  cursor: 'pointer',
  transitionDuration: '0.3s',
  ':hover': {
    marginBottom: '11px',
    marginTop: '3px',
  },
  ':focus': {
    color: '#F7A007',
  },
}));

function NavBar(props: { indexNavBar: any, roles: any, hideElement: boolean, window?: () => Window }) {

  const { indexNavBar, roles, hideElement, window } = props;
  const { t } = useTranslation();

  const ROLE_ADMIN = process.env.REACT_APP_ROLE_ADMIN;
  const ROLE_CARRIER = process.env.REACT_APP_ROLE_CARRIER;
  const ROLE_CLIENT = process.env.REACT_APP_ROLE_CLIENT;
  const ROLE_FACTURATION = process.env.REACT_APP_ROLE_FACTURATION;

  const buttonList = [{ name: t('home'), icon: <HomeIcon sx={{ fontSize: '42px' }} />, redirection: '/' }]

  if (roles.includes(ROLE_ADMIN)) {
    buttonList.push(
      { name: t('navDelivery'), icon: <LocalShippingIcon sx={{ fontSize: '42px' }} />, redirection: '/Delivery' },
      { name: t('navClient'), icon: <PersonIcon sx={{ fontSize: '42px' }} />, redirection: '/Clients' },
      { name: t("navCarrier"), icon: <TransferWithinAStationIcon sx={{ fontSize: '42px' }} />, redirection: '/Carriers' },
      { name: t('navLogs'), icon: <ManageSearchIcon sx={{ fontSize: '42px' }} />, redirection: '/Logs' }
    )
  } else if (roles.includes(ROLE_CARRIER)) {
    buttonList.push(
      { name: t('navDelivery'), icon: <LocalShippingIcon sx={{ fontSize: '42px' }} />, redirection: '/Delivery' },
    )

  } else if (roles.includes(ROLE_CLIENT)) {
    buttonList.push(
      { name: t('navDelivery'), icon: <LocalShippingIcon sx={{ fontSize: '42px' }} />, redirection: '/Delivery' },
      { name: t('navClient'), icon: <PersonIcon sx={{ fontSize: '42px' }} />, redirection: '/Clients' },
      { name: t('navInvoice'), icon: <DescriptionIcon sx={{ fontSize: '42px' }} />, redirection: '/Invoice' },
      { name: t('navRemoval'), icon: <InventoryIcon sx={{ fontSize: '42px' }} />, redirection: '/Removale' }
    )
  } else if (roles.includes(ROLE_FACTURATION)) {
    buttonList.push(
      { name: t("navInvoice"), icon: <DescriptionIcon sx={{ fontSize: '42px' }} />, redirection: '/Invoice' }
    )
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawerWidth = 240;

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        PostEx
      </Typography>
      <Divider />
      <List>
        {buttonList.map((menu: any, index) => (
          <Link to={menu?.redirection} style={{ color: (indexNavBar === index ? '#EC5428' : 'inherit') }}>
            <ListItem key={menu}>
              <ListItemIcon>{menu?.icon}</ListItemIcon>
              <ListItemText primary={menu?.name} />
            </ListItem>
          </Link>
        ))}
        <Divider />
        <Link to={'/settings'} style={{ color: (indexNavBar === buttonList?.length ? '#EC5428' : 'inherit') }}>
          <ListItem key={"settings"}>
            <ListItemIcon><Settings htmlColor='#EC5428' sx={{ fontSize: '42px' }} /></ListItemIcon>
            <ListItemText primary={t("navSettings")} />
          </ListItem>
        </Link>
        <Link to={'/'} onClick={() => dispatch(logout())}>
          <ListItem key={"logout"}>
            <ListItemIcon><Logout htmlColor='#EC5428' sx={{ fontSize: '42px' }} /></ListItemIcon>
            <ListItemText primary={t("logout")} />
          </ListItem>
        </Link>
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  if (hideElement) {
    return (<></>)
  } else {

    return (
      <>
        <Box >
          <CssBaseline />
          <AppBar component="nav" sx={{ backgroundColor: '#EC5428' }}>
            <Toolbar sx={{ backgroundColor: '#EC5428', position: 'relative', height: 80 }}>
              <div style={{ position: 'absolute', top: '0', left: '0', textAlign: 'center', width: 100, height: 80, background: 'white' }}>
                <a href="https://www.postex.lu/" target='_blank' style={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                  <img style={{ width: '70%', height: '100%', }} alt='Logo'  src={logo}/>
                </a>
              </div>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: 'none' }, margin: { xs: '0 auto', md: '0' } }}
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{ display: { xs: 'none', md: 'flex', marginLeft:'15%', justifyContent:"center", alignItems:"center" } }}>
                {buttonList.map((button, index) => {
                  return (
                    <Link to={button.redirection}>
                      <Onglet focus={indexNavBar === index ? true : false}>
                        {button.icon}
                        {button.name}
                      </Onglet>
                    </Link>
                  )
                })}

                <div style={{ width: '20%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                  <Onglet focus={indexNavBar === buttonList?.length ? true : false}>
                    <IconButton
                      onClick={handleClick}
                      size="large"
                      sx={{ ml: 2 }}
                      aria-controls={open ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                    >
                      <MoreVertIcon fontSize='large' />
                    </IconButton>
                  </Onglet>
                </div>
              
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: '40%',
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem>
                    <Link to={'/settings'}>
                      <ListItemIcon>
                        <Settings fontSize="large" htmlColor='#EC5428' />
                      </ListItemIcon>
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to={'/'} onClick={() => dispatch(logout())}>
                      <ListItemIcon>
                        <Logout fontSize="large" htmlColor='#EC5428' />
                      </ListItemIcon>
                    </Link>
                  </MenuItem>
                </Menu>
              </Box>
            </Toolbar>
          </AppBar>

          <Box component="nav">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { sm: 'block', md: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
            >
              {drawer}
            </Drawer>
          </Box>
          <Box component="main" sx={{ display: 'flex', flexDirection: 'row' }}>
            <Toolbar />
          </Box>
        </Box>
      </>
    );
  }
}

export default NavBar;
