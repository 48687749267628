import ReactPDF, { View } from "@react-pdf/renderer";
export interface ICustomRow {
    percentageWidth: string;
    data: any;
}

function PDFRow(props: {
    styles: ReactPDF.Styles,
    rows: Array<ICustomRow>

}) {

    const {
        styles,
        rows
    } = props;

    return (
        <>
            <View style={styles.tableRow}>
                {rows.map((row: ICustomRow) => {
                    return (
                        <View key={row.percentageWidth} style={[styles.tableCol, { width: row.percentageWidth + '%' }]}>
                            {row.data}
                        </View>
                    )
                })
                }
            </View>
        </>
    )
}

export default PDFRow;