import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PersonIcon from '@mui/icons-material/Person';
import StorageIcon from '@mui/icons-material/Storage';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CommentIcon from '@mui/icons-material/Comment';
import Zoom from '@mui/material/Zoom';

import { getISODate, getISOTime, localeISODateTime } from 'shared/utils/localeISODateTime';
import { Tooltip } from '@mui/material';
import { useTranslation } from "react-i18next";

const DrawerLogs = (props: { log: any, open: any, handleOpen: any }) => {

  const { log, open, handleOpen } = props;
  const { t } = useTranslation();

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
        <ManageSearchIcon sx={{ fontSize: '120px', color: '#009CFF' }} />
        {log !== null && log !== undefined &&
          <p style={{ fontSize: '20px', marginTop: '0px' }}>
            {getISODate(localeISODateTime(new Date(log?.date)))} {getISOTime(localeISODateTime(new Date(log?.date)))}
          </p>
        }
        <Zoom in={true} style={{ transitionDelay: '500ms' }}>
          <div style={{
            borderRadius: '12px', backgroundColor: '#F2F2F2', display: 'flex', flexDirection: "column", width: '75%', padding: '4vh 3vh', justifyContent: 'space-between', textAlign: 'center', boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)'
          }}>

            {log !== null && log !== undefined &&
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <PersonIcon sx={{ fontSize: '25px', color: '#009CFF', marginRight: 1 }} />
                  <span>{log?.remoteUserId}</span>
                </div>
                <div style={{ display: 'flex' }}>
                  <StorageIcon sx={{ fontSize: '25px', color: '#009CFF', marginRight: 1 }} />
                  <span>{`${log?.remoteEntity} [${log?.remoteEntityId}]`}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CallToActionIcon sx={{ fontSize: '25px', color: '#009CFF', marginRight: 1 }} />
                  <span>{`${log?.actionType}`}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowBackIosIcon sx={{ fontSize: '25px', color: '#009CFF', marginRight: 1 }} />
                  {log?.oldValue && <Tooltip title={<span>{`${log?.oldValue}`}</span>}><CommentIcon sx={{ fontSize: '25px' }} /></Tooltip>}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowForwardIosIcon sx={{ fontSize: '25px', color: '#009CFF', marginRight: 1 }} />
                  {log?.newValue && <Tooltip title={<span>{`${log?.newValue}`}</span>}><CommentIcon sx={{ fontSize: '25px' }} /></Tooltip>}
                </div>
              </>
            }
            {(log === null || log === undefined) &&
              t("noLogSelected")
            }
          </div>
        </Zoom>
      </div>
    </>
  );
}

export default DrawerLogs;