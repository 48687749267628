import { styled } from '@mui/material/styles';

const MyTitle = styled('h1')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Sansation, PinkBlue, Helvetica, sans-serif, -apple-system',
    fontSize: '36px',
    color: '#333333',
}));

export default MyTitle;