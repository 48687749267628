import Address from 'pages/Addresses/Address';
import { useEffect } from 'react';
import { IPayload } from 'types/payload';

function Settings(props: {
  indexNavBar: any,
  setIndexNavBar: any,
  selectedAddress: any,
  setSelectedAddress: any,
  open: boolean,
  setOpen: any,
  payload:IPayload,
  index:number,
  handleHideElement:any
}) {

  const { setIndexNavBar, indexNavBar,
    selectedAddress,
    setSelectedAddress,
    open,
    setOpen,
    payload,
    handleHideElement
  } = props;


  useEffect(() => {
    handleHideElement(false);
    setIndexNavBar(indexNavBar); //set color of current page button on navbar
    // setIndexNavBar(6); //set color of current page button on navbar
  }, []);

  return (
    <>
      <Address
        indexNavBar={indexNavBar}
        setIndexNavBar={setIndexNavBar}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        payload={payload}
        open={open}
        setOpen={setOpen}
        handleHideElement={handleHideElement}
      />
    </>
  );
}

export default Settings;