import instance from "shared/auth/service/AxiosInstance";
import { IFavoriteAddress } from "../type/FavoriteAddress";

const API_URL = process.env.REACT_APP_API_URL + "addresses/favoriteAddress";

const addressService = {

    getAllAddress: (userId: string, pageNo: number, pageSize: number, research: string) =>
        instance.get(API_URL + "/user/" + userId + "?pageSize=" + pageSize + "&pageNo=" + pageNo + "&research=" + research),

    getAllAddressDestination: (userId: string, pageNo: number, pageSize: number, research: string) =>
        instance.get(API_URL + "/user/" + userId + "/destination" + "?pageSize=" + pageSize + "&pageNo=" + pageNo + "&research=" + research),

    getAllAddressHome: (userId: string, pageNo: number, pageSize: number, research: string) =>
        instance.get(API_URL + "/user/" + userId + "/home?pageSize=" + pageSize + "&pageNo=" + pageNo + "&research=" + research),

    createAddress: (userId: string, favAddress: IFavoriteAddress) =>
        instance.post(API_URL + "/user/" + userId, favAddress),

    updateAddress: (id: string, favAddress: IFavoriteAddress) =>
        instance.put(API_URL + "/" + id, favAddress),

    deleteAddress: (id: string) =>
        instance.delete(API_URL + "/" + id)
}

export default addressService;