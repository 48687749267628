import { MouseEventHandler, ChangeEventHandler } from "react";
import { Box, FormControl, IconButton, MenuItem, TextField } from "@mui/material";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Tooltip from '@mui/material/Tooltip';
import MyTitle from "MyComponents/Title/MyTitle";
import { useTranslation } from "react-i18next";

const actionTypes = ["", "LOGIN", "CREATE", "UPDATE", "DELETE", "LOGIN", "LOGOUT"];
const remoteEntities = ["", "CARRIER", "ADDRESS", "FA_ADDRESS", "CLIENT", "USER", "ROLE", "AUTH", "DELIVERY", "DELIVERY_ITEM", "DELIVERY_TYPE", "INVOICE"];

export default function SearchLogs(props: {
    inputs: any,
    handleChange: ChangeEventHandler<HTMLInputElement>,
    resetInput: MouseEventHandler<HTMLButtonElement>

}) {

    const { t } = useTranslation();
    const {
        inputs,
        handleChange,
        resetInput
    } = props;

    return (
        <>
            <MyTitle>
                Logs
            </MyTitle>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', width: '95%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: { xs: '100%', md: '25%' } }}>
                        <FormControl margin='dense' sx={{ width: { xs: '50%', md: '100%' } }}>
                            <TextField
                                id="startDate"
                                name="startDate"
                                label={t("startDate")}
                                type="date"
                                value={inputs?.startDate || ""}
                                onChange={handleChange}
                                size='small'
                                sx={{
                                    boxShadow: 'none',
                                    '.MuiOutlinedInput-notchedOutline': { border: "3.5px solid #F5C065" },
                                    marginX: '5px'
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                        <FormControl margin='dense' sx={{ width: { xs: '50%', md: '100%' } }}>
                            <TextField
                                id="endDate"
                                name="endDate"
                                label={t("endDate")}
                                type="date"
                                value={inputs?.endDate || ""}
                                onChange={handleChange}
                                size='small'
                                sx={{
                                    boxShadow: 'none',
                                    '.MuiOutlinedInput-notchedOutline': { border: "3.5px solid #F5C065" },
                                    marginX: '5px'
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', width: { xs: '100%', md: '30%' } }}>
                        <FormControl margin='dense' sx={{ width: { xs: '50%', md: '100%' } }}>
                            <TextField
                                select
                                value={inputs?.remoteEntity || ""}
                                label={t("Entity")}
                                onChange={handleChange}
                                id="remoteEntity"
                                name="remoteEntity"
                                size="small"
                                sx={{
                                    boxShadow: 'none',
                                    '.MuiOutlinedInput-notchedOutline': { border: "3.5px solid #F5C065" },
                                    marginX: '5px'
                                }}
                            >
                                {remoteEntities.map((entity: string) =>
                                    <MenuItem key={entity} value={entity}>{entity}</MenuItem>
                                )}
                            </TextField>
                        </FormControl>

                        <FormControl margin='dense' sx={{ width: { xs: '50%', md: '100%' } }}>
                            <TextField
                                id="remoteEntityId"
                                name="remoteEntityId"
                                variant="outlined"
                                onChange={handleChange}
                                className="searchInput"
                                value={inputs?.remoteEntityId || ""}
                                label={t("remoteEntityId")}
                                size="small"
                                sx={{
                                    boxShadow: 'none',
                                    '.MuiOutlinedInput-notchedOutline': { border: "3.5px solid #F5C065" },
                                    marginX: '5px'
                                }}
                            >
                            </TextField>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', width: { xs: '100%', md: '30%' } }}>
                        <FormControl margin='dense' sx={{ width: { xs: '50%', md: '100%' } }}>
                            <TextField
                                select
                                value={inputs?.actionType || ""}
                                label="Action"
                                onChange={handleChange}
                                id="actionType"
                                name="actionType"
                                size="small"
                                sx={{
                                    boxShadow: 'none',
                                    '.MuiOutlinedInput-notchedOutline': { border: "3.5px solid #F5C065" },
                                    marginX: '5px'
                                }}
                            >
                                {actionTypes.map((action: string) =>
                                    <MenuItem key={action} value={action}>{action}</MenuItem>
                                )}
                            </TextField>
                        </FormControl>
                        <FormControl margin='dense' sx={{ width: { xs: '50%', md: '100%' } }}>
                            <TextField
                                id="remoteUserId"
                                name="remoteUserId"
                                variant="outlined"
                                onChange={handleChange}
                                className="searchInput"
                                value={inputs?.remoteUserId || ""}
                                label={t("emailUser")}
                                size="small"
                                sx={{
                                    boxShadow: 'none',
                                    '.MuiOutlinedInput-notchedOutline': { border: "3.5px solid #F5C065" },
                                    marginX: '5px'
                                }}
                            >
                            </TextField>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: { xs: '100%', md: '15%' } }}>
                        <Tooltip title={t("erase")} placement='top'>
                            <IconButton onClick={resetInput}>
                                <FilterAltOffIcon sx={{ color: '#F5C065', fontSize: '35px' }} />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        </>
    )
}