import jwtDecode from "jwt-decode";
import { useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Clients from './pages/Clients/Clients';
import DrawerClients from "./pages/Clients/DrawerClients";
import Delivery from './pages/Delivery/Delivery';
import DrawerDelivery from "./pages/Delivery/DrawerDelivery";
import { CustomDrawer as Drawer } from "./pages/Drawer/Drawer";
import DrawerHome from "./pages/Home/DrawerHome";
import Home from './pages/Home/Home';
import DrawerInvoice from "./pages/Invoice/DrawerInvoice";
import Invoice from './pages/Invoice/Invoice';
import Login from "./pages/Login/Login";
import NavBar from './pages/NavBar/NavBar';
import DrawerRemovale from "./pages/Removale/DrawerRemovale";
import Removale from './pages/Removale/Removale';
import DrawerSettings from "./pages/Settings/DrawerSettings";
import Settings from './pages/Settings/Settings';

import ClientAddress from "pages/Addresses/ClientAddress";
import DraweraddressAddress from "pages/Addresses/DrawerClientAddress";
import Carrier from "pages/Carrier/Carrier";
import DrawerCarriers from "pages/Carrier/DrawerCarrier";
import DeliveryStatus from "pages/Delivery/deliveryStatus/DeliveryStatus";
import DrawerLogs from "pages/logs/DrawerLogs";
import Logs from "pages/logs/Logs";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "shared/redux/features/auth/authSlice";
import { IPayload } from "types/payload";

import './App.css';

function App() {

  const { t } = useTranslation();

  const roles = JSON.parse(localStorage.getItem('roles') || '{}');

  const ROLE_ADMIN = process.env.REACT_APP_ROLE_ADMIN;
  const ROLE_CARRIER = process.env.REACT_APP_ROLE_CARRIER;
  const ROLE_CLIENT = process.env.REACT_APP_ROLE_CLIENT;
  const ROLE_FACTURATION = process.env.REACT_APP_ROLE_FACTURATION;

  const [indexNavBar, setIndexNavBar] = useState(0);

  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [invoiceToShow, setInvoiceToShow] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedLog, setSelectedLog] = useState(null);

  const [hideElement, setHideElement] = useState(false);

  const handleHideElement = (newState: boolean) => {
    setHideElement(newState)
  }

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  }

  const [addOpen, setAddOpen] = useState(false);
  const handleAddOpen = () => {
    setAddOpen(true);
  }

  const [editOpen, setEditOpen] = useState(false);
  const handleEditOpen = () => {
    setEditOpen(true);
  }

  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state: any) => state?.auth) || null;

  if (currentUser === null) {
    dispatch(logout());
  }

  if (currentUser == null || currentUser?.token == null) {
    return (
      <>
        <Router>
          <Routes>
            <Route path="*" element={<Login />} />
            <Route path="/start/:deliveryId" element={<Login />} />
            <Route path="/end/:deliveryId" element={<Login />} />
          </Routes>
        </Router>
      </>
    );

  } else if (roles.includes(ROLE_FACTURATION)) {
    let payload: IPayload = jwtDecode(currentUser.token)
    const drawerTitleList = [
      t("Utilisateur"),
      t("Facture"),
      t("MonCompte")
    ];

    const drawerContentList = [
      <DrawerHome key={"drawerHome"} user={{ fullname: payload?.fullname, email: payload?.email, lastConnection: payload?.lastConnection, locked: false }} />,
      <DrawerInvoice key={"drawerInvoice"} invoice={selectedInvoice} handleOpen={handleOpen} roles={roles} />,
      <DrawerSettings key={"drawerSettings"} user={{ fullname: payload?.fullname, email: payload?.email, lastConnection: payload?.lastConnection, locked: false }} payload={payload} />
    ];

    return (
      <Router>
        <NavBar indexNavBar={indexNavBar} roles={roles} hideElement={hideElement} />
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Routes>
            <Route path="/*" element={
              <Home
                indexNavBar={drawerTitleList.indexOf(t("Utilisateur"))}
                setIndexNavBar={setIndexNavBar}
                handleHideElement={handleHideElement}
                payload={payload}
              />} />
            <Route path="Invoice" element={
              <Invoice
                indexNavBar={drawerTitleList.indexOf(t("Facture"))}
                setIndexNavBar={setIndexNavBar}
                setSelectedInvoice={setSelectedInvoice}
                selectedInvoice={selectedInvoice}
                setInvoiceToShow={setInvoiceToShow}
                open={open}
                setOpen={setOpen}
                handleHideElement={handleHideElement}
                roles={roles}
                payload={payload}
              />} />
            <Route path="/Settings/*" element={
              <Settings
                index={drawerTitleList.indexOf(t("MonCompte"))}
                indexNavBar={drawerTitleList.indexOf(t("MonCompte"))}
                payload={payload}
                setIndexNavBar={setIndexNavBar}
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                open={open}
                setOpen={setOpen}
                handleHideElement={handleHideElement}
              />} />
          </Routes>
          <Drawer
            hide={hideElement}
            title={drawerTitleList[indexNavBar]}
            content={drawerContentList[indexNavBar]}
          />
        </div>
      </Router>
    );

  } else if (roles.includes(ROLE_ADMIN)) {
    let payload: IPayload = jwtDecode(currentUser.token)
    const drawerTitleList = [
      t("Utilisateur"),
      t("Livraison"),
      t("Client"),
      t("Transporteur"),
      t("Log"),
      t("MonCompte")
    ];

    const drawerContentList = [
      <DrawerHome key={"drawerHome"} user={{ fullname: payload?.fullname, email: payload?.email, lastConnection: payload?.lastConnection, locked: false }} />,
      <DrawerDelivery key={"drawerDelivery"} delivery={selectedDelivery} open={open} handleOpen={handleOpen} handleAddOpen={handleAddOpen} roles={roles} handleEditOpen={handleEditOpen} />,
      <DrawerClients key={"drawerClients"} client={selectedClient} open={open} handleOpen={handleOpen} />,
      <DrawerCarriers key={"drawerCarrier"} carrier={selectedCarrier} open={open} handleOpen={handleOpen} handleDeleteOpen={handleAddOpen} />,
      <DrawerLogs key={"drawerLogs"} log={selectedLog} open={open} handleOpen={handleOpen} />,
      <DrawerSettings key={"drawerSettings"} user={{ fullname: payload?.fullname, email: payload?.email, lastConnection: payload?.lastConnection, locked: false }} payload={payload} />
    ];

    return (
      <>
        <Router>
          <NavBar indexNavBar={indexNavBar} roles={roles} hideElement={hideElement} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Routes>
              <Route path="/*" element={
                <Home
                  indexNavBar={drawerTitleList.indexOf(t("Utilisateur"))}
                  setIndexNavBar={setIndexNavBar}
                  handleHideElement={handleHideElement}
                  payload={payload}
                />} />
              <Route path="/Delivery" element={
                <Delivery
                  indexNavBar={drawerTitleList.indexOf(t("Livraison"))}
                  setIndexNavBar={setIndexNavBar}
                  selectedDelivery={selectedDelivery}
                  setSelectedDelivery={setSelectedDelivery}
                  open={open}
                  setOpen={setOpen}
                  roles={roles}
                  selfId={payload.me}
                  handleHideElement={handleHideElement}
                  addOpen={addOpen}
                  setAddOpen={setAddOpen}
                  reduced={false}
                  editOpen={editOpen}
                  setEditOpen={setEditOpen}
                />} />
              <Route path="/Clients/*" element={
                <Clients
                  indexNavBar={drawerTitleList.indexOf(t("Client"))}
                  setIndexNavBar={setIndexNavBar}
                  selectedClient={selectedClient}
                  setSelectedClient={setSelectedClient}
                  open={open}
                  setOpen={setOpen}
                  handleHideElement={handleHideElement}
                  reduced={false}
                />} />
              <Route path="/Carriers" element={
                <Carrier
                  indexNavBar={drawerTitleList.indexOf(t("Transporteur"))}
                  setIndexNavBar={setIndexNavBar}
                  selectedCarrier={selectedCarrier}
                  setSelectedCarrier={setSelectedCarrier}
                  open={open}
                  setOpen={setOpen}
                  handleHideElement={handleHideElement}
                  setDeleteOpen={setAddOpen}
                  deleteOpen={addOpen}
                />} />
              <Route path="/Logs/*" element={
                <Logs
                  indexNavBar={drawerTitleList.indexOf(t("Log"))}
                  setIndexNavBar={setIndexNavBar}
                  selectedLog={selectedLog}
                  setSelectedLog={setSelectedLog}
                  open={open}
                  setOpen={setOpen}
                  roles={roles}
                  handleHideElement={handleHideElement}
                />} />
              <Route path="/Settings/*" element={
                <Settings
                  index={drawerTitleList.indexOf(t("MonCompte"))}
                  indexNavBar={drawerTitleList.indexOf(t("MonCompte"))}
                  payload={payload}
                  setIndexNavBar={setIndexNavBar}
                  selectedAddress={selectedAddress}
                  setSelectedAddress={setSelectedAddress}
                  open={open}
                  setOpen={setOpen}
                  handleHideElement={handleHideElement}
                />} />
            </Routes>
            <Drawer
              hide={hideElement}
              title={drawerTitleList[indexNavBar]}
              content={drawerContentList[indexNavBar]}
            />
          </div>
        </Router>
      </>
    );
  } else if (roles.includes(ROLE_CARRIER)) {
    let payload: IPayload = jwtDecode(currentUser.token)
    const drawerTitleList = [
      t("Utilisateur"),
      t("Livraison"),
      t("MonCompte")
    ];

    const drawerContentList = [
      <DrawerHome key={"drawerHome"} user={{ fullname: payload?.fullname, email: payload?.email, lastConnection: payload?.lastConnection, locked: false }} />,
      <DrawerDelivery key={"drawerDelivery"} delivery={selectedDelivery} open={open} handleOpen={handleOpen} handleAddOpen={handleAddOpen} roles={roles} />,
      <DrawerSettings key={"drawerSettings"} user={{ fullname: payload?.fullname, email: payload?.email, lastConnection: payload?.lastConnection, locked: false }} payload={payload} />
    ];

    return (
      <>
        <Router>
          <NavBar indexNavBar={indexNavBar} roles={roles} hideElement={hideElement} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Routes>
              <Route path="/*" element={
                <Home
                  indexNavBar={drawerTitleList.indexOf(t("Utilisateur"))}
                  setIndexNavBar={setIndexNavBar}
                  handleHideElement={handleHideElement}
                  payload={payload}
                />} />
              <Route path="/Delivery/*" element={
                <Delivery
                  indexNavBar={drawerTitleList.indexOf(t("Livraison"))}
                  setIndexNavBar={setIndexNavBar}
                  selectedDelivery={selectedDelivery}
                  setSelectedDelivery={setSelectedDelivery}
                  open={open}
                  setOpen={setOpen}
                  roles={roles}
                  selfId={payload.me}
                  handleHideElement={handleHideElement}
                  addOpen={addOpen}
                  setAddOpen={setAddOpen}
                  reduced={false}
                />} />

              <Route path="/Delivery/start/:id"
                element={
                  <DeliveryStatus
                    handleHideElement={handleHideElement}
                    open={open}
                    setOpen={setOpen}
                  />
                }
              />
              <Route path="/Settings/*" element={
                <Settings
                  index={drawerTitleList.indexOf(t("MonCompte"))}
                  indexNavBar={drawerTitleList.indexOf(t("MonCompte"))}
                  payload={payload}
                  setIndexNavBar={setIndexNavBar}
                  selectedAddress={selectedAddress}
                  setSelectedAddress={setSelectedAddress}
                  open={open}
                  setOpen={setOpen}
                  handleHideElement={handleHideElement}
                />} />
            </Routes>
            <Drawer
              hide={hideElement}
              title={drawerTitleList[indexNavBar]}
              content={drawerContentList[indexNavBar]}
            />
          </div>
        </Router>
      </>
    );
  } else if (roles.includes(ROLE_CLIENT)) {
    let payload: IPayload = jwtDecode(currentUser.token)
    const drawerTitleList = [
      t("Utilisateur"),
      t("Livraison"),
      t("Client"),
      t("Facture"),
      t("Étapes"),
      t("MonCompte")
    ];

    const drawerContentList = [
      <DrawerHome key={"drawerHome"} user={{ fullname: payload?.fullname, email: payload?.email, lastConnection: payload?.lastConnection, locked: false }} />,
      <DrawerDelivery key={"drawerDelivery"} delivery={selectedDelivery} open={open} handleOpen={handleOpen} handleAddOpen={handleAddOpen} roles={roles} />,
      <DraweraddressAddress key={"drawerAddressAddress"} address={selectedAddress} open={open} handleOpen={handleOpen} />,
      <DrawerInvoice key={"drawerInvoice"} invoice={selectedInvoice} handleOpen={handleOpen} roles={roles} />,
      <DrawerRemovale key={"drawerRemovale"} activeStep={activeStep} />,
      <DrawerSettings key={"drawerSettings"} user={{ fullname: payload?.fullname, email: payload?.email, lastConnection: payload?.lastConnection, locked: false }} payload={payload} />
    ];

    return (
      <>
        <Router>
          <NavBar indexNavBar={indexNavBar} roles={roles} hideElement={hideElement} />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Routes>
              <Route path="/*" element={
                <Home
                  indexNavBar={drawerTitleList.indexOf(t("Utilisateur"))}
                  setIndexNavBar={setIndexNavBar}
                  handleHideElement={handleHideElement}
                  payload={payload}
                />} />
              <Route path="/Delivery/*" element={
                <Delivery
                  indexNavBar={drawerTitleList.indexOf(t("Livraison"))}
                  setIndexNavBar={setIndexNavBar}
                  selectedDelivery={selectedDelivery}
                  setSelectedDelivery={setSelectedDelivery}
                  open={open}
                  setOpen={setOpen}
                  roles={roles}
                  selfId={payload.me}
                  handleHideElement={handleHideElement}
                  addOpen={addOpen}
                  setAddOpen={setAddOpen}
                  reduced={false}
                />} />
              <Route path="/Clients/*" element={
                <ClientAddress
                  indexNavBar={drawerTitleList.indexOf(t("Client"))}
                  setIndexNavBar={setIndexNavBar}
                  selectedAddress={selectedAddress}
                  setSelectedAddress={setSelectedAddress}
                  payload={payload}
                  open={open}
                  setOpen={setOpen}
                  handleHideElement={handleHideElement}
                />} />
              <Route path="/Invoice/*" element={
                <Invoice
                  indexNavBar={drawerTitleList.indexOf(t("Facture"))}
                  setIndexNavBar={setIndexNavBar}
                  setSelectedInvoice={setSelectedInvoice}
                  selectedInvoice={selectedInvoice}
                  setInvoiceToShow={setInvoiceToShow}
                  open={open}
                  setOpen={setOpen}
                  handleHideElement={handleHideElement}
                  roles={roles}
                  payload={payload}
                />} />
              <Route path="/Removale/*" element={
                <Removale
                  indexNavBar={drawerTitleList.indexOf(t("Étapes"))}
                  setIndexNavBar={setIndexNavBar}
                  setActiveStep={setActiveStep}
                  activeStep={activeStep}
                  payload={payload}
                  handleHideElement={handleHideElement}
                />} />
              <Route path="/Settings/*" element={
                <Settings
                  index={drawerTitleList.indexOf(t("MonCompte"))}
                  indexNavBar={drawerTitleList.indexOf(t("MonCompte"))}
                  payload={payload}
                  setIndexNavBar={setIndexNavBar}
                  selectedAddress={selectedAddress}
                  setSelectedAddress={setSelectedAddress}
                  open={open}
                  setOpen={setOpen}
                  handleHideElement={handleHideElement}
                />} />
            </Routes>
            <Drawer
              hide={hideElement}
              title={drawerTitleList[indexNavBar]}
              content={drawerContentList[indexNavBar]}
            />
          </div>
        </Router>
      </>
    );
  } else {
    return (
      <></>
    )
  }
}

export default App;
