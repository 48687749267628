// import { useDispatch } from "react-redux";
import { logout } from "shared/redux/features/auth/authSlice";

// const dispatch = useDispatch();
export const ErrorHandler = (err: any, setErrors: Function, setIsLoading?: Function) => {
    if (setIsLoading) setIsLoading(false);
    if (
        err?.statusCode === 403 ||
        err?.statusCode === 401 ||
        err?.statusCode === 400 ||
        err?.response?.status === 403 ||
        err?.response?.status === 401 ||
        err?.response?.status === 400
    ) {
        // dispatch(
            logout()
        // );
    } else {
        setErrors({
            type: 'error',
            content: err?.response?.data?.message
        })
    }
}