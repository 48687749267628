import React, { useState } from "react";
import LoginPicture from '../../assets/LoginPicture.jpeg';
import { useNavigate, useParams } from 'react-router-dom';
import Postex from '../../assets/postexLogo.png';
import { useDispatch } from "react-redux";
import { MyContainedButton } from '../../MyComponents/Generic/MyButton';
import { MyLoginTextField } from '../../MyComponents/TextField/MyTextField';
import { login } from "shared/redux/features/auth/authSlice";
import { CircularProgress } from "@material-ui/core";
import { IAuthData } from "types/authdata";
import Collapse from '@mui/material/Collapse';
import { Alert } from "@mui/material";
import { IMessage } from "pages/Clients/type/Message";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";

function Login() {

  const { deliveryId } = useParams();

  const [authData, setAuthData] = useState<IAuthData>({ login: "", password: "" });
  
  const [errors, setErrors] = useState<IMessage>({ type: 'error', content: '' });

  const [openAlert, setOpenAlert] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (event: any) => {
    setAuthData({
      ...authData,
      [event.target.name]: event.target.value
    });
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    dispatch(login(authData))
      .unwrap()
      .then(() => {
        let redirectUrl = "/";
        if (deliveryId != null) {
          redirectUrl = "/Delivery/" + deliveryId
        }
        navigate(redirectUrl);
        window.location.reload();
      })
      .catch((error: any) => {
        setOpenAlert(true);
        setIsLoading(false);
        setAuthData({
          ...authData,
          ['login']: '',
          ['password']: '',
        });
        if (error?.error?.statusCode === 403 || error?.error?.statusCode === 401
          || error?.error?.response?.status === 403 || error?.error?.response?.status === 401
        ) {
          setErrors({type:'error', content:error?.error?.response?.data ? error?.error?.response?.data.message : t("connectionRefused")});
        } else {
          setErrors({type: 'error', content:t("errorPwd")})
        }
      });
  }


  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#F7A007', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '55vh', height: '70vh', backgroundColor: '#F2F2F2', borderRadius: '24px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', textAlign: 'center', alignItems: 'center', justifyContent: 'space-around' }}>
          <img src={Postex} alt='Picture' style={{ height: '25vh', marginTop: '4vh' }} />
          <form onSubmit={handleSubmit}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <MyLoginTextField
                type='text'
                name='login'
                label={t("login")}
                value={authData?.login}
                onChange={handleChange}
              />
              <MyLoginTextField
                name='password'
                type='password'
                label={t('password')}
                value={authData?.password}
                onChange={handleChange}
              />
            </div>
            {isLoading ?
              <CircularProgress />
              :
              <MyContainedButton onClick={handleSubmit}>{t("Connection")}</MyContainedButton>
            }
          </form>
          <Collapse in={openAlert}>
              {(errors?.type) &&
                <Alert severity={errors.type}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenAlert(false);
                        setErrors({ type: 'error', content: '' });
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {errors?.content || ''}
                </Alert>
              }
            </Collapse>
        </div>
      </div>
      <img src={LoginPicture} alt='Picture' style={{ height: '100vh' }} />
    </div>
  );
}

export default Login;