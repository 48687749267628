import { MyContainedButton, MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import { MyTextField } from 'MyComponents/TextField/MyTextField';
import { MouseEventHandler } from 'react';
import MyTitle from '../../../MyComponents/Title/MyTitle';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
import InfoIcon from '@mui/icons-material/Info';
import { IDeliveryCreator, IDeliveryStepCreator } from 'pages/Delivery/type/Delivery/DeliveryCreator';
import { useTranslation } from "react-i18next";
import { IOptions } from '../types/Options';
import { Tooltip } from "@mui/material";
import { IFavoriteAddress } from 'pages/Addresses/type/FavoriteAddress';

import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import PanoramaVerticalSelectIcon from '@mui/icons-material/PanoramaVerticalSelect';
import FlagIcon from '@mui/icons-material/Flag';

import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

function ShippingRecapInformation(props: {
    inputs: IDeliveryCreator,
    myOptions: Array<IOptions>,
    destinationOptions: Array<IOptions>,
    handleSubmit: MouseEventHandler<HTMLButtonElement>,
    setActiveStep: Function,
    deliverySteps: Array<IDeliveryStepCreator>,
}) {

    const {
        inputs,
        myOptions,
        destinationOptions,
        handleSubmit,
        setActiveStep,
        deliverySteps,
    } = props;

    const { t } = useTranslation();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <MyTitle>
                {t("recapShippingTitle")}
            </MyTitle>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center', width: '90%', border: '3px solid #f5c065', borderRadius: '24px', padding: '2%' }}>
                <MyModalSubTitle sx={{ marginTop: '5px' }}>
                    <InfoIcon sx={{ color: '#009CFF', fontSize: '30px' }} />
                    {t("informations")}
                </MyModalSubTitle>
                <Grid container spacing={0} columns={{ xs: 3, md: 12 }}>
                    <Grid item xs={4}>
                        <MyTextField disabled={true} type='text' name="name" value={inputs.type} label="Type" />
                    </Grid>
                    <Grid item xs={3}>
                        <MyTextField disabled={true} type='date' name="date" value={inputs.datetime} label="Date" />
                    </Grid>
                    <Grid item xs={5}>
                        <MyTextField disabled={true} type='text' name="remark" label={t("remark")} />
                    </Grid>
                </Grid>
                <MyModalSubTitle sx={{ marginTop: '5px' }}>
                    <LocationOnIcon sx={{ color: '#009CFF', fontSize: '40px', marginTp: '30px' }} />
                    {t("journey")}
                </MyModalSubTitle>
                <Grid container spacing={1} columns={{ xs: 4, md: 12 }}>
                    <Grid item xs={1}>
                        <EmojiFlagsIcon sx={{ color: '#009CFF', fontSize: '40px', marginTop: '10px' }} />
                    </Grid>
                    <Grid item xs={8}>
                        <MyTextField disabled={true} type='text' name="favAddressFromId" value={(myOptions?.find(({ value }) => value === inputs.favAddressFromId))?.name} label={t("addressFrom")} />
                    </Grid>
                    <Grid item xs={3}>
                        <MyTextField disabled={true} type='text' name="clientId" value={(myOptions?.find(({ value }) => value === inputs.favAddressFromId))?.fullname} label={t("sender")} />
                    </Grid>
                    <Grid item xs={12}>
                        <List
                            sx={{
                                overflow: 'auto',
                                maxHeight: 150,
                                '& ul': { padding: 0 },
                            }}
                            subheader={<li />}>
                            {deliverySteps.map((deliveryStep: IDeliveryStepCreator) => {
                                return (
                                    <li key={deliveryStep.step}>
                                        <ul>
                                            <ListItemText >
                                                <Grid container spacing={1} columns={{ xs: 7, md: 12 }}>
                                                    <Grid item xs={1}>
                                                        <Tooltip title={(deliveryStep?.step != null ? deliveryStep?.step + 1 : "")} placement='top'>
                                                            <PanoramaVerticalSelectIcon sx={{ color: '#009CFF', fontSize: '25px', marginTop: '30px' }} />
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <MyTextField
                                                            disabled={true}
                                                            type='text'
                                                            name="favAddressId"
                                                            value={(myOptions?.find(({ value }) => value === deliveryStep.favAddressId))?.name}
                                                            label={t("stepAddresses") + (deliveryStep?.step != null ? deliveryStep?.step + 1 : "")} />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <MyTextField
                                                            disabled={true}
                                                            type='text'
                                                            name="clientId"
                                                            value={(myOptions?.find(({ value }) => value === deliveryStep.favAddressId))?.fullname}
                                                            label={t("receiverSTep") + (deliveryStep?.step != null ? deliveryStep?.step + 1 : "")} />
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        </ul>
                                    </li>
                                )
                            })}
                        </List>
                    </Grid>
                    <Grid item xs={1}>
                        <FlagIcon sx={{ color: '#009CFF', fontSize: '40px', marginTop: '10px' }} />
                    </Grid>
                    <Grid item xs={8}>
                        <MyTextField disabled={true} type='text' name="favAddressToId" value={(destinationOptions?.find(({ value }) => value === inputs.favAddressToId))?.name} label={t("addressReceiver")} />
                    </Grid>
                    <Grid item xs={3}>
                        <MyTextField disabled={true} type='text' name="clientId" value={(destinationOptions?.find(({ value }) => value === inputs.favAddressToId))?.fullname} label={t("receiver")} />
                    </Grid>
                </Grid>

            </div>
            <div>
                <MyOutlinedButton onClick={() => setActiveStep(2)}>{t("<back")}</MyOutlinedButton>
                <MyContainedButton onClick={handleSubmit}>{t("finish")}</MyContainedButton>
            </div>
        </div>
    )
}

export default ShippingRecapInformation;