import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import Grid from '@mui/material/Grid';
import { MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import { MyTextField } from 'MyComponents/TextField/MyTextField';
import { useState } from 'react';

import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
import { useTranslation } from "react-i18next";
import { CustomModals } from "shared/modals/customModals";
import { IMessage } from '../type/Message';

export function ViewClient(props: { open: any, setOpen: any, client: any }) {

    const { open, setOpen, client } = props;
    const { t } = useTranslation();

    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState<IMessage>({ type: 'error', content: '' });

    const handleClose = () => {
        setOpen(false);
    }

    const content =
        <>
            <MyModalSubTitle>
                <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                {t("personnalInfo")}
            </MyModalSubTitle>
            <Grid container spacing={1} columns={{ xs: 4, md: 12 }} >
                <Grid item xs={4}>
                    <MyTextField type='text' disabled={true} name="firstname" label={t("firstName")} defaultValue={client?.user?.firstname} />
                </Grid>
                <Grid item xs={4}>
                    <MyTextField type='text' disabled={true} name="lastname" label={t("lastName")} defaultValue={client?.user?.lastname} />
                </Grid>
                <Grid item xs={4}>
                    <MyTextField type='text' disabled={true} name="company" label={t("ticompanytle")} defaultValue={client?.company} />
                </Grid>
                <Grid item xs={6}>
                    <MyTextField type='tel' disabled={true} name="phone" pattern="[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}" label={t("phone")} placeholder={"xx xx xx xx xx"} defaultValue={client?.phone} />
                </Grid>
                <Grid item xs={6}>
                    <MyTextField type='text' disabled={true} name="vatNumber" label={t("tva")} size={{ width: '27.3vh' }} defaultValue={client?.vatNumber} />
                </Grid>
                <Grid item xs={6}>
                    <MyTextField type='text' disabled={true} name="email" label='Email' defaultValue={client?.email} />
                </Grid>
                <Grid item xs={6}>
                    <MyTextField type='text' disabled={true} name="emailFacturation" label={t("emailFacturation")} defaultValue={client?.emailFacturation} />
                </Grid>
            </Grid>
            <MyModalSubTitle>
                <LocationOnIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                {t("address")}
            </MyModalSubTitle>

            <Grid container spacing={1} columns={{ xs: 3, md: 12 }} >
                <Grid item xs={6}>
                    <MyTextField type='text' disabled={true} name="street" label={t("street")} size={{ width: '46vh' }} defaultValue={client?.address[0]?.address?.street} />
                </Grid>
                <Grid item xs={3}>
                    <MyTextField type='text' disabled={true} name="city" label={t("city")} size={{ width: '46vh' }} defaultValue={client?.address[0]?.address?.city} />
                </Grid>
                <Grid item xs={3}>
                    <MyTextField type='text' disabled={true} name="zipcode" label={t("zipcode")} size={{ width: '46vh' }} defaultValue={client?.address[0]?.address?.zipcode} />
                </Grid>
                <Grid item xs={8}>
                    <MyTextField type='text' disabled={true} name="complement" label={t("complement")} size={{ width: '46vh' }} defaultValue={client?.address[0]?.address?.complement} />
                </Grid>
                <Grid item xs={4}>
                    <MyTextField type='text' disabled={true} name="country" label={t("country")} size={{ width: '46vh' }} defaultValue={client?.address[0]?.address?.country} />
                </Grid>
                <Grid item xs={12}>
                    <MyTextField type='text' disabled={true} name="label" label={t("label")} size={{ width: '46vh' }} defaultValue={client?.address[0]?.label} />
                </Grid>
            </Grid>
        </>

    const actions =
        <>
            <MyOutlinedButton onClick={handleClose}>{t("quit")}</MyOutlinedButton>
        </>

    return (
        <CustomModals
            open={open}
            handleClose={handleClose}
            title={t("client")}
            content={content}
            actions={actions}
            openAlert={openAlert}
            setOpenAlert={setOpenAlert}
            message={message}
            setMessage={setMessage}
        />
    )
}