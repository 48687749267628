import React, { useEffect, useState } from 'react';
import MyTitle from '../../MyComponents/Title/MyTitle';
import Picture from "../../assets/HomePicture.png";
import Grid from '@mui/material/Grid';
import SettingsModal from 'pages/Settings/modal/SettingsModal';
import { IPayload } from 'types/payload';
import { useTranslation } from "react-i18next";

function Home(props: {
  indexNavBar: any,
  setIndexNavBar: any,
  handleHideElement: any,
  payload: IPayload
}) {

  const { indexNavBar, setIndexNavBar, handleHideElement, payload } = props;

  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    handleHideElement(false);
    setIndexNavBar(0); //set color of current page button on navbar
    if (payload.isFirst) {
      setOpen(true)
    }
  }, []);


  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 86.5px)', width: '100%' }}>
      <MyTitle>{t("home")}</MyTitle>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '10vh', textAlign: 'center' }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <img src={Picture} alt="Picture" style={{ borderRadius: '24px', width: '55%', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }} />
          </Grid>
        </Grid>
      </div>
      {payload != null &&
        <SettingsModal
          payload={payload}
          open={open}
          setOpen={setOpen}
        />
      }
    </div>

  );
}

export default Home;