import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { MyAccordion } from 'MyComponents/Generic/MyAccordion';
import MyStatusSelect from 'MyComponents/Status/MyStatusSelect';
import { MyTextField, MyTextFieldSelect } from 'MyComponents/TextField/MyTextField';
import { MouseEventHandler } from 'react';
import { useTranslation } from "react-i18next";
import { IType } from './type/Type';

export default function SearchDelivery(props: {
    input: any,
    isMobile: boolean,
    setInput: Function,
    setExpanded: Function,
    expanded: string | false,
    typeOptions: IType | undefined,
    resetInput: MouseEventHandler<HTMLButtonElement>,
}) {
    const { t } = useTranslation();
    const {
        input,
        isMobile,
        expanded,
        setInput,
        resetInput,
        setExpanded,
        typeOptions
    } = props;

    const handleChange = (event: any) => {
        const name = event.target.name;
        const value = event.target.value;
        setInput((values: any) => ({ ...values, [name]: value }));
    }

    return (
        <>
            {
                isMobile ?
                    (<MyAccordion
                        id={t("expandToSeeMore") + t("3dot")}
                        summary={
                            < MyTextField type='text' value={input.research} name="research" label="N°" onChange={handleChange} placeholder={t("deliveryNumber") + t("3dot")
                            }
                                customStyle={{
                                    border: "3.5px solid #F5C065",
                                    borderRadius: "8px",
                                    height: '30px',
                                    fontSize: '16px'
                                }}
                            />
                        }
                        description={t("expandToSeeMore") + t("3dot")}
                        content={
                            <>
                                <MyTextField type='date' value={input.dateStart} name="dateStart" label={t("startDate")} onChange={handleChange} placeholder={"Date" + t("3dot")}
                                    customStyle={{
                                        border: "3.5px solid #F5C065",
                                        borderRadius: "8px",
                                        height: '30px',
                                        fontSize: '16px',
                                    }}
                                />
                                <MyTextField type='date' value={input.dateEnd} name="dateEnd" label={t("endDate")} onChange={handleChange} placeholder={"Date" + t("3dot")}
                                    customStyle={{
                                        border: "3.5px solid #F5C065",
                                        borderRadius: "8px",
                                        height: '30px',
                                        fontSize: '16px',
                                    }}
                                />
                                {typeOptions != null &&
                                    <MyTextFieldSelect options={typeOptions} value={input.type} name="type" label="Type" onChange={handleChange}
                                        customStyle={{
                                            backgroundColor: 'white',
                                            border: "3.5px solid #F5C065",
                                            borderRadius: "8px",
                                            height: '30px',
                                            fontSize: '16px',
                                        }} />
                                }
                                <MyStatusSelect
                                    input={input}
                                    setInput={setInput}
                                />
                                <IconButton onClick={resetInput}>
                                    <FilterAltOffIcon sx={{ color: '#F5C065', fontSize: '40px', marginTop: '20px' }} />
                                </IconButton>
                            </>
                        }
                        expanded={expanded}
                        setExpanded={setExpanded}
                    />)
                    :
                    (
                        <Grid container spacing={1} columns={{ xs: 3, sm: 7, md: 12, }}>
                            <Grid item xs={3}>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <SearchIcon sx={{ color: '#F5C065', fontSize: '40px', marginTop: '20px' }} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <MyTextField type='text' value={input.research} name="research" label="N°" onChange={handleChange} placeholder={t("deliveryNumber") + t("3dot")}
                                            customStyle={{
                                                border: "3.5px solid #F5C065",
                                                borderRadius: "8px",
                                                height: '30px',
                                                fontSize: '16px',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <MyTextField type='date' value={input.dateStart} name="dateStart" label={t("startDate")} onChange={handleChange} placeholder={"Date" + t("3dot")}
                                    customStyle={{
                                        border: "3.5px solid #F5C065",
                                        borderRadius: "8px",
                                        height: '30px',
                                        fontSize: '16px',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <MyTextField type='date' value={input.dateEnd} name="dateEnd" label={t("endDate")} onChange={handleChange} placeholder={"Date" + t("3dot")}
                                    customStyle={{
                                        border: "3.5px solid #F5C065",
                                        borderRadius: "8px",
                                        height: '30px',
                                        fontSize: '16px',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                {typeOptions != null ?
                                    (
                                        <MyTextFieldSelect options={typeOptions} value={input.type} name="type" label="Type" onChange={handleChange}
                                            customStyle={{
                                                backgroundColor: 'white',
                                                border: "3.5px solid #F5C065",
                                                borderRadius: "8px",
                                                height: '30px',
                                                fontSize: '16px',
                                            }} />
                                    )
                                    :
                                    (<MyTextFieldSelect options={[]} value={null} name="type" label="Type" onChange={handleChange}
                                        customStyle={{
                                            backgroundColor: 'white',
                                            border: "3.5px solid #F5C065",
                                            borderRadius: "8px",
                                            height: '30px',
                                            fontSize: '16px',
                                        }} />)
                                }
                            </Grid>
                            <Grid item xs={2}>
                                <Grid container spacing={0}>
                                    <Grid item xs={10}>
                                        <MyStatusSelect input={input} setInput={setInput} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Tooltip title={t("erase")} placement='top'>
                                            <IconButton onClick={resetInput}>
                                                <FilterAltOffIcon sx={{ color: '#F5C065', fontSize: '40px', marginTop: '20px' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
            }
        </>
    )
}