import instance from "shared/auth/service/AxiosInstance";
import { IDelivery } from "../type/Delivery/Delivery";
import { IDeliveryCreator } from "../type/Delivery/DeliveryCreator";
import { IDeliveryStep } from "../type/Delivery/DeliveryData";

const API_URL: string = (process.env.REACT_APP_API_URL + "deliveries" );

const deliveryService = {

    getAll: (pageNo: number, pageSize: number, research: string | null | undefined) =>
        instance.get(API_URL + "/?pageSize=" + pageSize + "&pageNo=" + pageNo + "&research=" + research),

    create: (delivery: IDelivery) =>
        instance.post(API_URL + "/", delivery),

    update: (id: string, delivery: IDelivery) =>
        instance.put(API_URL + "/" + id, delivery),

    validateDeliverySteps : (deliveryId : string, deliveryStepsId : string, deliveySteps : IDeliveryStep) => 
        instance.put(API_URL + '/'+ deliveryId  + '/deliverySteps/' + deliveryStepsId, deliveySteps),
 
    getAllDeliveries: (pageNo: number, pageSize: number, input:any) =>
        instance.get(API_URL + "/?pageSize=" + pageSize + "&pageNo=" + pageNo + "&research=" + input.research + "&status=" + input.status + "&dateStart=" + input.dateStart + "&dateEnd=" + input.dateEnd + "&type=" + input.type),

    getAllDeliveriesClient: (pageNo: number, pageSize: number, userId: string,  input:any, billed:boolean) =>
        instance.get(API_URL + "/client/" + userId + "/?pageSize=" + pageSize + "&pageNo=" + pageNo + "&research=" + input.research + "&status=" + input.status + "&dateStart=" + input.dateStart + "&dateEnd=" + input.dateEnd + "&type=" + input.type + "&billed="+billed),

    getAllDeliveriesCarrier: (pageNo: number, pageSize: number, userId: string, input:any) =>
        instance.get(API_URL + "/carrier/" + userId + "/?pageSize=" + pageSize + "&pageNo=" + pageNo + "&research=" + input.research + "&status=" + input.status + "&dateStart=" + input.dateStart + "&dateEnd=" + input.dateEnd + "&type=" + input.type),

    createDelivery: (delivery: IDeliveryCreator) =>
        instance.post(API_URL + "/", delivery),

    updateDelivery: (id: string, delivery: IDeliveryCreator) =>
        instance.put(API_URL + "/" + id, delivery),

    addingDeliveryCarrier: (id: string | null | undefined, delivery: IDeliveryCreator) =>
        instance.put(API_URL + "/carrier/" + id, delivery),

    acceptDelivery: (id: string) =>
        instance.get(API_URL + "/accept/" + id),

    startDelivery: (id: string, delivery: IDeliveryCreator) =>
        instance.put(API_URL + "/start/" + id, delivery),

    endDelivery: (id: string, delivery: IDeliveryCreator) =>
        instance.put(API_URL + "/end/" + id, delivery),

    getDelivery: (id: string) =>
        instance.get(API_URL + "/" + id),

    deleteDelivery: (id: string) =>
        instance.delete(API_URL + "/" + id),

}

export default deliveryService;