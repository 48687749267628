import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { logout } from 'shared/redux/features/auth/authSlice';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import addressService from './service/AddressService';
import Tooltip from '@mui/material/Tooltip';
import { IconButton, Typography } from '@mui/material';
import { EditAddress } from './modals/EditAddress';
import { AddAddress } from './modals/AddAddress';
import { IPayload } from 'types/payload';
import { IFavoriteAddress } from './type/FavoriteAddress';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteModals } from 'shared/modals/DeleteModals';
import { IMessage } from 'pages/Clients/type/Message';
import EditIcon from '@mui/icons-material/Edit';
import { MyAccordion } from 'MyComponents/Generic/MyAccordion';

import { useTranslation } from "react-i18next";
import SearchAddress from './SearchAddress';
import MyDataGrid from 'MyComponents/DataGrid/MyDataGrid';

function Address(props: {
  indexNavBar: any,
  setIndexNavBar: any,
  selectedAddress: any,
  setSelectedAddress: any,
  open: any,
  setOpen: any,
  payload: IPayload;
  handleHideElement: any
}) {

  const {
    setIndexNavBar,
    selectedAddress,
    setSelectedAddress,
    payload,
    handleHideElement
  } = props;


  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [input, setInput] = useState("");

  const [addOpen, setAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState<IMessage>({ type: 'error', content: '' });

  const [isLoading, setIsLoading] = useState(false);
  const [addresses, setAddresses] = useState<Array<IFavoriteAddress>>();
  const [rowCountState, setRowCountState] = useState(0);
  const [defaultPageNo, setDefaultPageNo] = useState(0);
  const [defaultPageSize, setDefaultPageSize] = useState(40);
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 40,
  });

  const [errors, setErrors] = useState();

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 897) || false;

  const handleResize = () => {
    setIsMobile(window.innerWidth < 897);
  }

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      id: false,
      street: !isMobile,
      city: !isMobile,
      zipcode: !isMobile,
      country: !isMobile,
      complement: !isMobile,
      label: !isMobile,
      actions: true,
      address: isMobile,
    });

  const [expanded, setExpanded] = useState<string | false>(false);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Id",
      hideable: true,
      flex: 1,
      headerClassName: "researchColumn",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "street",
      headerName: t("street") || "",
      hideable: true,
      flex: 1,
      headerClassName: "researchColumn",
      headerAlign: "center",
      align: "center",
      renderCell: (data) => data?.row?.address?.street
    },
    {
      field: "city",
      headerName: t("city") || "",
      hideable: true,
      flex: 1,
      headerClassName: "researchColumn",
      headerAlign: "center",
      align: "center",
      renderCell: (data) => data?.row?.address?.city
    },
    {
      field: "zipcode",
      headerName: t("zipcode") || "",
      hideable: true,
      flex: 1,
      headerClassName: "researchColumn",
      headerAlign: "center",
      align: "center",
      renderCell: (data) => data?.row?.address?.zipcode
    },
    {
      field: "country",
      headerName: t("country") || "",
      hideable: true,
      flex: 1,
      headerClassName: "researchColumn",
      headerAlign: "center",
      align: "center",
      renderCell: (data) => data?.row?.address?.country
    },
    {
      field: "complement",
      headerName: t("complement") || "",
      hideable: true,
      flex: 1,
      headerClassName: "researchColumn",
      headerAlign: "center",
      align: "center",
      renderCell: (data) => data?.row?.address?.complement
    },
    {
      field: "label",
      headerName: t("label") || "",
      hideable: true,
      flex: 1,
      headerClassName: "researchColumn",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      headerName: "Actions",
      hideable: true,
      flex: 1,
      headerClassName: "researchColumn",
      headerAlign: "center",
      align: "center",
      renderCell: (data) => {
        return (
          <>
            <Tooltip title={t("update")} placement='top'>
              <IconButton onClick={handleEditOpen}>
                <EditIcon sx={{ color: '#009CFF' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("delete")} placement='top'>
              <IconButton onClick={handleDeleteOpen}>
                <DeleteIcon sx={{ color: '#FA6666' }} />
              </IconButton>
            </Tooltip>
          </>
        )
      }
    },
    {
      field: "address",
      headerName: t("address") || "",
      flex: 4,
      headerClassName: "researchColumn",
      headerAlign: "center",
      align: "left",
      renderCell: (data) => {
        return (
          <MyAccordion
            id={`${data.row?.id}`}
            summary={`${data.row?.label}`}
            description={`${data.row?.fullname}`}
            content={
              <>
                <Typography sx={{ fontSize: 13 }}>{`${data.row?.address?.complement || ''}`}</Typography>
                <Typography sx={{ fontSize: 13 }}>{`${data.row?.address?.street}`}</Typography>
                <Typography sx={{ fontSize: 13 }}>{`${data.row?.address?.zipcode} ${data.row?.address?.city}`}</Typography>
                <Typography sx={{ fontSize: 13 }}>{`${data.row?.address?.country}`}</Typography>
                <Typography sx={{ fontSize: 13 }}>{`Tél.: ${data.row?.phone}`}</Typography>
              </>
            }
            expanded={expanded}
            setExpanded={setExpanded}
          />
        )
      }
    }
  ];

  const handleChange = (event: any) => {
    setInput(event.target.value);
  }

  const resetInput = () => {
    setInput("");
  }

  const handlePageChange = (page: any) => {
    setDefaultPageNo(page);
    setRowsState((prev) => ({ ...prev, page }));
    refresh({ pageNo: page, pageSize: defaultPageSize }).catch((err) => handleError(err));
  }

  const handlePageSizeChange = (pageSize: any) => {
    setDefaultPageSize(pageSize);
    setRowsState((prev) => ({ ...prev, pageSize }));
    refresh({ pageNo: defaultPageNo, pageSize: pageSize }).catch((err) => handleError(err));
  }

  const handleSelectionModelChange = (ids: any) => {
    const selectedIDs = new Set(ids);
    addresses?.filter((address: any) => {
      if (selectedIDs.has(address.id)) {
        setSelectedAddress(address)
      }
    });
  }

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  }

  const handleEditOpen = () => {
    setEditOpen(true);
  }

  const handleAddOpen = () => {
    setAddOpen(true);
  }

  const handleError = (err: any) => {
    setIsLoading(false)
    if (
      err?.statusCode === 403 ||
      err?.statusCode === 401 ||
      err?.statusCode === 400 ||
      err?.response?.status === 403 ||
      err?.response?.status === 401 ||
      err?.response?.status === 400
    ) {
      dispatch(
        logout()
      );
    } else {
      setErrors(err?.response?.data?.error)
    }
  }

  const refresh = (
    { pageNo, pageSize } = {
      pageNo: rowsState.page,
      pageSize: rowsState.pageSize,
    }
  ) => {
    setIsLoading(true)
    return addressService.getAllAddressHome(payload?.me, pageNo, pageSize, input).then(
      (response: any) => {
        const data = response.data.content;
        setAddresses(
          data.map((row: IFavoriteAddress) => {
            const { favoriteAddressId, ...rest } = row;
            return { id: favoriteAddressId, ...rest };
          })
        );
        setIsLoading(false)
        setRowCountState(response.data.totalElements);
      }
    ).catch((err) => handleError(err));
  };

  const handleClose = () => {
    setMessage({ type: 'error', content: '' });
    setOpenAlert(false);
    setDeleteOpen(false);
  }

  const handleSubmit = () => {
    setMessage({ type: 'info', content: t("Processing") });
    setOpenAlert(true);
    if (selectedAddress != null) {
      addressService.deleteAddress(selectedAddress?.id)
        .then(r => {
          refresh().catch(err => console.log(err))
          setMessage({
            type: "success",
            content: t("address") + t("deleteWithSucess")
          });
          setTimeout(() => {
            handleClose()
          }, 1500)
        })
        .catch((err) => {
          if (
            err?.statusCode === 403 ||
            err?.statusCode === 401 ||
            err?.response?.status === 403 ||
            err?.response?.status === 401
          ) {
            dispatch(logout());
          } else {
            setMessage({
              type: 'error',
              content: err?.response?.data?.message
            })
          }
        }
        );
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleHideElement(false);
    setIndexNavBar(3); //set color of current page button on navbar
    setSelectedAddress(null); //reset selected Address
    refresh({
      pageNo: defaultPageNo,
      pageSize: defaultPageSize
    }).catch((err) => handleError(err));
  }, []);


  useEffect(() => {
    const timeoutId = setTimeout(
      () => refresh({
        pageNo: defaultPageNo,
        pageSize: defaultPageSize
      }).catch(err => handleError(err)),
      300
    );
    return () => clearTimeout(timeoutId);
  }, [input])

  useEffect(() => {
    setColumnVisibilityModel({
      id: false,
      street: !isMobile,
      city: !isMobile,
      zipcode: !isMobile,
      country: !isMobile,
      complement: !isMobile,
      label: !isMobile,
      actions: true,
      address: isMobile,
    })
  }, [isMobile]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 86.5px)', width: '100%' }}>
      <SearchAddress
        handleChange={handleChange}
        input={input}
        resetInput={resetInput}
        handleAddOpen={handleAddOpen}
      />

      <AddAddress
        ishome={true}
        type={t("address")}
        open={addOpen}
        refresh={refresh}
        setOpen={setAddOpen}
        payload={payload}
      />

      <MyDataGrid
        errors={errors}
        columns={columns}
        rows={addresses}
        rowsState={rowsState}
        isLoading={isLoading}
        rowCountState={rowCountState}
        selectionModel={[]}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        handleSelectionModelChange={handleSelectionModelChange}
      />

      <EditAddress
        ishome={true}
        type={t("address")}
        open={editOpen}
        refresh={refresh}
        setOpen={setEditOpen}
        address={selectedAddress}
      />

      <DeleteModals
        open={deleteOpen}
        setOpen={setDeleteOpen}
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
        message={message}
        setMessage={setMessage}
        refresh={refresh}
        title={t("address")}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
      />
    </div>
  )
}

export default Address;