import SearchIcon from "@mui/icons-material/Search";
import { MyAddButton } from "../../MyComponents/Generic/MyButton";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { MyAccordion } from 'MyComponents/Generic/MyAccordion';
import { MyTextField } from 'MyComponents/TextField/MyTextField';
import { ChangeEventHandler, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import MyTitle from '../../MyComponents/Title/MyTitle';

export default function SearchCient(props: {
    reduced: boolean,
    input: any,
    handleChange: ChangeEventHandler<HTMLInputElement>,
    handleAddOpen: MouseEventHandler<HTMLButtonElement>,
    expanded: string | false,
    setExpanded: Function,
    isMobile: boolean,
    resetInput: MouseEventHandler<HTMLButtonElement>
}) {
    
    const { t } = useTranslation();
    const {
        reduced,
        input,
        handleChange,
        handleAddOpen,
        resetInput,
        expanded,
        setExpanded,
        isMobile
    } = props;

    return (
        <>
            <MyTitle>
                {t("navClient")}
            </MyTitle>
            {isMobile ?
                (
                    <MyAccordion
                        id={t("expandToSeeMore") + t("3dot")}
                        summary={
                            <MyTextField type='text' value={input} name="research" label="" onChange={handleChange} placeholder={t("research") + t("3dot")}
                                customStyle={{
                                    border: "3.5px solid #F5C065",
                                    borderRadius: "8px",
                                    height: '30px',
                                    width: '100%',
                                    fontSize: '16px',
                                }}
                            />
                        }
                        description={t("expandToSeeMore") + t("3dot")}
                        content={
                            <>
                                {!reduced &&
                                    <MyAddButton onClick={handleAddOpen}>{t("add")}</MyAddButton>
                                }
                                <Tooltip title={t("erase")} placement='top'>
                                    <IconButton onClick={resetInput}>
                                        <FilterAltOffIcon sx={{ color: '#F5C065', fontSize: '35px', }} />
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                        expanded={expanded}
                        setExpanded={setExpanded}
                    />
                )
                :
                (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '70%' }}>
                            <SearchIcon sx={{ color: '#F5C065', fontSize: '40px' }} />
                            <input type='text' value={input} name="research" onChange={handleChange} placeholder={t("research") + t("3dot")}
                                style={{
                                    border: "3.5px solid #F5C065",
                                    borderRadius: "8px",
                                    height: '30px',
                                    width: '100%',
                                    fontSize: '16px',
                                }}
                            />
                            <Tooltip title={t("erase")} placement='top'>
                                <IconButton onClick={resetInput}>
                                    <FilterAltOffIcon sx={{ color: '#F5C065', fontSize: '35px', }} />
                                </IconButton>
                            </Tooltip>
                            {!reduced &&
                                <MyAddButton onClick={handleAddOpen}>{t("add")}</MyAddButton>
                            }
                        </Box>
                    </Box>
                )
            }
        </>
    )
}