import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { MyEditButton } from '../../MyComponents/Generic/MyButton';
import { Link } from 'react-router-dom';
import { MyDrawerParagraph, MyDrawerRow } from '../../MyComponents/Generic/MyDrawer';
import Zoom from '@mui/material/Zoom';
import { useTranslation } from "react-i18next";

function DrawerHome(props: { user: any }) {

  const { user } = props;
  const { t } = useTranslation();

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
        <PersonIcon sx={{ fontSize: '120px', color: '#009CFF' }} />
        <Zoom in={true} style={{ transitionDelay: '500ms' }}>
        <div style={{
          borderRadius: '12px', backgroundColor: '#F2F2F2', display: 'flex', flexDirection: "column", width: '75%', padding: '4vh 3vh', justifyContent: 'space-between', textAlign: 'center', alignItems: 'center', boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)'
        }}>
          <div>
            <MyDrawerRow>
              <PersonIcon sx={{ marginRight: '2vh', color: '#009CFF', fontSize: '40px' }} />
              <MyDrawerParagraph>
                {user.fullname}
              </MyDrawerParagraph>
            </MyDrawerRow>
            <MyDrawerRow>
              <EmailOutlinedIcon sx={{ marginRight: '2vh', color: '#009CFF', fontSize: '40px' }} />
              <MyDrawerParagraph>
                {user?.email}
              </MyDrawerParagraph>
            </MyDrawerRow>
            <MyDrawerRow>
              <CalendarTodayIcon sx={{ marginRight: '2vh', color: '#009CFF', fontSize: '40px' }} />
              {t("lastCo") + " : " + user?.lastConnection?.slice(0, 10)}
            </MyDrawerRow>
          </div>
        </div>
        </Zoom>
      </div>
    </>
  );
}

export default DrawerHome;