import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { MyEditButton } from '../../MyComponents/Generic/MyButton';
import { IPayload } from 'types/payload';
import SettingsModal from './modal/SettingsModal';
import { useState } from 'react';
import Zoom from '@mui/material/Zoom';
import { useTranslation } from "react-i18next";

function DrawerSettings(props: {
    user: any,
    payload: IPayload
}) {

    const { user, payload } = props

    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const handleAddOpen = () => {
        setOpen(true);
    }
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
                <PersonIcon sx={{ fontSize: '120px', color: '#009CFF' }} />
                <Zoom in={true} style={{ transitionDelay: '500ms' }}>
                    <div style={{
                        borderRadius: '12px', backgroundColor: '#F2F2F2', display: 'flex', flexDirection: "column", width: '75%', padding: '4vh 3vh', justifyContent: 'space-between', textAlign: 'center', alignItems: 'center', boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)'
                    }}>
                        <div>
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'center', textAlign: 'left' }}>
                                <PersonIcon sx={{ marginRight: '2vh', color: '#009CFF', fontSize: '40px' }} />
                                {user.fullname}
                            </div>
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'center', textAlign: 'left', marginTop: '3vh' }}>
                                <EmailOutlinedIcon sx={{ marginRight: '2vh', color: '#009CFF', fontSize: '40px' }} />
                                {user?.email}
                            </div>
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'center', textAlign: 'left', marginTop: '3vh' }}>
                                <CalendarTodayIcon sx={{ marginRight: '2vh', color: '#009CFF', fontSize: '40px' }} />
                                {t("lastCo") + " : " + user?.lastConnection.slice(0, 10)}
                            </div>

                            <MyEditButton onClick={handleAddOpen}>{t("changePwd")}</MyEditButton>
                            {payload != null &&
                                <SettingsModal
                                    payload={payload}
                                    open={open}
                                    setOpen={setOpen}
                                />
                            }
                        </div>
                    </div>
                </Zoom>
            </div>
        </>
    );
}

export default DrawerSettings;