import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Alert, Box, Collapse, IconButton, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid";
import logService from "./service/LogService";
import { ILogData } from "./type/LogData";
import { logout } from "shared/redux/features/auth/authSlice";
import { getISODate, getISOTime, localeISODateTime } from "shared/utils/localeISODateTime";
import { MyAccordion } from "MyComponents/Generic/MyAccordion";
import { ExportDataGridToExcel } from "shared/export/ExportDataGridToExcel";
import LogValueModal from "./modal/LogValueModal";

import { useTranslation } from "react-i18next";
import SearchLogs from "./SearchLogs";
import MyDataGrid from "MyComponents/DataGrid/MyDataGrid";

const Logs = (props: {
  indexNavBar: any,
  setIndexNavBar: any,
  selectedLog: any,
  setSelectedLog: any,
  roles: Array<string>,
  open: any,
  setOpen: any,
  handleHideElement: any
}) => {

  const {
    indexNavBar,
    setIndexNavBar,
    setSelectedLog,
    roles,
    handleHideElement
  } = props;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
  const ROLE_ADMIN = process.env.REACT_APP_ROLE_ADMIN;

  const [inputs, setInputs] = useState<any | null>();
  const [logs, setLogs] = useState<Array<ILogData>>() || null;

  const [isLoading, setIsLoading] = useState(false);

  const [rowCountState, setRowCountState] = useState(0);
  const [defaultPageNo, setDefaultPageNo] = useState(0);
  const [defaultPageSize, setDefaultPageSize] = useState(40);
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 40,
  });

  const [errors, setErrors] = useState();
  const [message, setMessage] = useState("");
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 897) || false;

  const handleResize = () => {
    setIsMobile(window.innerWidth < 897);
  }

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      id: false,
      date: !isMobile,
      remoteEntity: !isMobile,
      remoteEntityId: !isMobile,
      remoteUserId: !isMobile,
      actionType: !isMobile,
      oldValue: !isMobile,
      newValue: !isMobile,
      information: isMobile,
    });

  const [expanded, setExpanded] = useState<string | false>(false);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "N°",
      hideable: true,
      flex: 1,
      headerClassName: "researchColumn",
      headerAlign: "center",
      align: "center",
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 150,
      align: "center",
      headerAlign: 'center',
      headerClassName: 'researchColumn',
      flex: 1,
      renderCell: (data) =>
        <>
          {`${getISODate(localeISODateTime(new Date(data.row?.date)))} ${getISOTime(localeISODateTime(new Date(data.row?.date)))}`}
        </>
    },
    {
      field: 'remoteEntity',
      headerName: t('entity') || "",
      align: "center",
      headerAlign: 'center',
      headerClassName: 'researchColumn',
      flex: 1
    },
    {
      field: 'remoteEntityId',
      headerName: t("entityId") || "",
      align: "center",
      headerAlign: 'center',
      headerClassName: 'researchColumn',
      flex: 1
    },
    {
      field: 'remoteUserId',
      headerName: t("Utilisateur") || "",
      align: "center",
      headerAlign: 'center',
      headerClassName: 'researchColumn',
      flex: 1
    },
    {
      field: 'actionType',
      headerName: 'Action',
      align: "center",
      headerAlign: 'center',
      headerClassName: 'researchColumn',
      flex: 1
    },
    {
      field: 'information',
      headerName: t("informations") || "",
      align: "center",
      headerAlign: 'center',
      headerClassName: 'researchColumn',
      flex: 4,
      renderCell: (data) => {
        return (
          <MyAccordion
            id={`${data.row?.id}`}
            summary={`${data.row?.date}`}
            description={`${data.row?.actionType}: ${data.row?.remoteEntity}[${data.row?.remoteEntityId}]`}
            content={
              <>
                <Typography sx={{ fontSize: 13, fontWeight: 700 }}>{t("Utilisateur")}: {`${data.row?.remoteUserId || ''}`}</Typography>
                <Typography sx={{ fontSize: 13, fontWeight: 700 }}>{t("oldValues")}</Typography>
                <Typography sx={{ fontSize: 13 }}>{`${data.row?.oldValue || ''}`}</Typography>
                <Typography sx={{ fontSize: 13, fontWeight: 700 }}>{t("oldValues")}</Typography>
                <Typography sx={{ fontSize: 13 }}>{`${data.row?.newValue || ''}`}</Typography>
              </>
            }
            expanded={expanded}
            setExpanded={setExpanded}
          />
        )
      }
    },
    {
      field: 'valeurs',
      headerName: t("historicVallue") || "",
      align: "center",
      headerAlign: 'center',
      headerClassName: 'researchColumn',
      flex: 1,
      renderCell: (params) =>
        <>
          {(params.row.oldValue || params.row.newValue) &&
            <LogValueModal
              oldValue={params.row.oldValue}
              newValue={params.row.newValue}
            />
          }
        </>
    },
  ];

  const handleError = (err: any) => {
    setIsLoading(false);
    if (
      err?.statusCode === 403 ||
      err?.statusCode === 401 ||
      err?.statusCode === 400 ||
      err?.response?.status === 403 ||
      err?.response?.status === 401 ||
      err?.response?.status === 400
    ) {
      dispatch(
        logout()
      );
    } else {
      setErrors(err?.response?.data?.error)
    }
  }

  const resetInput = () => {
    setInputs(null);
  }

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values: any) => ({ ...values, [name]: value }));
  }

  const handlePageChange = (page: any) => {
    setDefaultPageNo(page);
    setRowsState((prev) => ({ ...prev, page }));
    refresh({ pageNo: page, pageSize: defaultPageSize })?.catch(err => handleError(err));
  }

  const handlePageSizeChange = (pageSize: any) => {
    setDefaultPageSize(pageSize);
    setRowsState((prev) => ({ ...prev, pageSize }));
    refresh({ pageNo: defaultPageNo, pageSize: pageSize })?.catch(err => handleError(err));
  }

  const handleSelectionModelChange = (ids: any) => {
    const selectedIDs = new Set(ids);
    logs?.filter((log: any) => {
      if (selectedIDs.has(log.id)) {
        setSelectedLog(log)
      }
    });
  }

  const refresh = (
    { pageNo, pageSize } = {
      pageNo: rowsState.page,
      pageSize: rowsState.pageSize,
    }
  ) => {
    setIsLoading(true);
    if (roles.includes(ROLE_ADMIN!)) {
      return logService.getAll(pageNo, pageSize, { ...inputs, secret: SECRET_KEY }).then(
        (response: any) => {
          const data = response.data.content;
          setLogs(
            data.map((row: ILogData) => {
              const { id, ...rest } = row;
              return { id: id, ...rest };
            })
          );
          setIsLoading(false);
          setRowCountState(response.data.totalElements);
        }
      ).catch(err => handleError(err));
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleHideElement(false);
    setIndexNavBar(indexNavBar); //set color of current page button on navbar
    setSelectedLog(null); //reset selected log
    refresh({
      pageNo: defaultPageNo,
      pageSize: defaultPageSize
    })?.catch(err => handleError(err));
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(
      () => refresh({
        pageNo: defaultPageNo,
        pageSize: defaultPageSize
      })?.catch(err => handleError(err)),
      300
    );
    return () => clearTimeout(timeoutId);
  }, [inputs])


  useEffect(() => {
    setColumnVisibilityModel({
      id: false,
      date: !isMobile,
      remoteEntity: !isMobile,
      remoteEntityId: !isMobile,
      remoteUserId: !isMobile,
      actionType: !isMobile,
      oldValue: !isMobile,
      newValue: !isMobile,
      information: isMobile,
    })
  }, [isMobile]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 86.5px)', width: '100%' }}>

        <SearchLogs
          inputs={inputs}
          handleChange={handleChange}
          resetInput={resetInput}
        />

        <MyDataGrid
          errors={errors}
          columns={columns}
          rows={logs}
          rowsState={rowsState}
          isLoading={isLoading}
          rowCountState={rowCountState}
          selectionModel={[]}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          columnVisibilityModel={columnVisibilityModel}
          setColumnVisibilityModel={setColumnVisibilityModel}
          handleSelectionModelChange={handleSelectionModelChange}
        />

        {(logs !== null) &&
          <ExportDataGridToExcel
            headers={[
              ...columns.filter(column => column.field !== 'valeurs'),
              { field: 'oldValue', hideable: false },
              { field: 'newValue', hideable: false }
            ]}
            data={logs}
            filename={`logs_${localeISODateTime()}`}
            setMessage={setMessage}
          />
        }

        <Box sx={{ width: '95%', margin: '0 auto' }}>
          <Collapse in={logs && (logs?.length > 0) && (message.length > 0)}>
            {(message?.length > 0) &&
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() =>setMessage("")}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {message}
              </Alert>
            }
          </Collapse>
        </Box>
      </div>
    </>
  );
}

export default Logs;