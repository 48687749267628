import { useEffect, useState } from "react";

export default function SelectedStatus(props: {
    delivery: any
}) {

    const { delivery } = props
    const [indexStatus, setIndexStatus] = useState(5);

    const options = [
        { label: "Created", value: 'created', statusIndex: 0 },
        { label: "Pending", value: 'pending', statusIndex: 1 },
        { label: "Picking up", value: 'picking up', statusIndex: 2 },
        { label: "Delivering", value: 'delivering', statusIndex: 3 },
        { label: "Shipped", value: 'shipped', statusIndex: 4 }
    ]


    const styleStatusChip = [{
        backgroundColor: '#5c5a5a83',
        border: '3px solid #8b8b8b',
    }, {
        backgroundColor: '#e6c72b76',
        border: '2px solid #e6c72b',
    },
    {
        backgroundColor: '#ec952a7a',
        border: '3px solid #ec952a',
    },
    {
        backgroundColor: '#2a8eec6d',
        border: '3px solid #2A8FEC',
    },
    {
        backgroundColor: '#1fb43f78',
        border: '3px solid #1FB43F',
    },
    {
        backgroundColor: 'rgba(221, 221, 221, 0.3)',
        border: '3px solid rgb(221, 221, 221)',
    }];

    useEffect(() => {
        if (delivery != null && delivery != undefined) {
            let tmp = options.find(options => delivery?.statuses[0]?.status?.name === options.value);
            if (tmp != null && tmp != undefined) {
                setIndexStatus(tmp.statusIndex);
            }
        }
    }, [delivery]);

    return (<>
        {delivery != null &&
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                <div style={{ margin: '5vh 1vh', textAlign: 'center' }}>
                    <input
                        value={delivery?.statuses[0]?.status?.name}
                        disabled={true}
                        style={{
                            textAlign: 'center',
                            borderRadius: '4vh',
                            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
                            fontSize: '26px',
                            ...styleStatusChip[indexStatus]
                        }}
                    />
                </div>
            </div>
        }
    </>
    )
}