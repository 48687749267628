import { MyContainedButton, MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import { MySelectTextField, MyTextField } from 'MyComponents/TextField/MyTextField';
import React, { useEffect } from 'react';
import { IPayload } from 'types/payload';
import { logout } from 'shared/redux/features/auth/authSlice';
import { CustomModals } from "shared/modals/customModals";
import { useState } from 'react';
import { useDispatch } from "react-redux";
import MyTitle from 'MyComponents/Title/MyTitle';
import { IPassword } from '../type/Password';
import Grid from '@mui/material/Grid';
import SettingService from '../service/SettingService';
import { useTranslation } from "react-i18next";

function SettingsModal(props: {
  payload: IPayload
  open: boolean,
  setOpen: any
}) {

  const { payload, open, setOpen } = props;
  const { t } = useTranslation();

  const [inputs, setInputs] = useState<IPassword>({});

  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState({ type: 'error', content: "" });
  const [isDisabled, setIsDisabled] = useState(true);

  const dispatch = useDispatch();


  const handleChange = (event: any) => {
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value
    });
    if (inputs.passwordHash != null) {
      setIsDisabled(false);
    }
  }
  
  const resetInput = () => {
    setInputs({});
  }

  const handleClose = () => {
    setMessage({ type: 'error', content: "" });
    setOpenAlert(false);
    resetInput();
    setOpen(false);
  }

  const handleSubmit = (id: string) => {
    setMessage({ type: 'info', content: t("Processing")});
    setOpenAlert(true);
    if (inputs != null) {
      SettingService.changePassword(id, inputs)
        .then(r => {
          setMessage({
            type: "success",
            content: (t("password") + t("updatedWithSuccess"))
          });
          
          setTimeout(() => {
            setMessage({
              type: "success",
              content: t("processingLogou") + t("3dot")
            });
          }, 1000)

          setTimeout(() => {
            handleClose()
            dispatch(logout());
          }, 1500)
        })
        .catch((err) => {
          if (
            err?.statusCode === 403 ||
            err?.statusCode === 401 ||
            err?.response?.status === 403 ||
            err?.response?.status === 401
          ) {
            dispatch(logout());
          } else {
            setMessage({
              type: 'error',
              content: err?.response?.data?.message
            });
          }
        });
    }
  }

  const content =
    <MyTextField
      type='password'
      name="passwordHash"
      label={t('newPwd')}
      onChange={handleChange}
    />

  const actions =
    <>
      <MyOutlinedButton onClick={handleClose}>{t("cancel")}</MyOutlinedButton>
      <MyContainedButton disabled={isDisabled} onClick={() => handleSubmit(payload.me)}>{t("validate")}</MyContainedButton>
    </>


  return (
    <>
      <CustomModals
        open={open}
        handleClose={handleClose}
        title={t("changePwd")}
        content={content}
        actions={actions}
        openAlert={openAlert}
        setOpenAlert={setOpenAlert}
        message={message}
        setMessage={setMessage}
      />
    </>
  );
}
export default SettingsModal;