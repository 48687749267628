import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import Grid from '@mui/material/Grid';
import { MyContainedButton, MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import { MyTextField } from 'MyComponents/TextField/MyTextField';
import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
import { IMessage } from 'pages/Clients/type/Message';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CustomModals } from "shared/modals/customModals";
import { logout } from 'shared/redux/features/auth/authSlice';
import addressService from '../service/AddressService';
import { IAddressCreator } from '../type/AddressCreator';
import { IFavoriteAddress } from '../type/FavoriteAddress';

export function EditAddress(props: { open: any, refresh: any, setOpen: any, address: any, type: string, ishome: boolean }) {

    const { t } = useTranslation();

    const { open, refresh, setOpen, address, type, ishome } = props;
    const [inputs, setInputs] = useState<IFavoriteAddress>({});
    const [addressInputs, setAddressInputs] = useState<IAddressCreator>({});
    const [openAlert, setOpenAlert] = useState(false);
    const [message, setMessage] = useState<IMessage>({ type: 'error', content: '' });

    const dispatch = useDispatch();

    const handleAddressChange = (event: any) => {
        setAddressInputs({
            ...addressInputs,
            [event.target.name]: event.target.value
        })
    }

    const handleChange = (event: any) => {
        setInputs({
            ...inputs,
            [event.target.name]: event.target.value
        });
    }

    const resetInput = () => {
        setInputs({})
        setAddressInputs({})
    }

    const handleClose = () => {
        setMessage({ type: 'error', content: '' });
        setOpenAlert(false);
        resetInput();
        setOpen(false);
    }

    const handleSubmit = (id: string) => {
        setMessage({ type: 'info', content: t("Processing") });
        setOpenAlert(true);
        if (inputs != null) {
            inputs.address = addressInputs;
            inputs.isHome = ishome;
            addressService.updateAddress(id, inputs)
                .then(r => {
                    refresh()
                    setMessage({
                        type: "success",
                        content: type + t("updatedWithSuccess")
                    });
                    setTimeout(() => {
                        handleClose()
                    }, 1500)
                })
                .catch((err) => {
                    if (
                        err?.statusCode === 403 ||
                        err?.statusCode === 401 ||
                        err?.response?.status === 403 ||
                        err?.response?.status === 401
                    ) {
                        dispatch(logout());
                    } else {
                        setMessage({
                            type: 'error',
                            content: err?.response?.data?.message
                        })
                    }
                }
                );
        }
    }

    const content =
        <>
            <MyModalSubTitle>
                <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                {t("personnalInfo")}
            </MyModalSubTitle>
            <Grid container spacing={1} columns={{ xs: 4, md: 12 }} >
                <Grid item xs={4} >
                    <MyTextField type='text' name="fullname" defaultValue={address?.fullname} label={t("nameOfPeople")} onChange={handleChange} />
                </Grid>
                <Grid item xs={4} >
                    <MyTextField type='text' name="label" label={t(t("company"))} defaultValue={address?.label} onChange={handleChange} />
                </Grid>
                <Grid item xs={4} >
                    <MyTextField type='tel' name="phone" defaultValue={address?.phone} pattern="[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}" label={t("phone")} placeholder={"xx xx xx xx xx"} onChange={handleChange} />
                </Grid>
            </Grid>
            <MyModalSubTitle >
                <LocationOnIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                {t("address")}
            </MyModalSubTitle>

            <Grid container spacing={1} columns={{ xs: 3, md: 12 }} >
                <Grid item xs={6} >
                    <MyTextField type='text' name="street" label={t("street")} defaultValue={address?.address?.street} onChange={handleAddressChange} />
                </Grid>
                < Grid item xs={6} >
                    <MyTextField type='text' name="city" label={t("city")} defaultValue={address?.address?.city} onChange={handleAddressChange} />
                </Grid>
                < Grid item xs={2} >
                    <MyTextField type='text' name="zipcode" label={t("zipcode")} defaultValue={address?.address?.zipcode} onChange={handleAddressChange} />
                </Grid>
                < Grid item xs={3} >
                    <MyTextField type='text' name="country" label={t("country")} defaultValue={address?.address?.country} onChange={handleAddressChange} />
                </Grid>
                < Grid item xs={7} >
                    <MyTextField type='text' name="complement" label={t("complement")} defaultValue={address?.address?.complement} onChange={handleAddressChange} />
                </Grid>
            </Grid>
        </>

    const actions =
        <>
            <MyOutlinedButton onClick={handleClose}> {t("cancel")} </MyOutlinedButton>
            <MyContainedButton onClick={() => handleSubmit(address.id)}> {t("validate")} </MyContainedButton>
        </>

    if (address != null && address != undefined) {
        return (
            <CustomModals
                open={open}
                handleClose={handleClose}
                title={t("updateAddress")}
                content={content}
                actions={actions}
                openAlert={openAlert}
                setOpenAlert={setOpenAlert}
                message={message}
                setMessage={setMessage}
            />
        )
    } else {
        return <></>
    }
}
