import RemovaleTemplate from "pages/Removale/pdf/RemovaleTemplate";
import { pdf } from '@react-pdf/renderer';
import { getISODate, localeISODateTime } from 'shared/utils/localeISODateTime';
import { saveAs } from 'file-saver';
import { IDeliveryData } from "pages/Delivery/type/Delivery/DeliveryData";
import { MyOutlinedButton } from "MyComponents/Generic/MyButton";
import { useTranslation } from "react-i18next";

const waitForElm = (selector: any) => {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}

function PDFButton(props: {
    setErrors: Function,
    setOpenAlert: Function,
    delivery: IDeliveryData
}) {

    const {
        setErrors,
        setOpenAlert,
        delivery
    } = props;
    const { t } = useTranslation();

    const handleError = (error: any) => {
        setErrors({
            type: "error",
            content: error
        })
    }

    const handleSave = () => {
        setErrors({
            type: "success",
            content: t("processingGenerateDelivery") + t("3dot")
        })

        waitForElm('canvas')
            .then(() => {
                const canvas = document.getElementById("qrcode") as HTMLCanvasElement;
                const pngUrl = canvas?.toDataURL();

                pdf(<RemovaleTemplate delivery={delivery} qrcode={pngUrl} />)
                    .toBlob()
                    .then((blob) => saveAs(blob,
                        t('delivery_code_')
                        + getISODate(localeISODateTime()) + '_'
                        + delivery?.addressTo?.fullname?.replace(' ', '_')
                        + '.pdf'))
                    .catch(err => {
                        handleError(err);
                    })
            }).catch(err => {
                handleError(err);
            });

        setOpenAlert(false);
    }

    return (
        <MyOutlinedButton onClick={handleSave}>{t("save")}</MyOutlinedButton>
    )
}

export default PDFButton;