import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from "@mui/icons-material/Close";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PersonIcon from '@mui/icons-material/Person';
import { Alert, Box } from "@mui/material";
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from "@mui/material/IconButton";
import { MyContainedButton, MyOutlinedButton } from 'MyComponents/Generic/MyButton';
import { MyTextField } from 'MyComponents/TextField/MyTextField';
import MyModalSubTitle from 'MyComponents/Title/MyModalSubTitle';
import { IMessage } from 'pages/Clients/type/Message';
import { useRef, useState } from 'react';
import CanvasDraw from "react-canvas-draw";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { logout } from 'shared/redux/features/auth/authSlice';
import deliveryService from '../service/DeliveryService';
import { IDeliveryCreator } from '../type/Delivery/DeliveryCreator';
import { IDeliveryData } from '../type/Delivery/DeliveryData';
import { StatusPage } from './StatusPage';

function EndDelivery(props: {
    setOpen: Function,
    open: boolean,
    handleHideElement: Function,
    delivery: IDeliveryData,
    errors: IMessage,
    activeStep: number,
    openAlert: boolean,
    setOpenAlert: Function,
    setActiveStep: Function,
    setErrors: Function,
    handleClose: Function
}) {

    const { id } = useParams();
    const {
        open,
        setOpen,
        handleHideElement,
        delivery,
        errors,
        activeStep,
        openAlert,
        setOpenAlert,
        setActiveStep,
        setErrors,
        handleClose
    } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [signatureTo, setSignatureTo] = useState("");
    const [signatureToRemark, setSignatureToRemark] = useState("");

    const canvasRef = useRef<any>(null)

    const handleRemarkchange = (event: any) => {
        setSignatureToRemark(event.target.value);
    }

    const handleAccept = async () => {
        if (!delivery?.deliveryId) {
            return;
        }
        setOpenAlert(true);
        try {
            const deliveryCreator: IDeliveryCreator = { signatureTo, signatureToRemark };
            await deliveryService.endDelivery(delivery.deliveryId, deliveryCreator);
            setErrors({
                type: "success",
                content: t("deliveryEndedSuccessfully")
            });
            setTimeout(handleClose, 1500);
            setActiveStep(6);
        } catch (err: any) {
            if (err?.statusCode === 403 || err?.response?.status === 403) {
                dispatch(logout());
            } else {
                setErrors(err?.response?.data?.error);
            }
        } finally {
            setOpenAlert(false);
        }
    };

    const handleChange = () => {
        const data = canvasRef?.current?.getSaveData();
        setSignatureTo(data);
    }

    if (delivery != null) {
        if (activeStep === 4) {
            return (
                <>
                    <StatusPage>
                        <Box className="header" />
                        <Box className="content">
                            <Box className='info' sx={{ width: { xs: '100%', md: '60%' } }}>
                                <MyModalSubTitle>
                                    <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                                    {t("informations")}
                                </MyModalSubTitle>
                                <Grid container spacing={1} padding={3}>
                                    <Grid item xs={12}>
                                        <MyTextField disabled={true} type='text' name="clientId" label={t("client")} defaultValue={delivery?.client?.user?.firstname + " " + delivery?.client?.user?.lastname} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MyTextField disabled={true} type='text' name="type" label={t("typeOfDelivery")} defaultValue={delivery?.type?.name} />
                                    </Grid>
                                    {/* <Grid item xs={6}>
                                        <MyTextField disabled={true} type='text' name="remark" label={t("remark")} defaultValue={delivery?.remark} />
                                    </Grid> */}
                                </Grid>
                                <MyModalSubTitle>
                                    <LocalShippingIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                                    {t("journey")}
                                </MyModalSubTitle>
                                <Grid container spacing={1} padding={3}>
                                    <Grid item xs={12}>
                                        <MyTextField disabled={true} type='text' name="favAddressFromId" label={t("addressFrom")} defaultValue={
                                            delivery?.addressFrom?.address?.street + " " +
                                            delivery?.addressFrom?.address?.city + " " +
                                            delivery?.addressFrom?.address?.complement}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MyTextField disabled={true} type='text' name="favAddressToId" label={t("addressTo")} defaultValue={
                                            delivery?.addressTo?.address?.street + " " +
                                            delivery?.addressTo?.address?.city + " " +
                                            delivery?.addressTo?.address?.complement}
                                        />
                                    </Grid>
                                </Grid>
                                <div>
                                    <MyContainedButton onClick={() => setActiveStep(5)}>{t("next>")}</MyContainedButton>
                                </div>
                            </Box>
                        </Box>
                    </StatusPage>
                </>
            )
        } else if (activeStep === 5) {
            return (
                <>
                    <StatusPage>
                        <Box className="header" />
                        <Box className="content">
                            <Box className='info' sx={{ width: { xs: '100%', md: '60%' } }}>
                                <Grid container spacing={1} padding={3}>
                                    <Grid item xs={12}>
                                        <MyModalSubTitle>
                                            <LocalShippingIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                                            {t("command")} {delivery.trackingNumber}
                                        </MyModalSubTitle>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MyModalSubTitle>
                                            <PersonIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                                            {t("signatureTo")}
                                        </MyModalSubTitle>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <CanvasDraw
                                                canvasWidth={300}
                                                canvasHeight={250}
                                                ref={canvasRef}
                                                loadTimeOffset={10}
                                                onChange={handleChange}
                                            />
                                        </Box>
                                        <Grid item xs={12}>
                                    <MyTextField type='text' name='remark' value={signatureToRemark} label='remark' onChange={handleRemarkchange}/>
                                </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Collapse in={openAlert}>
                                            {(errors?.type) &&
                                                <Alert severity={'info'}
                                                    action={
                                                        <IconButton
                                                            aria-label="close"
                                                            color="inherit"
                                                            size="small"
                                                            onClick={() => {
                                                                setOpenAlert(false);
                                                                setErrors({ type: 'error', content: '' });
                                                            }}
                                                        >
                                                            <CloseIcon fontSize="inherit" />
                                                        </IconButton>
                                                    }
                                                >
                                                    {errors?.content || ''}
                                                </Alert>
                                            }
                                        </Collapse>
                                    </Grid>
                                </Grid>
                                <div>
                                    <MyOutlinedButton onClick={() => setActiveStep(4)}>{t("<back")}</MyOutlinedButton>
                                    <MyContainedButton onClick={handleAccept}>{t("validate")}</MyContainedButton>
                                </div>
                            </Box>
                        </Box>
                    </StatusPage>
                </>)
        } else if (activeStep === 6) {
            return (
                <>
                    <StatusPage>
                        <Box className="header" />
                        <Box className="content">
                            <Box className='info' sx={{ width: { xs: '100%', md: '60%' } }}>
                                <Grid container spacing={1} padding={3}>
                                    <Grid item xs={12}>
                                        <MyModalSubTitle>
                                            <LocalShippingIcon sx={{ color: '#009CFF', fontSize: '40px' }} />
                                            {t("command")} {delivery.trackingNumber}
                                            <br />
                                            {t("deliverySuccess")}
                                        </MyModalSubTitle>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CheckCircleOutlineIcon sx={{ color: '#5fe95a', fontSize: '40px' }} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MyTextField disabled={true} type='text' name="favAddressFromId" label={t("addressFrom")} defaultValue={
                                            delivery?.addressFrom?.address?.street + " " +
                                            delivery?.addressFrom?.address?.city + " " +
                                            delivery?.addressFrom?.address?.complement}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MyTextField disabled={true} type='text' name="favAddressToId" label={t("addressTo")} defaultValue={
                                            delivery?.addressTo?.address?.street + " " +
                                            delivery?.addressTo?.address?.city + " " +
                                            delivery?.addressTo?.address?.complement}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h2>{delivery.isComingBack && t("deliveryShouldComeBack")}</h2>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </StatusPage>
                </>
            )
        } else {
            return (
                <>
                    <StatusPage>
                        <Box className="header" />
                        <Box className="content">
                            <Box className='info' sx={{ width: { xs: '100%', md: '60%' } }}>
                                <MyModalSubTitle>{t("deliveryNotFound")}</MyModalSubTitle>
                            </Box>
                        </Box>
                    </StatusPage>
                </>
            )
        }
    } else {
        return (
            <>
                <StatusPage>
                    <Box className="header" />
                    <Box className="content">
                        <Box className='info' sx={{ width: { xs: '100%', md: '60%' } }}>
                        </Box>
                    </Box>
                </StatusPage>
            </>
        )
    }
};

export default EndDelivery;